// Copyright 2021 Descript, Inc

import { DescriptError, ErrorCategory } from '@descript/errors';

interface RequestCreatorFactory<RequestCreator, Type extends string> {
    (type: Type): RequestCreator;
}

const registeredNamespaces = new Set<string>();

type NamespaceCommand<
    Namespace extends string,
    Command extends string,
> = `${Namespace}.${Command}`;

export function resetCommandNamespaces(): void {
    if (process.env.NODE_ENV !== 'test') {
        throw new DescriptError(
            'Can only reset command namespaces in tests',
            ErrorCategory.AppArchitecture,
        );
    }
    registeredNamespaces.clear();
}

export function namespacedCommandFactory<Namespace extends string>(namespace: Namespace) {
    if (registeredNamespaces.has(namespace)) {
        throw new DescriptError(
            `Namespace already registered: ${namespace}`,
            ErrorCategory.AppArchitecture,
        );
    }
    registeredNamespaces.add(namespace);
    const registeredTypes = new Set<string>();

    function prefixedRequestCreatorFactory<
        RC,
        Type extends string,
        RCF extends RequestCreatorFactory<RC, NamespaceCommand<Namespace, Type>>,
        T extends Type,
    >(requestCreatorFactory: RCF): RCF {
        return ((type: T) => {
            const fullName: NamespaceCommand<Namespace, T> = `${namespace}.${type}`;
            if (registeredTypes.has(type)) {
                throw new DescriptError(
                    `Request type already registered: ${fullName}`,
                    ErrorCategory.AppArchitecture,
                );
            }
            registeredTypes.add(type);
            return requestCreatorFactory(fullName);
        }) as RCF;
    }

    return prefixedRequestCreatorFactory;
}
