// Copyright 2023 Descript, Inc
import { CurrentUserHasPendingUpgradeRequestWithLoadingStatus } from '../../Reducers/Selectors/DriveSelectors';
import { User } from '../../Api/Project';
import { DriveMembershipType } from '../../Api/Drive';
import { Product } from '../../Api/ProductClient';
import { FeatureFlagValue } from '../../FeatureFlags/types';

export enum InviteLinkQueryKey {
    token = 'invite_link_token',
    nonce = 'invite_link_nonce',
    driveName = 'invite_link_drive_name',
    inviteMembershipType = 'invite_link_membership_type',
    // driveId and encryptionKey used as query params in new /join route, after hiding token in encryption sessionStorage
    encryptionKey = 'invite_link_session',
    driveId = 'driveId',
}

interface InviteLinkLandingPageProps {
    driveId: string;
    currentUser: User | undefined;
    currentUserIsDriveMember: boolean;
    currentUserIsFreeMemberOnDrive: boolean | undefined;
    currentUserHasPendingUpgradeRequestWithStatus: CurrentUserHasPendingUpgradeRequestWithLoadingStatus;
    driveProduct: Product | undefined;
}

export type InviteLinkLandingPageTokenProps = InviteLinkLandingPageProps & {
    token: string;
    editorInvitesRequireApproval: boolean;
    handleNoDrivesEnabledInviteLinks: FeatureFlagValue | undefined;
};

export type InviteLinkLandingPageNonceProps = InviteLinkLandingPageProps & {
    nonce: string;
    inviteMembershipType: DriveMembershipType | undefined;
    driveName: string | undefined;
    currentUserHasDrives: boolean;
};
