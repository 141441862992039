// Copyright 2020 Descript, Inc

import * as NUserSettings from '../App/UserSettings';
import { AppSettings, AppSettingsValue } from '../App/AppSettings';
import { Hosts } from '../App/Constants';

export const ApiTargetNames = [
    'production',
    'staging',
    'dev',
    'localhost',
    'localhost-with-staging-data',
] as const;
export type ApiTargetName = (typeof ApiTargetNames)[number];
export type ApiTargetSettings = {
    prefix?: 'staging' | 'dev' | 'localhost' | 'localhost-with-staging-data';
};
export const ApiTenancies = ['production', 'staging', 'dev', 'localhost'] as const;
export type ApiTenancy = (typeof ApiTenancies)[number];

let apiTargetSettings: ApiTargetSettings = {};

// should keep parity with LocalStorageHelper.loadUserSetting
export function loadUserSetting() {
    if (process.env.DESCRIPT_API_HOST) {
        if (
            String(process.env.DESCRIPT_API_HOST).startsWith(Hosts.devApi) ||
            String(process.env.DESCRIPT_API_HOST).startsWith(Hosts.secureDevWeb) ||
            String(process.env.DESCRIPT_API_HOST).includes(Hosts.devCoderApi)
        ) {
            // E2E local dev
            apiTargetSettings = { prefix: 'localhost' };
        } else if (String(process.env.DESCRIPT_API_HOST).startsWith(Hosts.developmentApi)) {
            // E2E development dev
            apiTargetSettings = { prefix: 'dev' };
        } else if (String(process.env.DESCRIPT_API_HOST).startsWith(Hosts.stagingApi)) {
            // E2E staging dev
            apiTargetSettings = { prefix: 'staging' };
        } else if (String(process.env.DESCRIPT_API_HOST).startsWith(Hosts.api)) {
            apiTargetSettings = {};
        }
    } else if (DescriptFeatures.DEV_TOOLS && DescriptFeatures.ALLOW_API_SWITCHER) {
        const apiTarget = NUserSettings.Application.apiTarget.get();
        apiTargetSettings = apiTarget === 'production' ? {} : { prefix: apiTarget };
    } else {
        apiTargetSettings = {};
    }
}

export function prefix(): string {
    if (apiTargetSettings.prefix === undefined) {
        return '';
    }
    return `${getApiTenancy(apiTargetSettings.prefix)}-`;
}

export function getApiTenancy(apiTarget: ApiTargetName): ApiTenancy {
    switch (apiTarget) {
        case 'localhost':
            return 'localhost';
        case 'localhost-with-staging-data':
            return 'localhost';
        case 'dev':
            return 'dev';
        case 'staging':
            return 'staging';
        case 'production':
            return 'production';
        default:
            return 'production';
    }
}

export function targetName(): ApiTargetName {
    return apiTargetSettings.prefix || 'production';
}

export function authTokenKey(): string {
    return accessTokenKeyForAuth0();
}

export function refreshTokenKey(): string {
    return refreshTokenKeyForAuth0();
}

export function getAuthToken(settings = AppSettings.authInstance): AppSettingsValue {
    return settings?.get(authTokenKey());
}

export function getRefreshToken(settings = AppSettings.authInstance): AppSettingsValue {
    return settings?.get(refreshTokenKey());
}

export async function setTokens(accessToken: string, refreshToken: string): Promise<void> {
    setTokensForAuth0(accessToken, refreshToken);
    clearTokensForLegacyAuth();
    NUserSettings.Application.isLoggedIn.set('true');
    if (AppSettings.authInstance.onIdle) {
        await AppSettings.authInstance.onIdle();
    }
}

export async function clearTokens(): Promise<void> {
    clearTokensForLegacyAuth();
    clearTokensForAuth0();
    NUserSettings.Application.isLoggedIn.clear();
    if (AppSettings.authInstance.onIdle) {
        await AppSettings.authInstance.onIdle();
    }
}

function shouldUseProductionCdn() {
    return (
        apiTargetSettings.prefix === undefined ||
        (apiTargetSettings.prefix as unknown as string) === 'production'
    );
}

export function awsBucket(): string {
    return `descript-assets${shouldUseProductionCdn() ? '' : '-testing'}`;
}

export function awsPublishBucket(): string {
    return `descript-publish${shouldUseProductionCdn() ? '' : '-testing'}`;
}

export function awsRecordingBucket(): string {
    return `descript-recordings${shouldUseProductionCdn() ? '' : '-testing'}`;
}

export function cloudfrontPublishDomain(): string {
    if (shouldUseProductionCdn()) {
        return 'd1d3n03t5zntha.cloudfront.net';
    }
    return 'd2fr0o6tpjbggf.cloudfront.net';
}

export function cloudfrontRecordingDomain(): string {
    if (shouldUseProductionCdn()) {
        return 'd3grwaljw6zt7u.cloudfront.net';
    }
    return 'd2kv8zej4ot6ky.cloudfront.net';
}

export function getPublicVoiceAudioPreviewUrl(voiceId: string): string {
    return `${
        shouldUseProductionCdn() ? Hosts.cdn : Hosts.stagingCdn
    }/stock-voices/${voiceId}/audio-preview.wav`;
}

function accessTokenKeyForLegacyAuth(): string {
    return `${prefix()}Auth.token`;
}

function accessTokenKeyForAuth0(): string {
    return `${prefix()}Auth0.accessToken`;
}

function refreshTokenKeyForLegacyAuth(): string {
    return `${prefix()}Auth.refreshToken`;
}

function refreshTokenKeyForAuth0(): string {
    return `${prefix()}Auth0.refreshToken`;
}

function setTokensForAuth0(accessToken: string, refreshToken: string): void {
    AppSettings.authInstance.set(accessTokenKeyForAuth0(), accessToken);
    AppSettings.authInstance.set(refreshTokenKeyForAuth0(), refreshToken);
}

function clearTokensForLegacyAuth(): void {
    AppSettings.authInstance.delete(accessTokenKeyForLegacyAuth());
    AppSettings.authInstance.delete(refreshTokenKeyForLegacyAuth());
}

function clearTokensForAuth0(): void {
    AppSettings.authInstance.delete(accessTokenKeyForAuth0());
    AppSettings.authInstance.delete(refreshTokenKeyForAuth0());
}
