// Copyright 2024 Descript, Inc

// eslint-disable-next-line no-restricted-imports
import actionCreatorFactoryBase, {
    ActionCreator,
    AsyncActionCreators,
    Meta,
} from 'typescript-fsa';
import { ErrorCategory } from '@descript/errors';

export function actionCreatorFactory(options: {
    prefix?: string | null;
    defaultIsError?: (payload: unknown) => boolean;
    category: ErrorCategory;
}) {
    const factory = actionCreatorFactoryBase(options.prefix, options.defaultIsError);

    type SubMeta = typeof options.category extends ErrorCategory
        ? { category?: ErrorCategory }
        : { category: ErrorCategory };

    const modifiedFactory = <Payload = void>(
        type: string,
        commonMeta?: Meta & SubMeta,
        isError?: boolean,
    ): ActionCreator<Payload> => {
        return factory(
            type,
            {
                category: options.category,
                ...commonMeta,
            },
            isError,
        );
    };

    modifiedFactory.async = <Params, Result, Error = object>(
        type: string,
        commonMeta?: Meta & SubMeta,
    ): AsyncActionCreators<Params, Result, Error> => {
        return factory.async<Params, Result, Error>(type, {
            category: options.category,
            ...commonMeta,
        });
    };

    return modifiedFactory;
}
