import { systemColors, theme } from './theme';

export type BaseTheme = typeof theme;

// as defined by Daily
export type ThemeOption = 'dark' | 'light' | 'user';

// as defined by Descript
export enum DescriptThemeOption {
    DARK = 'dark',
    LIGHT = 'light',
    SYSTEM = 'system',
}

export type ThemeColors<CustomColors = Record<string, string>> = {
    /** Main brand color */
    accent: string;
    /** Text or icon color rendered on main brand color */
    accentText: string;
    /** Background color */
    background: string;
    /** Accented background color */
    backgroundAccent: string;
    /** Default text and icon color */
    baseText: string;
    /** Border color */
    border: string;
    /** Any additional custom colors */
    custom?: CustomColors;
    /** Text color for supportive or muted text */
    supportiveText: string;
    /** Background color for room view */
    roomBackground: string;
    /** System colors */
    system: typeof systemColors;
};

export interface Theme extends BaseTheme {
    colors: ThemeColors;
}
