// Copyright © 2024 Descript, Inc. All rights reserved.

export enum Team {
    AIActions,
    AISpeech,
    ClientPlatform,
    Core,
    Collaboration,
    EditorFramework,
    EditorWorkflows,
    Media,
    Recorder,
    UserJourneys,
}

export interface TeamData {
    name: string;
    linearId: string;
}

const TEAM_INFO: Record<Team, TeamData> = Object.freeze({
    [Team.AIActions]: { name: 'AI Actions', linearId: 'a0622a6b-dd43-43a1-b707-cbf72d246ce3' },
    [Team.AISpeech]: { name: 'AI Speech', linearId: 'a6eb61ab-7d2c-4714-a87f-1e6592423852' },
    [Team.ClientPlatform]: {
        name: 'Client Platform',
        linearId: '0ce208e6-0a3f-4a16-9a00-151d2bb031ed',
    },
    [Team.Core]: { name: 'Core', linearId: '8c846747-5dd4-4bbd-b8ed-bd60f1a341fb' },
    [Team.Collaboration]: {
        name: 'Collaboration',
        linearId: '37a60bc6-91de-4605-b63a-78688d79a1b3',
    },
    [Team.EditorFramework]: {
        name: 'Editor Framework',
        linearId: '0f4fe808-6056-4d90-991c-316a76eec2c8',
    },
    [Team.EditorWorkflows]: {
        name: 'Editor Workflows',
        linearId: '55d1f855-4110-42f6-9448-427b015df439',
    },
    [Team.Media]: { name: 'Media', linearId: '9fe9a9cc-c2b3-45fa-8343-6d3023638c37' },
    [Team.Recorder]: { name: 'Recorder', linearId: '34af2b23-27e6-4943-b31b-9aefb15d195e' },
    [Team.UserJourneys]: {
        name: 'User Journeys',
        linearId: '56731d03-e365-4d7f-ae44-bc5292a09d8d',
    },
});

export function getTeamData(team: Team): TeamData {
    return TEAM_INFO[team];
}
