// Copyright 2020 Descript, Inc

import { AppSettingsValue, IAppSettings } from './AppSettings';
import * as UserSettings from './UserSettings';
import { DescriptError, ErrorCategory } from '@descript/errors';

export function* enumerateUserSettings(): IterableIterator<IUserSetting<AppSettingsValue>> {
    for (const settingsGroup of Object.values(UserSettings)) {
        for (const setting of Object.values(settingsGroup)) {
            if (isUserSetting(setting)) {
                yield setting;
            }
        }
    }
}

export function copyUserSettings(fromSettings: IAppSettings): void {
    for (const setting of enumerateUserSettings()) {
        if (setting.isSet(fromSettings)) {
            const value = setting.get(fromSettings);
            setting.set(value);
        }
    }
}

export function clearUserSettings(fromSettings: IAppSettings): void {
    for (const setting of enumerateUserSettings()) {
        setting.clear(fromSettings);
    }
}

export function replaceUserSetting(
    fromSetting: IUserSetting<AppSettingsValue>,
    toSetting: IUserSetting<AppSettingsValue>,
): void {
    if (fromSetting === toSetting) {
        throw new DescriptError(
            'Cannot replace setting in same settings object',
            ErrorCategory.AppSettings,
        );
    }
    if (fromSetting.isSet()) {
        const value = fromSetting.get();
        toSetting.set(value);
        fromSetting.clear();
    }
}

export interface IUserSetting<T> {
    key: string;
    get: (settings?: IAppSettings) => T;
    set: (val: T | undefined, settings?: IAppSettings) => void;
    isSet: (settings?: IAppSettings) => boolean;
    getDefault: () => T;
    clear: (settings?: IAppSettings) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUserSetting(x: any): x is IUserSetting<AppSettingsValue> {
    const setting = x as IUserSetting<AppSettingsValue>;
    if (!setting || typeof setting !== 'object') {
        return false;
    }
    return Boolean(
        typeof setting.key === 'string' &&
            typeof setting.get === 'function' &&
            typeof setting.set === 'function' &&
            typeof setting.isSet === 'function' &&
            typeof setting.clear === 'function' &&
            typeof setting.getDefault === 'function',
    );
}
