// Copyright © 2024 Descript, Inc. All rights reserved.

import './api';
import { DescriptError, ErrorCategory } from '@descript/errors';

function computeIsDesktop2(): boolean {
    const api = globalThis.DescriptDesktopAPI;
    if (!api) {
        return false;
    }
    switch (api.version) {
        case 1:
            return api.desktop;
        default:
            throw new DescriptError('Unsupported API version', ErrorCategory.AppArchitecture);
    }
}

export const isDesktop2 = computeIsDesktop2();

export const desktopPlatform: typeof process.platform | undefined =
    process?.platform ?? globalThis.DescriptDesktopAPI?.platform;

export const isFramelessMac: boolean =
    globalThis.DescriptDesktopAPI?.window.setWindowButtonPosition !== undefined ||
    process?.platform === 'darwin' ||
    false;
