// Copyright 2024 Descript, Inc

import type { Event, Integration, EventHint } from '@sentry/types';
import { getActualErrorFromHint } from './getErrorFromEvent';
import { ERROR_CATEGORY_TEAMS, ErrorCategory, getTeamData } from '@descript/errors';

export class UnhandledCategoryIntegration implements Integration {
    name = 'descript.unhandledCategory';

    constructor() {
        // no-op
    }

    setupOnce(): void {
        // no-op; will become optional w/ Sentry 8
    }

    async processEvent(event: Event, hint: EventHint): Promise<Event | null> {
        if (event.exception?.values?.[0]?.mechanism?.handled === true) {
            return event;
        }

        const error = await getActualErrorFromHint(hint);

        if (!error) {
            return event;
        }

        const category = (error as { category?: ErrorCategory }).category;

        if (!category) {
            return event;
        }

        event.tags = {
            'eng-team': getTeamData(ERROR_CATEGORY_TEAMS[category]).name,
            'error-category': category,
            // If somehow these are already set, don't overwrite them
            ...event.extra,
        };

        return event;
    }
}
