// Copyright 2023 Descript, Inc

import { Dimensions, Point2D } from '@descript/descript-model';
import { PopoverWithDimensions } from './userPreferences';

export type PopoverDimensions = Record<PopoverWithDimensions, Dimensions>;
export type PopoverPositions = Partial<Record<PopoverWithDimensions, Point2D>>;

export const minPopoverDimensions: PopoverDimensions = {
    'file-preview': { width: 240, height: 336 },
    'insert-drawer': { width: 240, height: 336 },
    'template-drawer': { width: 240, height: 296 },
    'composition-drawer': { width: 240, height: 274 },
    'media-library': { width: 240, height: 296 },
    'sequence-media-library': { width: 240, height: 296 },
    'recorder-config': { width: 368, height: 256 },
    'web-recorder': { width: 300, height: 246 },
} as const;

export const defaultPopoverDimensions: PopoverDimensions = {
    'file-preview': { width: 760, height: 480 },
    'insert-drawer': minPopoverDimensions['insert-drawer'],
    'composition-drawer': minPopoverDimensions['composition-drawer'],
    'template-drawer': { width: 760, height: 480 },
    'media-library': { width: 760, height: 480 },
    'sequence-media-library': { width: 620, height: 480 },
    'recorder-config': { width: 368, height: 256 },
    'web-recorder': { width: 730, height: 488 },
} as const;
