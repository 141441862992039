// Copyright 2024 Descript, Inc

import { ErrorCategory } from './ErrorCategory';

// eslint-disable-next-line @descript-eslint/error-require-category
export class DescriptError extends Error {
    category: ErrorCategory;
    constructor(message: string, category: ErrorCategory) {
        super(message);
        this.category = category;
    }
}
