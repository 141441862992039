// Copyright 2024 Descript, Inc

import { ErrorCategory } from '@descript/errors';

export class TimeoutError extends Error {
    category = ErrorCategory.AppArchitecture;

    constructor(
        public readonly timeoutMs?: number,
        message: string = `operation timed out${
            timeoutMs !== undefined ? ` after ${timeoutMs}ms` : ''
        }`,
    ) {
        super(message);
    }
}
