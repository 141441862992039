// Copyright 2022 Descript, Inc

import { createScopedLogger } from '../createScopedLogger';
import * as DebugSettings from './DebugSettings';
import {
    DEBUG_SETTINGS_SERIALIZED_VERSION_1,
    DEBUG_SETTINGS_SERIALIZED_VERSION_CURRENT,
    SerializedDebugSettingsBase,
    SerializedDebugSettingsV1,
    SerializedDebugSettingsV2,
} from './DebugSettingsTypes';
import { IDebugSettings, OnChangeFn, UnsubscribeFn } from './IDebugSettings';

const logger = createScopedLogger({ name: 'DEBUG_SETTINGS' });
/**
 * Base class functionality for backing implementations of the DebugSettings.
 * For Electron, there's an implementation for the Main process, and another for the Renderer process
 * WebWorkers also need an implementation as they don't share a global state with the Browser's main thread
 */
export abstract class DebugSettingsBase implements IDebugSettings {
    private settings: Map<string, DebugSettings.EntryType> = new Map<
        string,
        DebugSettings.EntryType
    >();

    private allKeySubscribers = new Set<OnChangeFn>();
    private keySubscribers = new Map<string, Set<OnChangeFn>>();

    /**
     * Set of keys that have been accessed in the current lifetime of this DebugSettingsBase
     */
    private accessed = new Set<string>();

    /**
     *
     */
    protected constructor() {
        // intentionally empty
        // https://github.com/typescript-eslint/typescript-eslint/issues/1178
    }

    /**
     *  IDebugSettings
     */
    public set(
        key: string,
        value: DebugSettings.ValueType,
        defaultValue?: DebugSettings.ValueType,
        description?: string,
        constrainedValues?: DebugSettings.ConstrainedValueType,
    ): void {
        if (key.length) {
            this.markAccessed(key);
            let entry = this.settings.get(key);
            if (entry) {
                // update existing entry
                entry.value = value;
            } else {
                // new entry
                entry = {
                    value,
                    defaultValue: defaultValue ?? value, // if no default provided, use `value`
                    description,
                    constrainedValues,
                };
            }

            // @TODO: check if value is within constrainedValues
            // if constrainedValues exists

            this.settings.set(key, entry);
            this.allKeySubscribers.forEach((onChange) => onChange(key, value));
            this.keySubscribers.get(key)?.forEach((onChange) => onChange(key, value));
        }
    }

    /**
     *  IDebugSettings
     */
    public get(
        key: string,
        defaultValue: DebugSettings.ValueType,
        description?: string,
        constrainedValues?: DebugSettings.ConstrainedValueType,
    ): DebugSettings.ValueType {
        this.markAccessed(key);
        const entry = this.settings.get(key);
        const result = entry?.value ?? defaultValue;

        if (!entry) {
            this.set(key, defaultValue, defaultValue, description, constrainedValues);
        }

        return result;
    }

    protected markAccessed(key: string): void {
        this.accessed.add(key);
    }

    /**
     * IDebugSettings
     */
    public getAccessed(): ReadonlySet<string> {
        return this.accessed;
    }

    /**
     * IDebugSettings
     */
    public cleanupUnused(): void {
        for (const key of this.settings.keys()) {
            if (!this.accessed.has(key)) {
                this.delete(key);
            }
        }
    }

    /**
     * IDebugSettings
     */
    clear(): void {
        for (const key of this.settings.keys()) {
            this.delete(key);
        }
    }

    /**
     * IDebugSettings
     */
    public getDefault(key: string): DebugSettings.ValueType | undefined {
        return this.settings.get(key)?.defaultValue;
    }

    /**
     * IDebugSettings
     */
    public getDescription(key: string): string | undefined {
        return this.settings.get(key)?.description;
    }

    /**
     * IDebugSettings
     */
    public getConstrainedValues(key: string): DebugSettings.ConstrainedValueType | undefined {
        return this.settings.get(key)?.constrainedValues;
    }

    /**
     *  IDebugSettings
     */
    public hasKey(key: string): boolean {
        return this.settings.has(key);
    }

    /**
     * IDebugSettings
     * @return `true` if `key` existed and has been removed, or `false` if it did not exist
     */
    public delete(key: string): boolean {
        return this.settings.delete(key);
    }

    /**
     *  IDebugSettings
     */
    public serialize(): DebugSettings.SerializedType {
        return {
            version: DEBUG_SETTINGS_SERIALIZED_VERSION_CURRENT,
            data: Object.fromEntries(this.settings),
        };
    }

    /**
     *  IDebugSettings
     */
    public updateFromSerialized(serializedData: SerializedDebugSettingsBase): void {
        const accessed = new Set(this.accessed);
        if (
            serializedData.version === DEBUG_SETTINGS_SERIALIZED_VERSION_CURRENT &&
            'data' in serializedData
        ) {
            this.settings.clear();
            Object.entries((serializedData as SerializedDebugSettingsV2).data)
                .sort()
                .forEach(([key, entry]) => {
                    if (
                        (typeof entry.value === 'boolean' &&
                            typeof entry.defaultValue === 'boolean') ||
                        (typeof entry.value === 'number' &&
                            typeof entry.defaultValue === 'number') ||
                        (typeof entry.value === 'string' &&
                            typeof entry.defaultValue === 'string')
                    ) {
                        this.set(
                            key,
                            entry.value,
                            entry.defaultValue,
                            entry.description,
                            entry.constrainedValues,
                        );
                    }
                });
        } else if (
            serializedData.version === DEBUG_SETTINGS_SERIALIZED_VERSION_1 &&
            'data' in serializedData
        ) {
            this.settings.clear();
            Object.entries((serializedData as SerializedDebugSettingsV1).data)
                .sort()
                .forEach(([key, value]) => {
                    if (
                        typeof value === 'boolean' ||
                        typeof value === 'number' ||
                        typeof value === 'string'
                    ) {
                        this.set(key, value, value);
                    }
                });
        } else {
            logger.error(`Invalid Debug Settings Version: ${serializedData.version}`);
        }

        // restore accessed keys from before the update-from-serialized
        this.accessed = accessed;
    }

    subscribeAll(onChange: OnChangeFn): UnsubscribeFn {
        this.allKeySubscribers.add(onChange);
        return () => {
            this.allKeySubscribers.delete(onChange);
        };
    }
    subscribeKey(key: string, onChange: OnChangeFn): UnsubscribeFn {
        this.markAccessed(key);
        const set = this.keySubscribers.get(key);
        if (!set) {
            this.keySubscribers.set(key, new Set([onChange]));
        } else {
            set.add(onChange);
        }
        return () => {
            this.keySubscribers.get(key)?.delete(onChange);
        };
    }
}
