// Copyright 2022 Descript, Inc

import { v4 as uuidv4 } from 'uuid';
import { AppSettings, AppSettingsValue, IAppSettings } from './AppSettings';
import { WindowState } from './WindowSettings';
import { AppConstants } from './Constants';
import { ProxyVideoCodec, ProxyVideoEncodingSpeed } from '@descript/ffmpeg-proxy-creator';
import { ExportFileFormat } from '../Utilities/ExportFileFormat';
import {
    AudioExportSettings,
    FirstRunExperienceSettings,
    GifExportSettings,
    QuickEditSettings,
    QuickRecordingAudioDevicePreference,
    QuickRecordingCompleteAction,
    QuickRecordingMode,
    RecordingConfigurationPreference,
    SavedProjectCompositions,
    SubtitleExportSettings,
    TemplateBrowserSortState,
    TextExportSettings,
    TimelineExportSettings,
    TimelineLayerDensity,
    VideoExportSettings,
    WebRecordingSettings,
} from '../Reducers/userPreferences';
import {
    defaultPopoverDimensions,
    PopoverDimensions,
    PopoverPositions,
} from '../Reducers/PopoverDimensions';
import {
    deleteIfExists,
    Dimensions,
    IEncodedTextVector,
    Progress,
    Quality,
    SegmentEffectValueProperties,
    setIfChanging,
    Speaker as VoiceModel,
    TranscriptionLanguage,
    Waveform,
} from '@descript/descript-model';
import {
    EditorVariantFilter,
    getDefaultSortAscending,
    ProjectBrowserTableColumn,
    ProjectFilter,
    ProjectFilterSettings,
    ProjectSubFilter,
} from '../Utilities/ProjectFilters';
import {
    CopyRangeType,
    LastUpdatedType,
    LastUpdatedTypeDefault,
    PublishType,
    PublishTypeDefault,
    VideoPanePosition,
} from '../Actions/UserPreferenceActions';
import { ApiTargetName } from '../Api/ApiTarget';
import { DefaultExportScope } from '../Utilities/ExportScopes';
import { PublishWebTargetName } from '../Api/PublishedProjectClient';
import { FeatureFlagSettings } from '../FeatureFlags/types';
import { RootFolder } from '../Api/FolderClientConstants';
import { TimelineTool } from '../Reducers/document/ProjectEditorState';
import { SortState } from '../Components/Sidebar/Sorting';
import {
    IAccountSetting,
    IUserSettings,
    makeBooleanUserSetting,
    makeNumericUserSetting,
    makeUserSetting,
} from './UserSettingsCreationHelpers';
import { JSONObjectCompat } from '@descript/descript-core';
import { ProjectType } from '../Utilities/DefaultProjectTypes';
import { DriveRecordingQualityMap } from '../Recording/types';
import { ColorScheme } from '@descript/react-utils';
import { AccountLinkingContext } from '../Utilities/AccountLinking';
import { LastWatermarkRemoval } from '../Hooks/WatermarkRemoval';
import { EditorRecordingConfiguration } from '../Model/editorRecording';
import { GoogleAuthState } from '../Utilities/GoogleAuthHelpers';
import { IconSidebarState } from '../Components/Sidebar/StoryboardRemasteredSidebar/Sidebar/IconSidebarItemValue';
import { TemplateSectionType } from '../Components/Sidebar/StoryboardRemasteredSidebar/Templates/TemplateSectionTypes';
import { PresetId } from '../Components/AiActions/actionDescriptors/actionDescriptors';
import { SubmitFnArgs } from '../Components/AiActions/actionDescriptors/actionDescriptorTypes';
import { ApplicationInstallId } from './Constants/CookieConstants';
import { DefaultSpeaker } from '../Components/DefaultSpeakerSelect';

const currentUserId = makeUserSetting<string | undefined>(
    'Application.currentUserId',
    undefined,
);

/**
 * Defines a UserSetting specific to a logged-in user account or the logged out state
 *
 * @param key This must be globally unique across all UserSettings.
 */
function makeAccountSetting<T extends AppSettingsValue>(
    key: string,
    defaultValue: T,
): IAccountSetting<T> {
    function getAccountId(): string {
        return currentUserId.get() || 'no-user';
    }
    type AccountSettingsMap = Readonly<Record<string, T>> | undefined;
    function getSetting(settings: IAppSettings | undefined) {
        const accountSettings = settings?.get(key) as AccountSettingsMap | undefined;
        return accountSettings?.[getAccountId()];
    }
    function getForAccount(
        userId: string,
        settings: IAppSettings | undefined = AppSettings.instance,
    ): T | undefined {
        const accountSettings = settings?.get(key) as AccountSettingsMap | undefined;
        return accountSettings?.[userId];
    }
    function setForAccount(userId: string, val: T, settings = AppSettings.instance) {
        const accountSettings = settings?.get(key) as AccountSettingsMap | undefined;
        settings.set(key, setIfChanging(accountSettings ?? {}, userId, val));
    }
    return {
        key,
        get: (settings = AppSettings.instance) => getSetting(settings) ?? defaultValue,
        getForAccount,
        isSet: (settings = AppSettings.instance) => getSetting(settings) !== undefined,
        set: (val: T, settings = AppSettings.instance) => {
            setForAccount(getAccountId(), val, settings);
        },
        setForAccount,
        clear: (settings = AppSettings.instance) => {
            const accountId = getAccountId();
            const accountSettings = settings?.get(key) as AccountSettingsMap | undefined;
            if (accountSettings !== undefined) {
                settings.set(key, deleteIfExists(accountSettings, accountId));
            }
        },
        getDefault: () => {
            return defaultValue;
        },
    };
}

export const Migration = {
    latest: makeNumericUserSetting('Migration.latest', 0, 0),
};

export const PatientPlayback = {
    enabled: makeBooleanUserSetting('patientPlaybackEnabled', false),
    pauseDelay: makeNumericUserSetting('patientPlaybackPauseDelay', 2, 0, 60),
    pauseDuration: makeNumericUserSetting('patientPlaybackPauseDuration', 1.5, 0.5, 60),
};

export const TextExport = {
    intervalTimecodeFrequency: makeNumericUserSetting(
        'TextExport.intervalTimecodeFrequency',
        60,
        1,
        Number.MAX_SAFE_INTEGER,
    ),
    intervalTimecodeEnabled: makeBooleanUserSetting('TextExport.intervalTimecodeEnabled', true),
    paragraphTimecodeEnabled: makeBooleanUserSetting(
        'TextExport.paragraphTimecodeEnabled',
        false,
    ),
    speakerTimecodeEnabled: makeBooleanUserSetting('TextExport.speakerTimecodeEnabled', false),
    markerTimecodeEnabled: makeBooleanUserSetting('TextExport.markerTimecodeEnabled', false),
    timecodeOffsetEnabled: makeBooleanUserSetting('TextExport.timecodeOffsetEnabled', false),
    timecodeOffset: makeNumericUserSetting(
        'TextExport.timecodeOffset',
        0,
        0,
        Number.MAX_SAFE_INTEGER,
    ),
    includeCompositionName: makeBooleanUserSetting('TextExport.includeCompositionName', true),
    includeMarkers: makeBooleanUserSetting('TextExport.includeMarkers', true),
    includeSpeakerLabels: makeBooleanUserSetting('TextExport.includeSpeakerLabels', true),
    allSpeakerLabels: makeBooleanUserSetting('TextExport.allSpeakerLabels', false),
    includeIgnoredText: makeBooleanUserSetting('TextExport.includeIgnoredText', true),
    fileFormat: makeUserSetting('TextExport.category', ExportFileFormat.Word),
} as IUserSettings<TextExportSettings>;

export const SubtitleExport = {
    maxLineLength: makeNumericUserSetting(
        'SubtitleExport.maxLineLength',
        42,
        1,
        Number.MAX_SAFE_INTEGER,
    ),
    maxLinesPerCard: makeNumericUserSetting(
        'SubtitleExport.maxLinesPerCard',
        2,
        1,
        Number.MAX_SAFE_INTEGER,
    ),
    includeSpeakerNames: makeBooleanUserSetting('SubtitleExport.includeSpeakerNames', false),
    fileFormat: makeUserSetting('SubtitleExport.category', ExportFileFormat.SRT),
} as IUserSettings<SubtitleExportSettings>;

export const TimelineExport = {
    // Note: This was renamed to TimelineExport, but we're keeping the old name here for compatibility.
    fileFormat: makeUserSetting('SessionExport.category', ExportFileFormat.AAF),
    copyMedia: makeBooleanUserSetting('SessionExport.copyMedia', true),
    stripSpaces: makeBooleanUserSetting('SessionExport.stripSpaces', false),
    createTrackPerFile: makeBooleanUserSetting('SessionExport.createTrackPerFile', false),
    skipExistingMediaFiles: makeBooleanUserSetting(
        'SessionExport.skipExistingMediaFiles',
        false,
    ),
} as IUserSettings<TimelineExportSettings>;

export const AudioExport = {
    bitrate: makeUserSetting('AudioExport.bitrate', Quality.BitrateDefault),
    samplerate: makeUserSetting<Quality.Samplerate>('AudioExport.samplerate', 44100),
    channelCount: makeNumericUserSetting(
        'AudioExport.channelCount',
        1,
        1,
        Number.MAX_SAFE_INTEGER,
    ),
    fileFormat: makeUserSetting('AudioExport.category', ExportFileFormat.WAV),
    normalization: makeUserSetting('AudioExport.normalization', Quality.NormalizationDefault),
    scope: makeUserSetting('AudioExport.scope', DefaultExportScope),
} as IUserSettings<AudioExportSettings>;

export const GifExport = {
    // Technically GIF exports don't need audio settings but we include them in order to make
    // typing easier in the export saga.
    bitrate: makeUserSetting('VideoExport.bitrate', Quality.BitrateDefault),
    samplerate: makeUserSetting<Quality.Samplerate>('VideoExport.samplerate', 48000),
    channelCount: makeNumericUserSetting(
        'VideoExport.channelCount',
        2,
        1,
        Number.MAX_SAFE_INTEGER,
    ),
    dimensions: makeUserSetting<Dimensions>(
        'GifExport.dimensions',
        AppConstants.Dimensions.defaultUserSetting,
    ),
    quality: makeUserSetting('GifExport.quality', Quality.VideoQualityDefault),
    fileFormat: makeUserSetting('GifExport.category', ExportFileFormat.GIF),
    normalization: makeUserSetting('AudioExport.normalization', Quality.NormalizationDefault),
    scope: makeUserSetting('GifExport.scope', DefaultExportScope),
    freeExportDimensions: makeUserSetting<Dimensions>(
        'GifExport.freeExportDimensions',
        AppConstants.Dimensions.defaultUserSetting,
    ),
} as IUserSettings<GifExportSettings>;

export const VideoExport = {
    bitrate: makeUserSetting('VideoExport.bitrate', Quality.BitrateDefault),
    samplerate: makeUserSetting<Quality.Samplerate>('VideoExport.samplerate', 48000),
    channelCount: makeNumericUserSetting(
        'VideoExport.channelCount',
        2,
        1,
        Number.MAX_SAFE_INTEGER,
    ),
    dimensions: makeUserSetting<Dimensions>(
        'VideoExport.dimensions',
        AppConstants.Dimensions.defaultUserSetting,
    ),
    quality: makeUserSetting('VideoExport.quality', Quality.VideoQualityDefault),
    fileFormat: makeUserSetting('VideoExport.category', ExportFileFormat.MP4),
    normalization: makeUserSetting('AudioExport.normalization', Quality.NormalizationDefault),
    scope: makeUserSetting('VideoExport.scope', DefaultExportScope),
    freeExportDimensions: makeUserSetting<Dimensions>(
        'VideoExport.freeExportDimensions',
        AppConstants.Dimensions.defaultUserSetting,
    ),
} as IUserSettings<VideoExportSettings>;

export type DriveViewDisplay = 'grid' | 'list';

export const Application = {
    installId: makeUserSetting<string>(
        ApplicationInstallId,
        uuidv4(),
        // storing installId in authInstance so it is accessible across domains/subdomains on web
        () => AppSettings.authInstance,
    ),
    isLoggedIn: makeUserSetting<string>(
        'Application.isLoggedIn',
        'false',
        // storing installId in authInstance so it is accessible across domains/subdomains on web
        () => AppSettings.authInstance,
    ),
    windows: makeUserSetting<WindowState[]>('Application.windows', []),
    dismissedRemoteRecordingWarning: makeUserSetting<
        | {
              date: number;
          }
        | undefined
    >('Application.dismissedRemoteRecordingWarning', undefined),
    dismissedBetaWarning: makeUserSetting<
        | {
              date: number;
              choice: 'web' | 'desktop';
          }
        | undefined
    >('Application.dismissedBetaWarning', undefined),
    isPropertyPanelLayoutSectionExpanded: makeBooleanUserSetting(
        'Application.isPropertyPanelLayoutSectionExpanded',
        false,
    ),
    showTrackInspector: makeBooleanUserSetting('Application.showTrackInspector', false),
    showScript: makeBooleanUserSetting('Application.showScript', true),
    timelineLayerDensity: makeUserSetting<TimelineLayerDensity>(
        'Application.timelineLayerDensity',
        'default',
    ),
    showTimeline: makeBooleanUserSetting('Application.showTimeline', true),
    showVUMeterInTimeline: makeBooleanUserSetting('Application.showVUMeterInTimeline', false),
    sidebarState: makeUserSetting<Record<string, IconSidebarState>>(
        'Application.sidebarState',
        {},
    ),
    previousSidebarState: makeUserSetting<Record<string, IconSidebarState>>(
        'Application.previousSidebarState',
        {},
    ),
    compositionAreaPercentage: makeUserSetting<number | undefined>(
        'Application.compositionAreaPercentage',
        undefined,
    ),
    isTimelineHidden: makeBooleanUserSetting('Application.isTimelineHidden', true),
    isTimelineExpanded: makeBooleanUserSetting('Application.isTimelineExpanded', false),
    timelineSize: makeNumericUserSetting(
        'Application.timelineSize',
        AppConstants.Timeline.Classic.scriptSizeDefault,
        AppConstants.Timeline.Classic.scriptSizeMin,
        Infinity,
    ),
    uiDimensions: makeUserSetting<PopoverDimensions>(
        'Application.uiDimensions',
        defaultPopoverDimensions,
    ),
    uiPositions: makeUserSetting<PopoverPositions>('Application.uiPositions', {}),
    timelineScriptSize: makeNumericUserSetting(
        'Application.timelineScriptSize',
        AppConstants.Timeline.Classic.scriptSizeDefault,
        AppConstants.Timeline.Classic.scriptSizeMin,
        Infinity,
    ),
    timelineScriptSizeStoryboard: makeNumericUserSetting(
        'Application.timelineScriptSizeStoryboard',
        AppConstants.Timeline.Storyboard.scriptSizeMin,
        AppConstants.Timeline.Storyboard.wordbarHeight,
        Infinity,
    ),
    projectPageSidebarSize: makeNumericUserSetting(
        'Application.projectPageSidebarSize',
        AppConstants.projectSidebarDefaultSize,
        AppConstants.projectPageSidebarSizeMin,
    ),
    projectSidebarCollapsed: makeBooleanUserSetting(
        'Application.projectSidebarCollapsed',
        true,
    ),
    cardRailSize: makeNumericUserSetting(
        'Application.cardRailSize',
        AppConstants.minCardRailSize,
        AppConstants.minCardRailSize,
        AppConstants.maxCardRailSize,
    ),
    videoPaneSize: makeNumericUserSetting('Application.videoPaneSize', 450, 50),
    videoPanePosition: makeUserSetting<VideoPanePosition>(
        'Application.videoPanePosition',
        'right',
    ),
    mediaLibraryInspectorSize: makeUserSetting<number>(
        'Application.mediaLibraryInspectorSize',
        AppConstants.minMediaLibraryInspectorSize,
    ),
    mediaTreeSortState: makeUserSetting<SortState>('Application.mediaTreeSortState', {
        sortOrder: 'manual',
        sortAscending: true,
    }),
    sidebarSortState: makeUserSetting<SortState>('Application.sidebarSortState', {
        sortOrder: 'manual',
        sortAscending: true,
    }),
    templateBrowserSortState: makeUserSetting<TemplateBrowserSortState>(
        'Application.templateBrowserSortState',
        {
            column: undefined,
            ascending: undefined,
        },
    ),
    templateBrowserFilterState: makeUserSetting<TemplateSectionType>(
        'Application.templateBrowserFilterState',
        TemplateSectionType.Drive,
    ),
    showAllLayouts: makeUserSetting<boolean>('Application.showAllLayouts', false),
    projectFilter: makeUserSetting<ProjectFilterSettings>('Application.projectFilter', {
        lastPath: undefined,
        lastSortColumn: ProjectBrowserTableColumn.LastUpdatedAt,
        lastSortAscending: getDefaultSortAscending(ProjectBrowserTableColumn.LastUpdatedAt),
        filter: ProjectFilter.All,
        editorVariantFilter: EditorVariantFilter.Projects,
        projectSubFilter: ProjectSubFilter.All,
        driveId: undefined,
        folderId: RootFolder,
        workspaceId: undefined,
    }),
    googleAuth: makeUserSetting<GoogleAuthState>('Application.googleAuth', {
        driveAccess: undefined,
        youTubeAccess: undefined,
    }),
    apiTarget: makeUserSetting<ApiTargetName>('Application.apiTarget', 'production'),
    liveTranscriptionApiTarget: makeUserSetting<ApiTargetName>(
        'Application.liveTranscriptionApiTarget',
        'production',
    ),
    publishWebTarget: makeUserSetting<PublishWebTargetName>(
        'Application.publishWebTarget',
        'production',
    ),
    createCompositionOnTranscription: makeBooleanUserSetting(
        'Application.createCompositionOnTranscription',
        true,
    ),
    lastVersionChangelogViewed: makeUserSetting<string | undefined>(
        'Application.lastVersionChangelogViewed',
        undefined,
    ),
    lastVoiceModelIdPromoted: makeUserSetting<string | undefined>(
        'Application.lastVoiceModelIdPromoted',
        undefined,
    ),
    driveViewDisplay: makeUserSetting<DriveViewDisplay>('Application.driveViewDisplay', 'list'),
    lastUpdatedType: makeUserSetting<LastUpdatedType>(
        'Application.lastUpdatedType',
        LastUpdatedTypeDefault,
    ),
    lastWatermarkRemoval: makeUserSetting<LastWatermarkRemoval>(
        'Application.lastWatermarkRemoval',
        {},
    ),
    showingPublishedBrowserCardView: makeBooleanUserSetting(
        'Application.showingPublishedBrowserCardView',
        true,
    ),
    currentUserId,
    keepOpenOnQuit: makeBooleanUserSetting('Application.keepOpenOnQuit', true),
    launchAtLogin: makeBooleanUserSetting('Application.launchAtLogin', true),
    featureFlags: makeAccountSetting<FeatureFlagSettings>('Application.featureFlags', {}),
    featureFlagOverrides: makeAccountSetting<FeatureFlagSettings>(
        'Application.featureFlagsOverrides',
        {},
    ),
    publicFeatureFlags: makeAccountSetting<FeatureFlagSettings>(
        'Application.publicFeatureFlags',
        {},
    ),
    showRoomtoneModal: makeBooleanUserSetting('Application.showRoomtoneModal', true),
    showVersion4Onboarding: makeBooleanUserSetting('Application.showVersion4Onboarding', true),
    showStoryboardNewCardOnboarding: makeBooleanUserSetting(
        'Application.showStoryboardNewCardOnboarding',
        true,
    ),
    showRoomtoneProperties: makeBooleanUserSetting('Application.showRoomtoneProperties', false),
    // Recently-used fonts, with most recently used first.
    recentFonts: makeUserSetting<string[]>('Project.recentFonts', []),
    transcriptionLanguage: makeUserSetting<TranscriptionLanguage | undefined>(
        'Application.transcriptionLanguage',
        undefined,
    ),
    colorScheme: makeUserSetting<ColorScheme>('Application.colorScheme', 'system'),
    defaultProjectType: makeUserSetting<ProjectType | undefined>(
        'Application.defaultProjectType',
        undefined,
    ),
    alwaysPromptForTranscription: makeUserSetting<boolean | undefined>(
        'Application.alwaysPromptForTranscriptionLanguage',
        undefined,
        undefined,
    ),
    promptForSpeakerDetection: makeUserSetting<boolean | undefined>(
        'Application.promptForSpeakerDetection',
        undefined,
        undefined,
    ),
    showLiveTranscriptionUnavailableMessage: makeUserSetting<boolean>(
        'Application.showLiveTranscriptionUnavailableMessage',
        true,
    ),
    // keep track of whether users have gained access to different features
    accessInAppStarted: makeAccountSetting<
        Partial<{
            storyboard: boolean;
        }>
    >('Application.accessInAppStarted', {}),
    bootCachePayload: makeUserSetting<string | undefined>(
        'Application.bootCachePayload',
        undefined,
    ),
    accountLinkingContext: makeUserSetting<AccountLinkingContext | undefined>(
        'Application.accountLinkingContext',
        undefined,
    ),
    recentlyUsedColors: makeUserSetting<number[][]>('Application.recentlyUsedColors', []),
    webCodecsCapabilitySurveyCompletedTime: makeNumericUserSetting(
        'Application.webCodecsCapabilitySurveyCompletedTime',
        0,
    ),
    skipRegenerateTranslationConfirmation: makeBooleanUserSetting(
        'Application.skipRegenerateTranslationConfirmation',
        false,
    ),
    skipDuplicateTranslationAsCompWarning: makeBooleanUserSetting(
        'Application.skipDuplicateTranslationAsCompWarning',
        false,
    ),
    hasDrive: makeBooleanUserSetting('Application.hasDrive', false),
    hasDismissedTranslationNavTutorial: makeBooleanUserSetting(
        'Application.hasDismissedTranslationNavTutorial',
        false,
    ),
};

export const Search = {
    copyRangeType: makeUserSetting<CopyRangeType>('Search.copyRangeType', CopyRangeType.Exact),
    wordGapFilter: makeNumericUserSetting('Search.wordGapFilter', 3, 0.01),
    wordGapShortenDuration: makeNumericUserSetting('Search.wordGapShortenDuration', 1, 0),
    preserveGapsOnDelete: makeBooleanUserSetting('Search.preserveGapsOnDelete', false),
    spotAudition: makeBooleanUserSetting('Search.spotAudition', true),
};

export const Script = {
    showEditBoundaries: makeBooleanUserSetting('Script.showEditBoundaries', true),
    decorateFillerWords: makeBooleanUserSetting('Script.decorateFillerWords', true),
    decorateWordErrors: makeBooleanUserSetting('Script.decorateWordErrors', true),
    applySpeakerLabelToSingleParagraph: makeBooleanUserSetting(
        'Script.applySpeakerLabelToSingleParagraph',
        false,
    ),
};

export const Timeline = {
    timelineTool: makeUserSetting<TimelineTool>('Timeline.timelineTool', 'select'),
};

export const Project = {
    openLinksInDesktopApp: makeBooleanUserSetting('Project.openLinksInDesktopApp', false),
    originalAssetDefault: makeBooleanUserSetting('Project.originalAssetDefault', false),
    automaticallyLevelClipsDefault: makeBooleanUserSetting(
        'Project.automaticallyLevelClipsDefault',
        true,
    ),
    savedForOffline: makeUserSetting<string[]>('Project.savedForOffline', []),
    showAllVersions: makeBooleanUserSetting('Project.showAllRevisions', false),
    showAllTracks: makeBooleanUserSetting('Project.showAllTracks', false),
    useRoomtoneOnGapClips: makeBooleanUserSetting('Project.useRoomtoneOnGapClips', true),
    autoGenerateProxy: makeBooleanUserSetting(
        'Project.autoGenerateProxy',
        !DescriptFeatures.MANUAL_PROXY_GENERATION_DEFAULT,
    ),

    // This is an array of project ids for which the user has dismissed
    // the upgrade to storyboard banner
    storyboardUpgradePromptDismissed: makeUserSetting<string[]>(
        'Project.storyboardUpgradePromptDismissed',
        [],
    ),
    // This is used to save composition details for a project
    // It will track last active/opened compositions per project as well as the playhead positions
    // It is grouped by projectId and the playheads are further grouped by compositionId
    savedProjectCompositions: makeUserSetting<Record<string, SavedProjectCompositions>>(
        'Project.savedProjectCompositions',
        {},
    ),
    // Used to make sure we only check each project once for late revisions after migrating to storyboard
    checkedForLateRevisions: makeUserSetting<string[]>('Project.checkedForLateRevisions', []),
};

export const ClassicRecording = {
    automaticallyTranscribe: makeBooleanUserSetting('Recording.autoTranscribe', true),
    showComputerAudioModal: makeBooleanUserSetting('Recording.showComputerAudioModal', true),
    lastConfiguration: makeUserSetting<RecordingConfigurationPreference | undefined>(
        'Recording.lastConfiguration_v2',
        undefined,
    ),
};

// Keys still use legacy "ScreenRecording" name to prevent users' current settings from being lost
export const Recording = {
    audioDevice: makeUserSetting<QuickRecordingAudioDevicePreference | undefined>(
        'ScreenRecording.audioDevice_v2',
        'default',
    ),
    audioDeviceEnabled: makeBooleanUserSetting('ScreenRecording.audioDeviceEnabled', true),
    systemAudioEnabled: makeBooleanUserSetting('ScreenRecording.includeSystemAudio', false),
    systemAudioAvailable: makeBooleanUserSetting('ScreenRecording.systemAudioAvailable', true),
    videoDevice: makeUserSetting<string | undefined>('ScreenRecording.videoDevice', undefined),
    videoDeviceEnabled: makeBooleanUserSetting('ScreenRecording.videoDeviceEnabled', true),
    monitorEnabled: makeBooleanUserSetting('ScreenRecording.monitorEnabled', false),
    driveId: makeUserSetting<string | undefined>('ScreenRecording.driveId', undefined),
    transcriptionEnabled: makeBooleanUserSetting('ScreenRecording.transcribeMicrophone', true),
    flipCamera: makeBooleanUserSetting('ScreenRecording.flipCamera_v2', true),
    keyboardShortcut: makeUserSetting<string | undefined>(
        'ScreenRecording.keyboardShortcut',
        AppConstants.QuickRecording.defaultKeyboardShortcut,
    ),
    recordingMode: makeUserSetting<QuickRecordingMode>(
        'ScreenRecording.recordingMode',
        'screen',
    ),
    recordingCompleteAction: makeUserSetting<QuickRecordingCompleteAction>(
        'ScreenRecording.recordingCompleteAction',
        'share',
    ),
    highlightMouseClicks: makeBooleanUserSetting('ScreenRecording.highlightMouseClicks', true),
    hideRecordingControls: makeBooleanUserSetting(
        'ScreenRecording.hideRecordingControls',
        false,
    ),
    showRegionRecordingConfirmation: makeBooleanUserSetting(
        'ScreenRecording.showRegionRecordingConfirmation',
        true,
    ),
    gpuAccelerationEnabled: makeBooleanUserSetting(
        'ScreenRecording.gpuAccelerationEnabled',
        true,
    ),
    studioSoundEnabled: makeBooleanUserSetting('ScreenRecording.studioSoundEnabled', false),
    countdownEnabled: makeBooleanUserSetting('ScreenRecording.countdownEnabled', true),
    allowScreenCaptureKit: makeBooleanUserSetting(
        'ScreenRecording.allowScreenCaptureKit',
        true,
    ),
    driveRecordingQualityMap: makeUserSetting<DriveRecordingQualityMap>(
        'ScreenRecording.driveRecordingQualityMap',
        {},
    ),
};

export const WebRecording = {
    countdownEnabled: makeBooleanUserSetting('WebRecording.countdownEnabled', true),
    videoDevice: makeUserSetting<string | undefined>('WebRecording.videoDevice', undefined),
    audioDevice: makeUserSetting<string | undefined>('WebRecording.audioDevice', undefined),
    driveRecordingQualityMap: makeUserSetting<DriveRecordingQualityMap>(
        'WebRecording.driveRecordingQualityMap',
        {},
    ),
    microphoneAudio: makeUserSetting<WebRecordingSettings['microphoneAudio']>(
        'WebRecording.microphoneAudio',
        {
            transcriptionEnabled: true,
            studioSoundEnabled: false,
            studioSoundIntensity: 1,
            speakerId: undefined,
        },
    ),
    computerAudio: makeUserSetting<WebRecordingSettings['computerAudio']>(
        'WebRecording.computerAudio',
        {
            transcriptionEnabled: true,
            studioSoundEnabled: false,
            studioSoundIntensity: 1,
            speakerId: undefined,
        },
    ),
};

export const ZoomRecordings = {
    projectBrowsePageCardDismissed: makeBooleanUserSetting(
        'ZoomRecordings.projectBrowsePageCardDismissed',
        false,
    ),
};

export const Voice = {
    /**
     * @deprecated
     */
    defaultBackendVoiceId: makeUserSetting<string | undefined>(
        'Voice.defaultBackendVoiceId',
        undefined,
    ),

    defaultSpeaker: makeUserSetting<DefaultSpeaker | undefined>(
        'Voice.defaultSpeaker',
        undefined,
    ),
};

export const PublishUI = {
    publishType: makeUserSetting<PublishType>('PublishUI.publishType', PublishTypeDefault),
};

export const QuickEdit: IUserSettings<QuickEditSettings> = {
    openWebpageAfterPublish: makeBooleanUserSetting('QuickEdit.openWebpageAfterPublish', true),
    showAuthorOnPublish: makeBooleanUserSetting('QuickEdit.showAuthorOnPublish', true),
    enableCommentsOnPublish: makeBooleanUserSetting('QuickEdit.enableCommentsOnPublish', true),
    defaultVoice: makeUserSetting<JSONObjectCompat<VoiceModel | undefined>>(
        'QuickEdit.defaultVoice',
        undefined,
    ),
};

export const FirstRunExperience: IUserSettings<FirstRunExperienceSettings> = {
    showAltClickPlayback: makeBooleanUserSetting(
        'FirstRunExperience.showAltClickPlayback',
        true,
    ),
    showCapitalization: makeBooleanUserSetting('FirstRunExperience.showCapitalization', true),
    showPunctuation: makeBooleanUserSetting('FirstRunExperience.showPunctuation', true),
    showStudioSoundOnboardingExperience: makeBooleanUserSetting(
        'FirstRunExperience.showStudioSoundOnboardingExperience',
        true,
    ),
    showTimelineScrollStoryboardOnboardingTip: makeBooleanUserSetting(
        'FirstRunExperience.showTimelineScrollStoryboardOnboardingTip',
        true,
    ),
    showHiddenTimelineTip: makeBooleanUserSetting(
        'FirstRunExperience.showHiddenTimelineTip',
        true,
    ),
};

export const Debug = {
    beamcoder: makeBooleanUserSetting(
        'Debug.useBeamcoder3',
        DescriptFeatures.BEAMCODER_DEFAULT,
    ),
    currentSpeakerPreferenceTime: makeNumericUserSetting(
        'Debug.currentSpeakerPreferenceTime',
        1,
        0,
        1000,
    ),
    showHiddenFiles: makeBooleanUserSetting('Debug.showHiddenFiles', false),
    showBetaLearningVideos: makeBooleanUserSetting('Debug.showBetaLearningVideos', false),
};

export const Video = {
    webGlPreserveDrawingBuffer: makeBooleanUserSetting(
        'Video.webGlPreserveDrawingBuffer',
        false,
    ),
};

export const DebugProxy = {
    keyframeInterval: makeNumericUserSetting('DebugProxy.keyframeInterval', 30, 1, 1000),
    hardwareEncoding: makeBooleanUserSetting('DebugProxy.hardwareEncoding', true),
    maxFrameRate: makeNumericUserSetting('DebugProxy.maxFrameRate', 30, 1, 200),
    maxDimension: makeNumericUserSetting('DebugProxy.maxDimension', 1280, 1, 10_000),
    crf: makeNumericUserSetting('DebugProxy.crf', 20, 0, 51),
    videoCodec: makeUserSetting<ProxyVideoCodec>('DebugProxy.videoCodec', 'h265'),
    videoEncodingSpeed: makeUserSetting<ProxyVideoEncodingSpeed>(
        'DebugProxy.videoEncodingSpeed',
        'ultrafast',
    ),
    maxCpus: makeNumericUserSetting('DebugProxy.maxCpus', 2, 1),
};

export const Analytics = {
    firedAppInstalled: makeBooleanUserSetting('fired_app_installed', false),
    transcription: makeUserSetting<Record<string, number>>('transcription-events', {}),
};

export const DefaultEffects = {
    title: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.title',
        undefined,
    ),
    titleText: makeUserSetting<Partial<IEncodedTextVector> | undefined>(
        'DefaultEffects.titleText',
        undefined,
    ),
    captions: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.captions',
        undefined,
    ),
    captionsText: makeUserSetting<Partial<IEncodedTextVector> | undefined>(
        'DefaultEffects.captionsText',
        undefined,
    ),
    dynamicText: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        // ##TextClipSharedDefaults
        // Share default effects between title and dynamic text, since they're added via
        // the same entry point (text clip)
        'DefaultEffects.title',
        undefined,
    ),
    dynamicTextText: makeUserSetting<Partial<IEncodedTextVector> | undefined>(
        // #TextClipSharedDefaults
        'DefaultEffects.titleText',
        undefined,
    ),
    rectangle: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.rectangle',
        undefined,
    ),
    progress: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.progress',
        undefined,
    ),
    progressVector: makeUserSetting<Partial<Progress> | undefined>(
        'DefaultEffects.progressVisual',
        undefined,
    ),
    ellipse: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.ellipse',
        undefined,
    ),
    line: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.line',
        undefined,
    ),
    arrow: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.arrow',
        undefined,
    ),
    waveform: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.waveform',
        undefined,
    ),
    waveformVector: makeUserSetting<Partial<Waveform> | undefined>(
        'DefaultEffects.waveformVisual',
        undefined,
    ),
    triangle: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.triangle',
        undefined,
    ),
    star: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.star',
        undefined,
    ),
    placeholder: makeUserSetting<Partial<SegmentEffectValueProperties> | undefined>(
        'DefaultEffects.placeholder',
        undefined,
    ),
};

export const AiActions = {
    savedParams: makeUserSetting<Partial<Record<PresetId, SubmitFnArgs>>>(
        'AiActions.savedParams',
        {},
    ),
};

export const LearningRail = {
    videoWidth: makeNumericUserSetting(
        'LearningRail.videoWidth',
        AppConstants.learningRailVideoWidthDefault,
    ),
};

export const Canvas = {
    snapToGuides: makeBooleanUserSetting('Canvas.snapToGuides', true),
};

export const EditorRecording = {
    hideWindowsOnScreenRecord: makeBooleanUserSetting(
        'ScreenRecording.hideWindowsOnScreenRecord',
        true,
    ),
    lastConfiguration: makeUserSetting<EditorRecordingConfiguration | undefined>(
        'EditorRecording.lastConfiguration_v5',
        undefined,
    ),
};

export const SharePage = {
    playbackSpeed: makeNumericUserSetting('SharePage.playbackSpeed', 1, 0.5, 2),
    volumeLevel: makeNumericUserSetting('SharePage.volumeLevel', 1, 0, 1),
    volumeMuted: makeBooleanUserSetting('SharePage.volumeMuted', false),
    closedCaptionsEnabled: makeBooleanUserSetting('SharePage.closedCaptionsEnabled', false),
};

// These keys are deprecated, but are listed here with placeholder values so clearUserSettings will still remove them
export const Old = {
    audioDevice: makeUserSetting<QuickRecordingAudioDevicePreference>(
        'ScreenRecording.audioDevice',
        'default',
    ),
    currentDirectory: makeUserSetting<string | undefined>(
        'Application.currentDirectory',
        undefined,
    ),
    lastConfiguration: makeUserSetting('Recording.lastConfiguration', false),
    transcribeSystemAudio: makeBooleanUserSetting(
        'ScreenRecording.transcribeSystemAudio',
        true,
    ),
    wordGapFilter: makeNumericUserSetting('Application.wordGapFilter', 3),
    wordGapShortenDuration: makeNumericUserSetting('Application.wordGapShortenDuration', 1),
    editMode: makeBooleanUserSetting('Debug.editMode', true),
    showTranscriptScenes: makeBooleanUserSetting('Debug.showTranscriptScenes', false),
    protoLiveCollabProjects: makeUserSetting<readonly string[]>(
        'Debug.protoLiveCollabProjects',
        [],
    ),
    showLoomImportClipboardModal: makeUserSetting<boolean>(
        'Application.showLoomImportClipboardModal',
        true,
    ),
    watchClipboardForImportableMedia: makeUserSetting<boolean | undefined>(
        'Application.watchClipboardForImportableMedia',
        // undefined: enabled, but prompt user with modal about the feature
        // true: enabled
        // false: disabled
        undefined,
    ),
};
