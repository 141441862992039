// Copyright 2024 Descript, Inc

import { ScopedLogger } from '@descript/descript-core';

let analyticsLogger: ScopedLogger | undefined;

export function setAnalyticsLogger(logger: ScopedLogger | undefined) {
    analyticsLogger = logger;
}

export function getAnalyticsLogger(): ScopedLogger | undefined {
    return analyticsLogger;
}
