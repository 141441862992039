// Copyright 2023 Descript, Inc

import { JSONObject } from '@descript/descript-core';

export interface AnalyticsPropertiesType<State> {
    installId: string;
    /** User properties included on every identify event */
    genericProperties: JSONObject;
    genericContext: JSONObject;
    /** Function to get properties included on every track event */
    getAdditionalProperties?: (state: Partial<State>) => Partial<Record<string, unknown>>;
    /** Properties included on the initial page event */
    getPageProperties?: () => {
        page: string;
        params: Record<string, unknown>;
    };
}

export let AnalyticsProperties: AnalyticsPropertiesType<unknown> = {
    installId: '',
    genericProperties: {},
    genericContext: {},
    getAdditionalProperties: () => ({}),
    getPageProperties: undefined,
};

export function setAnalyticsProperties<State>(
    analyticsProperties: Partial<AnalyticsPropertiesType<State>>,
): void {
    AnalyticsProperties = { ...AnalyticsProperties, ...analyticsProperties };
}
