// Copyright 2024 Descript, Inc

import { ApiTargetName } from '../../Api/ApiTarget';
export const cdn = 'https://cdn.descript.com';
export const stagingCdn = 'https://staging-cdn.descript.com';
export const learningRail = 'https://learn.descript.com';
export const stagingLearningRail = [
    'https://learn-descript.webflow.io',
    'https://staging-learn-descript.webflow.io',
];
export const youtube = 'https://www.youtube.com';
export const youtubeThumbnails = 'https://img.youtube.com';
/**
 * !!! IMPORTANT !!!
 *
 * If you change application hosts, make sure to update the whitelisted callbacks in Auth0 application settings
 **/
// WEB
export const web = 'https://web.descript.com';
export const qaWeb = 'https://qa-web.descript.com';
export const stagingWeb = 'https://staging-web.descript.com';
export const staging2Web = 'https://staging2-web.descript.com';
export const secureDevWeb = 'https://local-dev.descript.com:3000';
export const devWeb = `http://localhost:${process.env.WEB_PORT || '3000'}`;
// WEB SHARE
export const webShare = 'https://share.descript.com';
export const qaWebShare = 'https://qa-share.descript.com';
export const stagingWebShare = 'https://staging-share.descript.com';
export const secureDevWebShare = 'https://local-dev.descript.com:3002';
export const staging2WebShare = 'https://staging2-share.descript.com';
export const stagingApiWebShare = 'https://staging-api-share.descript.com';
export const devWebShare = 'http://localhost:3002';
// API
export const api = 'https://api.descript.com';
export const stagingApi = 'https://staging-api.descript.com';
export const developmentApi = 'https://dev-api.descript.com';
export const devApi = 'http://localhost';
export const devCoderApi = 'coder.descripthq.com';

export const webToShareHost: Record<string, string> = {
    [web]: webShare,
    [qaWeb]: qaWebShare,
    [stagingWeb]: stagingWebShare,
    [secureDevWeb]: secureDevWebShare,
};

export const shareToWebHost: Record<string, string> = {
    [webShare]: web,
    [qaWebShare]: qaWeb,
    [stagingWebShare]: stagingWeb,
    [secureDevWebShare]: secureDevWeb,
};

export const desktopToWebHost: Record<ApiTargetName, string> = {
    localhost: secureDevWeb,
    'localhost-with-staging-data': stagingWeb,
    dev: stagingWeb,
    staging: stagingWeb,
    production: web,
};
