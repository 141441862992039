// Copyright 2018 Descript, Inc

import { TypedArray } from './TypedArray';
import { DescriptError, ErrorCategory } from '@descript/errors';

export function arrayWindower<T extends TypedArray>(
    ArrayType: { new (length: number): T },
    windowLength: number,
    hopLength: number,
    callback: (buffer: T) => void,
): {
    process: (input: T) => void;
    done: () => void;
} {
    if (hopLength < 1) {
        throw new DescriptError('hop length < 1', ErrorCategory.AppArchitecture);
    }
    if (hopLength > windowLength) {
        throw new DescriptError('hop length > window length', ErrorCategory.AppArchitecture);
    }
    // To reduce array copies when hop < window size and input is ≤ window size, we need extra buffer space to avoid
    // copying data on every process input
    const bufferSize = windowLength * 2 - hopLength;
    const buffer = new ArrayType(bufferSize);
    let bufferIndex = 0;
    let bufferUsage = 0;

    return {
        process: (input: T): void => {
            // We are receiving some number of input, and we might have part of a leftover input from before
            for (let i = -bufferUsage; i < input.length; ) {
                if (i < 0) {
                    // We have leftover bytes from before we need to add to
                    const array = input.subarray(
                        i + bufferUsage,
                        Math.min(i + windowLength, input.length),
                    );
                    const arrayLength = array.length;
                    // Do we have enough room in the buffer to store this?
                    if (bufferIndex + bufferUsage + arrayLength > bufferSize) {
                        // If not, we need to slide everything to the front of the array
                        buffer.copyWithin(0, bufferIndex, bufferIndex + bufferUsage);
                        bufferIndex = 0;
                    }
                    buffer.set(array, bufferIndex + bufferUsage);
                    bufferUsage += arrayLength;
                    if (bufferUsage < windowLength) {
                        // We don't have a full frame
                        break;
                    }
                    callback(buffer.subarray(bufferIndex, bufferIndex + windowLength));
                    i += hopLength;
                    // Do we still need the buffered data, or can we get everything from "input" now?
                    if (i < 0) {
                        // Shift everything in buffer to left by hopLength
                        bufferIndex += hopLength;
                        bufferUsage -= hopLength;
                    } else {
                        bufferUsage = 0;
                        bufferIndex = 0;
                    }
                } else if (i + windowLength <= input.length) {
                    // We have a full frame in `input`
                    callback(input.subarray(i, i + windowLength));
                    i += hopLength;
                } else {
                    // We have leftover data in `input` to save for the next call to process() or done()
                    const remainder = input.subarray(i, input.length);
                    buffer.set(remainder, bufferUsage);
                    bufferUsage += remainder.length;
                    break;
                }
            }
        },
        done: () => {
            if (bufferUsage > 0) {
                callback(buffer.subarray(bufferIndex, bufferIndex + bufferUsage));
            }
        },
    };
}
