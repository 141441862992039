// Copyright 2023 Descript, Inc

import {
    Span,
    SpanAttributes,
    Context,
    Exception,
    SpanStatus,
    SpanContext,
    TimeInput,
    SpanAttributeValue,
    trace,
    TraceFlags,
    Link,
} from '@opentelemetry/api';
import { DescriptError, ErrorCategory } from '@descript/errors';
import { errorCategorySymbol } from './tags';

export interface AsyncContext extends Context {
    readonly span: Span;
    setSpan(span: Span): AsyncContext;

    /**
     * Attributes that are added to this span every descendant span.
     * Since this data will also (eventually) get propagated between processes,
     * including to the API servers, so use sparingly.
     */
    readonly traceMetadata?: Readonly<SpanAttributes>;
}

export class BaseAsyncContext implements AsyncContext {
    public readonly traceMetadata?: Readonly<SpanAttributes>;
    public readonly contextData: Readonly<Record<symbol, unknown>>;

    static create({
        span = new NoopSpan(),
        traceMetadata,
    }: Partial<{
        span: Span;
        traceMetadata: Readonly<SpanAttributes>;
    }> = {}): BaseAsyncContext {
        const ctx = new BaseAsyncContext({ traceMetadata });
        return ctx.setSpan(span);
    }

    private constructor({
        traceMetadata,
        contextData = {},
    }: Partial<{
        traceMetadata: Readonly<SpanAttributes>;
        contextData: Readonly<Record<symbol, unknown>>;
    }> = {}) {
        this.traceMetadata = traceMetadata;
        this.contextData = contextData;
    }

    get span(): Span {
        const span = trace.getSpan(this);
        if (!span) {
            throw new DescriptError('no span found on context', ErrorCategory.VideoMediaEngine);
        }
        return span;
    }

    deleteValue(key: symbol): BaseAsyncContext {
        if (!(key in this.contextData)) {
            return this;
        }
        const contextData = { ...this.contextData };
        delete contextData[key];
        return new BaseAsyncContext({
            traceMetadata: this.traceMetadata,
            contextData,
        });
    }

    getValue(key: symbol): unknown {
        return this.contextData[key];
    }

    setValue(key: symbol, value: unknown): BaseAsyncContext {
        if (key in this.contextData && this.contextData[key] === value) {
            return this;
        }
        return new BaseAsyncContext({
            traceMetadata: this.traceMetadata,
            contextData: { ...this.contextData, [key]: value },
        });
    }

    setSpan(span: Span): BaseAsyncContext {
        if (span === trace.getSpan(this)) {
            return this;
        }
        return trace.setSpan(this, span) as BaseAsyncContext;
    }
}

const NOOP_SPAN_CONTEXT: SpanContext = Object.freeze({
    traceId: '',
    spanId: '',
    traceFlags: TraceFlags.NONE,
    traceState: undefined,
    isRemote: false,
});

export class NoopSpan implements Span {
    addEvent(
        name: string,
        attributesOrStartTime?: SpanAttributes | TimeInput,
        startTime?: TimeInput,
    ): this {
        return this;
    }

    end(endTime?: TimeInput): void {
        // noop
    }

    isRecording(): boolean {
        return false;
    }

    recordException(exception: Exception, time?: TimeInput): void {
        // noop
    }

    setAttribute(key: string, value: SpanAttributeValue): this {
        return this;
    }

    setAttributes(attributes: SpanAttributes): this {
        return this;
    }

    setStatus(status: SpanStatus): this {
        return this;
    }

    spanContext(): SpanContext {
        return NOOP_SPAN_CONTEXT;
    }

    updateName(name: string): this {
        return this;
    }

    addLink(link: Link): this {
        return this;
    }

    addLinks(links: Link[]): this {
        return this;
    }
}

export function errorCategoryContext(category: ErrorCategory) {
    const ctx = BaseAsyncContext.create().setValue(errorCategorySymbol, category);

    return () => {
        return ctx;
    };
}

/**
 * Helper to change the error category on a context.
 */
export function setErrorCategory(ctx: AsyncContext, category: ErrorCategory): AsyncContext {
    return ctx.setValue(errorCategorySymbol, category) as BaseAsyncContext;
}
