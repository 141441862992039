// Copyright 2020 Descript, Inc

import { AppSettingsValue, IAppSettings } from './AppSettings';
import { trackError } from '../Utilities/ErrorTracker';
import { ErrorCategory, invariant } from '@descript/errors';
import { enumerateUserSettings } from './UserSettingsHelpers';
import { StrictEventEmitter } from 'strict-event-emitter-types';
import { EventEmitter } from 'events';
import { fastDeepEqualJson } from '@descript/fast-deep-equal';

function getIsLocalStorageAvailable() {
    const test = 'descript-probe-localstorage';

    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export const isLocalStorageAvailable = getIsLocalStorageAvailable();

const trackedErrorKeys = new Set<string>();

export const SettingUpdated = 'setting-updated';

type SettingUpdatedMessage = {
    key: string;
};

interface WebAppLocalStorageSettingsEvents {
    [SettingUpdated]: SettingUpdatedMessage;
}

type WebAppLocalStorageSettingsEmitter = StrictEventEmitter<
    EventEmitter,
    WebAppLocalStorageSettingsEvents
>;

export default class WebAppLocalStorageSettings implements IAppSettings {
    readonly emitter: WebAppLocalStorageSettingsEmitter = new EventEmitter();
    private readonly validKeys = new Set<string>();
    private readonly storage: Record<string, AppSettingsValue> = {};
    constructor() {
        // Hydrate cache
        this.updateAll();
        // Listen for changes to local storage (e.g. other tabs) and update our cache
        window.addEventListener('storage', ({ key, newValue }) => {
            // Only look at keys we've seen before
            if (key && this.validKeys.has(key)) {
                this.updateFromStore(key, newValue);
                this.emitter.emit(SettingUpdated, { key });
            }
        });
    }

    updateAll = () => {
        // Load all known settings from local storage
        for (const { key } of enumerateUserSettings()) {
            if (isLocalStorageAvailable) {
                this.updateFromStore(key, window.localStorage.getItem(key));
            }
            this.validKeys.add(key);
        }
    };

    private updateFromStore(key: string, jsonString: string | null): void {
        // If the value is null, it means the key was deleted (empty string also triggers this)
        if (!jsonString) {
            delete this.storage[key];
            return;
        }
        try {
            this.storage[key] = JSON.parse(jsonString);
        } catch (err) {
            if (!trackedErrorKeys.has(key)) {
                trackError(err as Error, 'local-storage-get-parse', {
                    category: ErrorCategory.AppSettings,
                    extra: {
                        key,
                        value: jsonString,
                    },
                });
                trackedErrorKeys.add(key);
            }
        }
    }
    get = (key: string) => this.storage[key];
    set = (key: string, value: AppSettingsValue) => {
        invariant(
            this.validKeys.has(key),
            `Unknown setting key: ${key}`,
            ErrorCategory.AppArchitecture,
        );
        if (value === undefined) {
            this.delete(key);
            return;
        }
        if (fastDeepEqualJson(value, this.storage[key])) {
            // Ignore if value hasn't changed
            return;
        }
        this.storage[key] = value;
        const json = JSON.stringify(value);
        if (isLocalStorageAvailable) {
            window.localStorage.setItem(key, json);
        }
        this.emitter.emit(SettingUpdated, { key });
    };
    delete = (key: string) => {
        if (!(key in this.storage)) {
            // Do nothing if value doesn't exist in memory copy
            return;
        }
        delete this.storage[key];
        if (isLocalStorageAvailable) {
            window.localStorage.removeItem(key);
        }
        this.emitter.emit(SettingUpdated, { key });
    };
    getAll = () => this.storage;
}
