// Copyright 2023 Descript, Inc
export const signInUrlQueryKey = 'sign-in-url';
export const signUpUrlQueryKey = 'sign-up-url';
export const signUpUrlWithRedirectToWebQueryKey = 'sign-up-url-with-redirect-to-web';
export const autoAccountLinkQueryKey = 'auto-account-link';
export const auth0MessageParamsQueryKey = 'auth0-message-params';
/**
 * (Web and web-share only)
 * When this query param is set to "true", the Auth0 page will start on the sign up tab
 * instead of the sign in tab (users can still decide to switch between tabs).
 **/
export const signUpHintQueryKey = 'sign_up';
/**
 * This param is used for the inviteLinkLandingPage but can be used elsewhere as well
 * WeblogoutCallback will remove it from the state when called with logoutOfAuth=true
 * which is triggered by the for the SignOutAction
 * this allows react components to hold a state until the logout flow completes
 */
export const removeAfterSignOutQueryKey = 'remove_after_sign_out';

export const descriptReturnTo = 'descript_return_to';
