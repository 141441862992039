// Copyright 2024 Descript, Inc

import { Routes } from '../App/Constants';
import * as NUserSettings from '../App/UserSettings';
import { getRelayedState, isAuthorizationRedirect } from '../Api/Auth0Client';
import { AppSettings } from '../App/AppSettings';

// Import this so we know the global types are available
import '../Router/WindowRouterHistory';

export type AccountLinkingApiContext = {
    nextConnection: string;
    nextConnectionDisplayName: string;
    email: string;
};

export type AccountLinkingContext = AccountLinkingApiContext & {
    createdAt: number;
    secondaryAccessToken: string | undefined;
    lastPath: string | undefined;
    lastHref: string | undefined;
};

export const auth0AccountLinkingLabel = 'Link Accounts';
export const auth0AccountLinkingMessage = 'Sign in to link accounts';

export function clearOldAccountLinkingContext() {
    const context = NUserSettings.Application.accountLinkingContext.get();
    // Clear the context if it's been around for more than 30 minutes
    if (context && context.createdAt && Date.now() - context.createdAt > 1000 * 60 * 30) {
        NUserSettings.Application.accountLinkingContext.clear();
    }
}

export function resumeAccountLinking() {
    clearOldAccountLinkingContext();
    const context = NUserSettings.Application.accountLinkingContext.get();
    if (
        context !== undefined &&
        window.routerHistoryLocationDeprecated()?.pathname !== Routes.accountLinking
    ) {
        window.routerHistoryPushDeprecated(Routes.accountLinking);
    }
}

export function startAccountLinking(params: AccountLinkingApiContext) {
    const location = window.routerHistoryLocationDeprecated();
    if (!location || location.pathname === Routes.accountLinking) {
        return;
    }

    clearOldAccountLinkingContext();
    const context = NUserSettings.Application.accountLinkingContext.get();
    if (context === undefined) {
        let lastPath: string | undefined = location.pathname + location.search;
        let lastHref = undefined;
        if (isAuthorizationRedirect(window.location.href)) {
            const state = getRelayedState(window.location.href, AppSettings.authInstance);
            if (state !== undefined) {
                const { path, href } = JSON.parse(state);
                if (path) {
                    lastPath = path;
                } else if (href) {
                    // Takes priority over routes like '/'
                    lastPath = undefined;
                    lastHref = href;
                }
            }
        }
        NUserSettings.Application.accountLinkingContext.set({
            ...params,
            // Wait to set first access token until right before logging out
            secondaryAccessToken: undefined,
            createdAt: Date.now(),
            lastPath,
            lastHref,
        });
    }

    window.routerHistoryPushDeprecated(Routes.accountLinking);
}
