// Copyright © 2024 Descript, Inc. All rights reserved.

import { AbortError } from './AbortError';

/**
 * A helper function to add a listener to an AbortController/AbortSignal, and return a function to remove the listener
 *
 * It will throw AbortSignal if you call it on an aborted signal.
 *
 * @param abortControllerOrSignal
 * @param listener
 */
export function addAbortListener(
    abortControllerOrSignal: AbortController | AbortSignal | undefined,
    listener: () => void,
): (() => void) | undefined {
    const signal =
        abortControllerOrSignal instanceof AbortController
            ? abortControllerOrSignal.signal
            : abortControllerOrSignal;
    if (signal) {
        if (signal.aborted) {
            throw new AbortError('signal is already aborted');
        }
        signal.addEventListener('abort', listener, { once: true });
        return () => {
            signal.removeEventListener('abort', listener);
        };
    }
    return undefined;
}
