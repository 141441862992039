// Copyright 2020 Descript, Inc

import { createScopedLogger } from '../createScopedLogger';
import { TypedArray, TypedArrayConstructor } from './TypedArray';
import { DescriptError, ErrorCategory } from '@descript/errors';

const logger = createScopedLogger({ name: 'TYPEDARRAY_POOL' });

export interface ITypedArrayPool<T extends TypedArray> {
    get(length: number): T;
    release(array: T): void;
}

let allocBytes = 0;
export class TypedArrayPool<T extends TypedArray> implements ITypedArrayPool<T> {
    private idlePool = new Set<T>();
    private activeCount = 0;
    constructor(private readonly ArrayType: TypedArrayConstructor<T>) {}

    get = (length: number): T => {
        // treat `pool` like a stack because `pop`ping is cheap
        for (const item of this.idlePool) {
            if (item.length === length) {
                this.idlePool.delete(item);
                return item;
            }
        }
        if (process.env.NODE_ENV === 'development') {
            const bytes = length * this.ArrayType.BYTES_PER_ELEMENT;
            allocBytes += bytes;
            logger.debug(`[MEMORY] allocating new array (${allocBytes} total): ${bytes}`);
        }
        this.activeCount++;
        return new this.ArrayType(length);
    };

    garbageCollect() {
        this.activeCount -= this.idlePool.size;
        this.idlePool.clear();
    }

    get size() {
        return this.activeCount;
    }

    destroy() {
        this.garbageCollect();

        if (this.activeCount > 0) {
            logger.warn(`[MEMORY] destroying with ${this.activeCount} arrays still in use`);
            if (process.env.NODE_ENV === 'test') {
                throw new DescriptError(
                    `TypedArrayPool.destroy() with ${this.activeCount} arrays still in use (possible memory leak?)`,
                    ErrorCategory.AppArchitecture,
                );
            }
        }
    }

    release = (array: T): void => {
        if (this.idlePool.has(array)) {
            throw new DescriptError('double release detected', ErrorCategory.AppArchitecture);
        }
        this.idlePool.add(array);
    };
}
