// Copyright 2018 Descript, Inc

import * as ApiClient from './ApiClient';
import {
    CreateInviteJson,
    DriveInvite,
    Invite,
    inviteFromJson,
    InviteJson,
    InviteType,
    ProjectInvite,
} from './Invite';
import { DriveMembershipType } from './Drive';
import { OwnershipRole } from './Project';
import { AsyncContext } from '@descript/analytics';

export async function fetchInvite(
    ctx: AsyncContext,
    { inviteId }: { inviteId: string },
): Promise<Invite> {
    const json = await ApiClient.request(
        ctx,
        ApiClient.RequestType.GET,
        `/invites/${inviteId}`,
    );
    return inviteFromJson(json as InviteJson);
}

export async function fetchInviteInfo(
    ctx: AsyncContext,
    { inviteId }: { inviteId: string },
): Promise<Invite> {
    // unauthenticated endpoint to fetch nonsensitive information about an invite
    const json = await ApiClient.request(
        ctx,
        ApiClient.RequestType.GET,
        `/invites/${inviteId}/info`,
    );
    return inviteFromJson(json as InviteJson);
}

async function fetchInvites(
    ctx: AsyncContext,
    objectType: InviteType,
    objectId: string,
): Promise<Invite[]> {
    const query = { objectId, objectType };
    const json = (await ApiClient.request(
        ctx,
        ApiClient.RequestType.GET,
        '/invites/lookup',
        query,
    )) as InviteJson[];
    return json.map(inviteFromJson);
}

export async function fetchDriveInvites(
    ctx: AsyncContext,
    driveId: string,
): Promise<DriveInvite[]> {
    return (await fetchInvites(ctx, 'drive', driveId)) as DriveInvite[];
}

export async function fetchProjectInvites(
    ctx: AsyncContext,
    projectId: string,
): Promise<ProjectInvite[]> {
    return (await fetchInvites(ctx, 'project', projectId)) as ProjectInvite[];
}

async function createInvite(
    ctx: AsyncContext,
    createInviteJson: CreateInviteJson,
): Promise<Invite> {
    // sets as undefined in case comment is an empty string
    if (!createInviteJson.comment) {
        createInviteJson.comment = undefined;
    }

    const json = await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        '/invites',
        undefined,
        createInviteJson,
    );
    return inviteFromJson(json as InviteJson);
}

export async function createDriveInvite(
    ctx: AsyncContext,
    driveId: string,
    email: string,
    type: DriveMembershipType,
): Promise<DriveInvite> {
    return (await createInvite(ctx, {
        invite_object_type: 'drive',
        invite_object_id: driveId,
        invitee_email: email,
        properties: {
            membership_type: type,
        },
    })) as DriveInvite;
}

export async function createProjectInvite(
    ctx: AsyncContext,
    projectId: string,
    email: string,
    role: OwnershipRole,
): Promise<ProjectInvite> {
    return (await createInvite(ctx, {
        invite_object_type: 'project',
        invite_object_id: projectId,
        invitee_email: email,
        properties: {
            role,
        },
    })) as ProjectInvite;
}

export async function acceptInvite(
    ctx: AsyncContext,
    {
        inviteId,
        nonce,
    }: {
        inviteId: string;
        nonce: string;
    },
): Promise<Invite> {
    const query = { nonce };
    const json = await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/invites/${inviteId}/accept`,
        query,
    );
    return inviteFromJson(json as InviteJson);
}

export async function deleteInvite(ctx: AsyncContext, inviteId: string): Promise<void> {
    await ApiClient.request(ctx, ApiClient.RequestType.DELETE, `/invites/${inviteId}`);
}

export enum InviteErrorType {
    InviteAlreadyRedeemed = 'invite_already_redeemed',
    InviteNonceMismatch = 'invite_nonce_mismatch',
}
