// Copyright 2024 Descript, Inc

import { Dimensions } from '@descript/descript-model';

export const defaultUserSetting: Dimensions = {
    height: 720,
    width: 1280,
};

// 16:9 dimensions for common resolutions
export const res5k: Dimensions = {
    width: 5120,
    height: 2880,
};
export const res4K: Dimensions = {
    width: 3840,
    height: 2160,
};
export const res1440p: Dimensions = {
    width: 2560,
    height: 1440,
};
export const res1080p: Dimensions = {
    width: 1920,
    height: 1080,
};
export const res720p: Dimensions = {
    width: 1280,
    height: 720,
};
export const res480p: Dimensions = {
    width: 854,
    height: 480,
};

export const autoCameraResolution: Dimensions = {
    width: 0,
    height: 0,
};
