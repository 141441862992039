// Copyright 2024 Descript, Inc

import { ErrorCategory } from '@descript/errors';

/**
 * Used to throw when reacting to an AbortSignal
 */
export class AbortError extends Error {
    category = ErrorCategory.VideoMediaEngine;

    constructor(message: string = 'operation was aborted') {
        super(message);
    }
}
