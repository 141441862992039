// Copyright 2024 Descript, Inc

import { Span, SpanOptions, SpanStatusCode } from '@opentelemetry/api';
import { startSpan } from './tracers';
import { AssetTracingMetadata } from './datatypes';
import { SemanticAttributes } from '@opentelemetry/semantic-conventions';
import { errorCategorySymbol, SpanStatus, SpanTag } from './tags';
import { AsyncContext, errorCategoryContext } from './asyncContext';
import { ERROR_CATEGORY_TEAMS, ErrorCategory, getTeamData } from '@descript/errors';
import { config } from '../eventTracker';

const defaultCtx = errorCategoryContext(ErrorCategory.AppArchitecture);

/**
 * Mark a span as "ok".
 *
 * Per the OpenTelemetry spec, once this is set,
 * the span's status cannot be changed to "error"
 */
export function recordSpanOk(span: Span): void {
    // eslint-disable-next-line no-restricted-syntax
    span.setStatus({ code: SpanStatusCode.OK });
}

export function getErrorMessage(err: unknown): string | undefined {
    const errWithMessage = err as { message?: string };
    if (errWithMessage.message !== undefined && typeof errWithMessage.message === 'string') {
        return errWithMessage.message;
    }
    return String(err);
}

export type ErrorSpanStatus = Exclude<SpanStatus, SpanStatus.ok | SpanStatus.cancelled>;

/**
 * Records error attributes on span. Should be called if a span encounters an error.
 * @param error - error to record. Makes best effort attempt extract string error message from this param.
 * Populates `exception_type`, `exception_message`, `exception_stacktrace` on Honeycomb.
 * @param status - error status (of type SpanStatus) for this error. Defaults to `unknownError`.
 * Populates `status_message` on Honeycomb.
 * @param escaping - indicates whether the error is escaping the span, or if it's handled
 */
export function recordSpanError(
    span: Span,
    error: unknown,
    status: ErrorSpanStatus = SpanStatus.unknownError,
    escaping?: boolean,
): void {
    const excData: AssetTracingMetadata = {};
    if (escaping !== undefined) {
        excData[SemanticAttributes.EXCEPTION_ESCAPED] = escaping;
    }
    if (error instanceof Error) {
        excData[SemanticAttributes.EXCEPTION_TYPE] = error.name;
        excData[SemanticAttributes.EXCEPTION_STACKTRACE] = error.stack;
    } else if (error && typeof error === 'object') {
        excData[SemanticAttributes.EXCEPTION_TYPE] = error.constructor.name;
    } else {
        excData[SemanticAttributes.EXCEPTION_TYPE] = typeof error;
    }
    const message = error === undefined ? undefined : getErrorMessage(error);
    excData[SemanticAttributes.EXCEPTION_MESSAGE] = message;
    span.setAttributes(excData);
    // eslint-disable-next-line no-restricted-syntax
    span.setStatus({ code: SpanStatusCode.ERROR, message: status });
}

/**
 * Mark a span as canceled.
 * Does not imply that the span represents an error; use `recordSpanError` for that.
 */
export function recordSpanCanceled(span: Span): void {
    span.setAttribute(SpanTag.canceled, true);
}

export type WithSpanOptions = SpanOptions & {
    /** Created span will be a child of the span in the context */
    ctx: AsyncContext;
    /** Span helpers will not record an error if the abort controller was aborted. */
    userCancellationAbortController?: AbortController;
    /** Span status to record if an error is encountered. */
    errorStatus?: ErrorSpanStatus;
};
type SyncSpanFn<T> = (ctx: AsyncContext) => T;
type AsyncSpanFn<T> = (ctx: AsyncContext) => Promise<T>;

const EMPTY_SPAN_OPTIONS = Object.freeze({
    userCancellationAbortController: undefined,
    spanOptions: undefined,
});

export function processWithSpanOptions(opts: WithSpanOptions | undefined): Readonly<{
    spanOptions: SpanOptions | undefined;
    ctx: AsyncContext;
    userCancellationAbortController: AbortController | undefined;
    errorStatus?: ErrorSpanStatus;
}> {
    if (opts) {
        const { ctx, userCancellationAbortController, errorStatus, ...spanOptions } = opts;
        return { ctx, userCancellationAbortController, errorStatus, spanOptions };
    }
    return { ...EMPTY_SPAN_OPTIONS, ctx: defaultCtx() };
}

export function processWithSpanArgs<Fn>(
    opts: WithSpanOptions,
    fn: Fn,
): {
    spanOptions: SpanOptions | undefined;
    callback: Fn;
    ctx: AsyncContext;
    userCancellationAbortController: AbortController | undefined;
    errorStatus?: ErrorSpanStatus;
} {
    return { ...processWithSpanOptions(opts), callback: fn };
}

function handleSpanFailure(
    ctx: AsyncContext,
    userCancellationAbortController: AbortController | undefined,
    err: unknown,
    errorStatus?: ErrorSpanStatus,
) {
    if (userCancellationAbortController?.signal.aborted) {
        recordSpanCanceled(ctx.span);
    } else {
        recordSpanError(ctx.span, err, errorStatus);
    }

    const category = ctx.getValue(errorCategorySymbol);

    if (category) {
        config.sentry?.configureScope((scope) => {
            scope.setTag('error-category', category as ErrorCategory);
            scope.setTag(
                'eng-team',
                getTeamData(ERROR_CATEGORY_TEAMS[category as ErrorCategory]).name,
            );
        });
    }
}

/**
 * Calls a synchronous function with a span, ending the span afterwards.
 */
export function withSpanSync<T>(spanName: string, opts: WithSpanOptions, fn: SyncSpanFn<T>): T {
    const { callback, spanOptions, ctx, userCancellationAbortController, errorStatus } =
        processWithSpanArgs(opts, fn);
    const newCtx = startSpan(spanName, spanOptions, ctx);

    try {
        return callback(newCtx);
    } catch (err) {
        handleSpanFailure(newCtx, userCancellationAbortController, err, errorStatus);
        throw err;
    } finally {
        newCtx.span.end();
    }
}

/**
 * Calls an async function with a span, ending the span afterwards.
 */
export async function withSpanAsync<T>(
    spanName: string,
    opts: WithSpanOptions,
    fn: AsyncSpanFn<T>,
): Promise<T> {
    const { callback, spanOptions, ctx, userCancellationAbortController, errorStatus } =
        processWithSpanArgs(opts, fn);
    const newCtx = startSpan(spanName, spanOptions, ctx);

    try {
        return await callback(newCtx);
    } catch (err) {
        handleSpanFailure(newCtx, userCancellationAbortController, err, errorStatus);
        throw err;
    } finally {
        newCtx.span.end();
    }
}
