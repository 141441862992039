// Copyright 2018 Descript, Inc

import { WebContents } from 'electron';
import { Revision } from '../Api/Revision';
import { MediaReference } from '@descript/descript-model';
import { TemplatePreviewReadUrls } from '../Api/ProjectTemplate';
import { ErrorCategory, DescriptError, Errors } from '@descript/errors';
import { getTemplateRename } from '../Hooks/useCustomLayoutManagement';

export interface IErrorWithCode extends Error {
    code: number | undefined;
}

export interface IPubnubError extends Error {
    status?: {
        category?: string;
    };
}

export interface ITemplatePreviewsError extends Error {
    previews?: TemplatePreviewReadUrls;
}

// Error factories

export function downloadJobCancelledError(): Error {
    const error = new DescriptError(
        'Project download cancelled',
        ErrorCategory.AppArchitecture,
    );
    error.name = DownloadJobCancelled;
    return error;
}

export function missingRemoteAssetError(assetName: string, url: string): Error {
    const error = new DescriptError(
        `Remote asset does not exist: ${assetName} (${url})`,
        ErrorCategory.GlobalAssetSync,
    );
    error.name = MissingRemoteAsset;
    return error;
}

export function missingLocalAssetError(mediaRef: MediaReference): Error {
    const error = new DescriptError(
        `The file "${mediaRef.displayName}" could not be found [${mediaRef.assetKey}]`,
        ErrorCategory.GlobalAssetSync,
    );
    error.name = MissingLocalAsset;
    return error;
}

export function stillDownloadingError(ref?: MediaReference): Error {
    const error = new DescriptError(
        ref
            ? `One or more required files are still downloading: ${ref.id}`
            : `One or more required files are still downloading`,
        ErrorCategory.GlobalAssetSync,
    );
    error.name = StillDownloadingAsset;
    return error;
}

export function webContentsDestroyedError(webContents: WebContents): Error {
    const error = new DescriptError('WebContents is destroyed', ErrorCategory.GlobalAssetSync);
    error.name = WebContentsDestroyed;
    return error;
}

export function needsOriginalAssetsError(): Error {
    const error = new DescriptError(
        'This action needs original assets',
        ErrorCategory.GlobalAssetSync,
    );
    error.name = NeedsOriginalAssets;
    return error;
}

export function templatePreviewGenerationInProgress(
    previews: TemplatePreviewReadUrls,
): ITemplatePreviewsError {
    const error: ITemplatePreviewsError = new DescriptError(
        getTemplateRename('Template still has previews generating'),
        ErrorCategory.Templates,
    );
    error.name = TemplatePreviewGenerationInProgress;
    error.previews = previews;
    return error;
}

export function templateNotPublished(): Error {
    const error = new DescriptError(
        getTemplateRename('Template is not published'),
        ErrorCategory.Templates,
    );
    error.name = TemplateNotPublished;
    return error;
}
export function projectNotTemplate(): Error {
    const error = new DescriptError(
        getTemplateRename('Project is not a template'),
        ErrorCategory.Templates,
    );
    error.name = ProjectNotTemplate;
    return error;
}

export function templateUnavailableOnMobile(): Error {
    const error = new DescriptError(
        getTemplateRename('Template unavailable on mobile'),
        ErrorCategory.Templates,
    );
    error.name = TemplateUnavailableOnMobile;
    return error;
}

export function projectOpenInOtherWindowError(): Error {
    const error = new DescriptError('Project open in other window', ErrorCategory.Templates);
    error.name = ProjectOpenInOtherWindow;
    return error;
}

export function revisionProjectHasLiveCollabMetadata(): Error {
    const error = new DescriptError(
        "Project is not marked live collab but it's docSyncMetadata is type live-collab",
        ErrorCategory.Revisions,
    );
    error.name = RevisionProjectHasLiveCollabMetadata;
    return error;
}

// Error names
export const DownloadJobCancelled = 'DownloadJobCancelled';
export const MissingRemoteAsset = 'MissingRemoteAsset';
export const MissingLocalAsset = 'MissingLocalAsset';
export const StillDownloadingAsset = 'StillDownloadingAsset';
export const WebContentsDestroyed = 'WebContentsDestroyed';
export const NoFileOrDirectory = 'ENOENT';
export const WhiteGloveMaximumDuration = 'WhiteGloveMaximumDuration';
export const NeedsOriginalAssets = 'NeedsOriginalAssets';
export const TauHasNoMediaErrorName = 'TauHasNoMedia';
export const GoogleAuthNoPorts = 'GoogleAuthNoPorts';
export const GoogleAuthTimedOut = 'GoogleAuthTimedOut';
export const GoogleAuthCancelled = 'GoogleAuthCancelled';
export const TemplatePreviewGenerationInProgress = 'TemplatePreviewGenerationInProgress';
export const TemplateNotPublished = 'TemplateNotPublished';
export const ProjectNotTemplate = 'ProjectNotTemplate';
export const TemplateUnavailableOnMobile = 'TemplateUnavailableOnMobile';
export const ProjectOpenInOtherWindow = 'ProjectOpenInOtherWindow';
export const RevisionProjectHasLiveCollabMetadata = 'RevisionProjectHasLiveCollabMetadata';

// Error messages
const emailTakenErrorMessage = 'Could not create user, email already in use';
const invalidClientErrorMessage = 'Client version not allowed';
const accountLinkingErrorMessage = 'Account linking required';

export function stopRecorderErrorTracking(error: unknown): boolean {
    if (error instanceof RecordingInitializationError) {
        if (error.trackError === undefined) {
            return false;
        }

        return !error?.trackError;
    }

    return false;
}

export function isPubnubError(error: Error): error is IPubnubError {
    try {
        // Can't check for `PubNubError` class or name because the name gets minified
        const category = (error as IPubnubError).status?.category;
        return typeof category === 'string' && category.startsWith('PN');
    } catch {
        return false;
    }
}

export function isPubnubNetworkError(error: Error): boolean {
    if (!isPubnubError(error)) {
        return false;
    }
    const category = error.status?.category;

    // Categories taken from https://www.pubnub.com/docs/web-javascript/pubnub-network-lifecycle
    return (
        category === 'PNTimeoutCategory' ||
        category === 'PNNetworkIssuesCategory' ||
        category === 'PNTLSConnectionFailedCategory' ||
        category === 'PNNetworkDownCategory'
    );
}

export function isIdbVersionMismatchError(error: unknown): boolean {
    return error instanceof DOMException && error.name === 'VersionError';
}

export function isEmailTakenError(error: Error): boolean {
    return (
        Errors.isUnauthorizedError(error) &&
        Errors.isRequestError(error) &&
        (error.json.message === emailTakenErrorMessage ||
            error.json.attributes?.error === emailTakenErrorMessage)
    );
}

export function isInvalidClientError(error: Error): boolean {
    return (
        Errors.isForbiddenError(error) &&
        Errors.isRequestError(error) &&
        error.json?.message === invalidClientErrorMessage
    );
}

export function isAccountLinkingError(error: Error): boolean {
    return (
        Errors.isForbiddenError(error) &&
        Errors.isRequestError(error) &&
        error.json?.message === accountLinkingErrorMessage
    );
}

export function isTemplatePreviewsError(e: Error): e is ITemplatePreviewsError {
    return e.name === TemplatePreviewGenerationInProgress;
}
export function isTemplateNotPublishedError(e: Error): boolean {
    return e.name === TemplateNotPublished;
}
export function isProjectNotTemplateError(e: Error): boolean {
    return e.name === ProjectNotTemplate;
}
export function isTemplateUnavailableOnMobileError(e: Error): boolean {
    return e.name === TemplateUnavailableOnMobile;
}
export function isProjectOpenInOtherWindowError(e: Error): boolean {
    return e.name === ProjectOpenInOtherWindow;
}

export function isRevisionProjectHasLiveCollabMetadataError(e: Error): boolean {
    return e.name === RevisionProjectHasLiveCollabMetadata;
}

export class DriveSuspendedError extends Error {
    category = ErrorCategory.Account;
    override name = 'DriveSuspendedError';
    constructor(readonly driveId: string) {
        super(`Drive ${driveId} is suspended`);
    }
}

export class ProjectLoadUnauthorizedError extends Error {
    category = ErrorCategory.ProjectLoad;
    override name = 'ProjectLoadUnauthorizedError';
}

// Create Revision Errors

export const CreateRevision = Object.freeze({
    // Names
    Conflict: 'CreateRevision.Conflict',
    DocumentSaveCorrupted: 'CreateRevision.DocumentSaveCorrupted',
    TrimergeFail: 'CreateRevision.TrimergeFail',
    ProcessSaveJob: 'CreateRevision.ProcessSaveJob',
});

export class RevisionConflictError extends Error {
    category = ErrorCategory.Revisions;
    constructor(
        public readonly projectId: string,
        public readonly conflictingRevision: Revision,
    ) {
        super(`Revision conflicts with newer remote revision for project ${projectId}`);
        this.name = CreateRevision.Conflict;
    }
}

export class DuplicateToStoryboardNotSupportedError extends Error {
    category = ErrorCategory.ProjectLoad;
    override name = 'DuplicateToStoryboardNotSupportedError';
}

export class DuplicateToStoryboardTimedOutError extends Error {
    category = ErrorCategory.ProjectLoad;
    override name = 'DuplicateToStoryboardTimedOut';
    constructor(
        message: string,
        public readonly type?: 'quick-edit' | 'revision-sync',
    ) {
        super(message);
    }
}

export class DuplicateToStoryboardLegacyAssetConversionError extends Error {
    category = ErrorCategory.ProjectLoad;
    override name = 'DuplicateToStoryboardFailedToConvertLegacyAssets';
    constructor(
        message: string,
        public readonly revisionId?: string,
    ) {
        super(message);
    }
}

export class DuplicateToStoryboardPermissionError extends Error {
    category = ErrorCategory.ProjectLoad;
    override name = 'DuplicateToStoryboardPermissionError';
}

export class DuplicateToStoryboardMigratedDocInvalidError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'DuplicateToStoryboardMigratedDocInvalidError';
}

export class LiveCollabSaveDocumentError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'LiveCollabSaveDocumentError';
    constructor(
        message: string,
        public readonly actionType?: string,
    ) {
        super(actionType ? `[${actionType}] ${message}` : message);
    }
}

export class RequiresUpdateError extends Error {
    category = ErrorCategory.AppArchitecture;
    override name = 'RequiresUpdateError';
}

export const isRevisionConflictError = (err: Error): err is RevisionConflictError =>
    err instanceof RevisionConflictError || err.name === CreateRevision.Conflict;

export const silenceError =
    (silenceErrorCondition: (err?: unknown) => boolean) =>
    async <T>(promise: Promise<T>): Promise<T | undefined> => {
        try {
            return await promise;
        } catch (err) {
            if (silenceErrorCondition(err)) {
                return undefined;
            }
            throw err;
        }
    };

export const silenceErrorSync =
    (silenceErrorCondition: (err?: unknown) => boolean) =>
    <T>(callback: () => T): T | undefined => {
        try {
            return callback();
        } catch (err) {
            if (silenceErrorCondition(err)) {
                return undefined;
            }
            throw err;
        }
    };

export function saveJobError(underlyingError: Error): Errors.IErrorWithUnderlyingError {
    return Errors.errorWithUnderlyingError(underlyingError, CreateRevision.ProcessSaveJob);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMissingFileOrDirError = (err?: any) =>
    err &&
    (err.code === NoFileOrDirectory ||
        err.message.indexOf('A requested file or directory could not be found') !== -1 ||
        err.message === 'NotFoundError: The object can not be found here.');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMissingOrInvalidJsonError = (err?: any) => {
    if (isMissingFileOrDirError(err) || err instanceof SyntaxError) {
        return true;
    }
    return false;
};

export const allowMissingFileOrDir = silenceError(isMissingFileOrDirError);

export const allowMissingOrInvalidJsonError = silenceError(isMissingOrInvalidJsonError);
export const allowMissingOrInvalidJsonErrorSync = silenceErrorSync(isMissingOrInvalidJsonError);

export class RecordingPermissionDeniedError extends Error {
    category = ErrorCategory.Recording;
    constructor(message: string = 'recording permission denied') {
        super(message);
    }
}

export class RecordingInitializationError extends Error {
    category = ErrorCategory.Recording;
    trackError?: boolean;
}

export class RecordingFatalError extends Error {
    category = ErrorCategory.Recording;
}

export interface SystemError extends Error {
    address?: string;
    code: string;
    dest?: string;
    errno: number;
    info?: Record<string, unknown>;
    message: string;
    path?: string;
    port?: number;
    syscall: string;
}

let isSystemErrorImpl: (error: unknown) => error is SystemError = (x): x is SystemError =>
    false;
export function setIsSystemError(_isSystemError: typeof isSystemError) {
    isSystemErrorImpl = _isSystemError;
}
export function isSystemError(error: unknown): error is SystemError {
    return isSystemErrorImpl(error);
}

export function errorFromHTMLMediaElementError(meError: MediaError | null): Error {
    return meError
        ? new DescriptError(
              `MediaError Code: ${meError.code} | Message: ${meError.message}`,
              ErrorCategory.VideoMediaEngine,
          )
        : new DescriptError('MediaElement error', ErrorCategory.VideoMediaEngine);
}
