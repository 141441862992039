// These are marketing params that have gotten stored inside a cookie
export const marketingParams = [
    'fbclid',
    'msclkid',
    'ttclid',
    'lmref',
    'gclid',
    'tta_attr_id',
    'muid',
    'utm_campaignname',
    'utm_ad_id',
    'utm_medium',
    'utm_source',
    'utm_content',
    'utm_term',
    'utm_campaign',
    'gbraid',
    'wbraid',
    'li_fat_id',
];

// Additional marketing cookies
export const marketingCookies = [
    '_gac',
    '_gcl',
    '_gid',
    '_ga',
    '_fbp',
    '_fbc',
    '_ttp',
    '_pangle',
    '_uetmsclkid',
    '_ttp',
    '_pangle',
];
