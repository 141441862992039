// Copyright 2019 Descript, Inc

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ellipsis(value: any, maxLen: number = 100): any {
    if (value === undefined) {
        return '';
    }
    if (process.env.PROCESS !== 'main') {
        return value;
    }
    if (value instanceof Buffer) {
        return `<Buffer ${value.length}>`;
    }
    const text = JSON.stringify(value);
    if (!text) {
        return '';
    }
    return text.slice(0, maxLen) + (text.length > maxLen ? `... (${text.length} chars)` : '');
}

let next = 0;
const emojis = [
    '🐶',
    '🐱',
    '🐭',
    '🐹',
    '🐰',
    '🦊',
    '🐻',
    '💝',
    '🐼',
    '🐨',
    '🐯',
    '🦁',
    '🐮',
    '💜',
    '🐷',
    '🐸',
    '🐵',
    '🐔',
    '🦋',
    '🐞',
    '🧡',
    '🦄',
    '🐴',
    '🐝',
    '🐥',
    '🐳',
    '💙',
    '🐙',
    '🐠',
    '🐊',
    '🐡',
    '🦀',
    '💛',
    '🐢',
    '👠',
];

export function getNextEmoji(): string {
    const emoji = emojis[next]!;
    next = (next + 1) % emojis.length;
    return emoji;
}
