// Copyright 2022 Descript, Inc

import { ComparisonResult } from '@descript/descript-core';
import { CompositionId, IEquatable } from '@descript/descript-model';

import * as Sorting from '../Utilities/Sorting';
import { trackError } from '../Utilities/ErrorTracker';
import {
    RootFolder,
    generateRecordingsFolderId,
    ParentFolderId,
} from './FolderClientConstants';
import { generateWorkspaceId, WorkspaceType } from '../Utilities/Workspaces';
import { Revision, RevisionJson } from './Revision';
import {
    ProjectTemplateInfo,
    projectTemplateInfoFromJSON,
    ProjectTemplateInfoJSON,
    projectTemplateInfoToJSON,
    templateContentsIsEqual,
} from './ProjectTemplate';
import {
    ProjectPermissionsJson,
    ProjectPermissions,
    ProjectPermissionLevel,
} from './ProjectPermissions';
import {
    DocumentMetadata,
    documentMetadataFromJSON,
    DocumentMetadataJson,
    documentMetadataToJSON,
    recentMetadataIsEqual,
} from './DocumentMetadata';
import { getInitialFeatureFlag } from '../FeatureFlags/settings';
import { ErrorCategory, DescriptError } from '@descript/errors';
import { SetNonNullable } from 'type-fest';
import { getTemplateRename } from '../Hooks/useCustomLayoutManagement';

type SyncState = {
    currentCompositionId?: CompositionId;
    currentPlaybackTime?: number;
    isPlaying?: boolean;
    lastUpdated?: Date;
};

type UserProjectPermissions = {
    hasEditorPermissions: boolean;
    hasOwnerPermissions: boolean;
};

export enum Role {
    Public = 'public',
    Admin = 'admin',
    Descript = 'descript',
    Decentral = 'decentral',
}

export enum UserGroup {
    Transcription = 'transcription',
    Audio = 'audio',
    Video = 'video',
    Unspecified = 'unspecified',
}

export enum AppUseCase {
    Podcasting = 'podcasting',
    Transcription = 'transcription',
    VideoEditing = 'video_editing',
    ScreenRecording = 'screen_recording',
    AISpeech = 'overdub',
    Audiogram = 'audiogram',
}

export enum OrgPurposeTracking {
    Marketing = 'marketing',
    LearningDevelopment = 'learning_development',
    Education = 'education',
    ProductDesign = 'product_design',
    CustomerResearch = 'customer_research',
    CustomerSupport = 'customer_support',
    Sales = 'sales',
    Other = 'other',
}

export type OrgPurposeType = keyof typeof OrgPurposeTracking;

export enum OwnershipRole {
    Owner = 'owner',
    Collaborator = 'collaborator',
    Tester = 'tester',
}

export type UserJson = {
    id: string;
    created_at?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    roles?: Role[];
    profile_image_url?: string;
    profile_image_cdn_url?: string;
    free_transcription_minutes?: number;
    available_prepaid_minutes?: number;
    referral_code?: string;
    referral_target_id?: string;
    user_group?: UserGroup;
    app_use_cases?: string;
    gclid?: string;
    reverse_trial_started_at?: string;
};

export type AnalyticsSummary = {
    id?: string;
    created_at?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    name?: string;
    admin?: boolean;
    free_transcription_minutes?: number;
    referral_code?: string;
    user_group?: UserGroup;
    gclid?: string;
    use_case_audiograms?: boolean;
    use_case_transcription?: boolean;
    use_case_video_editing?: boolean;
    use_case_screen_recording?: boolean;
    use_case_podcasting?: boolean;
    use_case_overdub?: boolean;
    use_case_storyboard_onboarding?: boolean;
};

export type OwnershipJson = {
    id?: string;
    sku?: string;
    user_id?: string;
    role?: OwnershipRole;
};

/**
 * Project membership and role for a particular user
 *
 * This is called "Ownership" for historical reasons, but a user
 * who has an Ownership may not be the project owner! Instead, the
 * permissions are determined by the role.
 */
export class Ownership implements IEquatable<Ownership> {
    constructor(
        public readonly id: string = '',
        public readonly projectId: string = '',
        public readonly userId: string = '',
        public readonly role: OwnershipRole = OwnershipRole.Tester,
    ) {}

    equals(other: Ownership): boolean {
        return (
            this.id === other.id &&
            this.projectId === other.projectId &&
            this.userId === other.userId &&
            this.role === other.role
        );
    }

    static fromJson(json: OwnershipJson): Ownership {
        const ownership = new Ownership(json.id, json.sku, json.user_id, json.role);
        return ownership;
    }

    static toJSON(ownership: Ownership): OwnershipJson {
        return {
            id: ownership.id,
            sku: ownership.projectId,
            user_id: ownership.userId,
            role: ownership.role,
        };
    }

    static sortOwnerships(a: Ownership, b: Ownership) {
        if (a.role === b.role) {
            return 0;
        } else if (a.role === OwnershipRole.Owner) {
            return -1;
        } else if (b.role === OwnershipRole.Owner) {
            return 1;
        } else if (a.role === OwnershipRole.Collaborator) {
            return -1;
        }
        return 1;
    }

    static ownershipsEqual(a: Ownership[], b: Ownership[]): boolean {
        const aLength = a.length;
        if (aLength !== b.length) {
            return false;
        }

        const aSorted = a.sort(Sorting.sortByID);
        const bSorted = b.sort(Sorting.sortByID);

        for (let index = 0; index < aLength; index++) {
            if (!aSorted[index]!.equals(bSorted[index]!)) {
                return false;
            }
        }

        return true;
    }
}

export class User implements IEquatable<User> {
    static ColorMapping: { [userId: string]: string } = {};
    static BaseColors = [
        'var(--orange-500-a)',
        'var(--amber-500-a)',
        'var(--green-500-a)',
        'var(--cyan-500-a)',
        'var(--blue-500-a)',
        'var(--purple-500-a)',
        'var(--pink-500-a)',
        'var(--red-500-a)',
    ];
    static BaseSolidColors = [
        'var(--orange-200)',
        'var(--amber-200)',
        'var(--green-200)',
        'var(--cyan-200)',
        'var(--blue-200)',
        'var(--purple-200)',
        'var(--pink-200)',
        'var(--red-200)',
    ];
    static BaseColorsOld = [
        '#5ECC5C',
        '#00D1B2',
        '#3AB2FF',
        '#5978FF',
        '#9166FF',
        '#D82292',
        '#FF365B',
        '#FF8519',
        '#FFB400',
    ];
    static DefaultSolidColor = 'var(--blue-200)';
    static DefaultColor = 'var(--blue-500-a-base)';
    static DefaultColorOld = '#222430';

    static ColorForUserId(userId: string, isRedesign: boolean, isGrouped?: boolean): string {
        const color = User.ColorMapping[userId];
        if (color && !isGrouped) {
            return color;
        }

        let hashCode = 0; // based on Java hashCode() implementation
        for (let i = 0; i < userId.length; i++) {
            hashCode += Math.pow(userId.charCodeAt(i) * 31, userId.length - i);
            hashCode = hashCode & hashCode; //eslint-disable-line no-bitwise
        }

        if (isRedesign) {
            const colors = isGrouped ? User.BaseSolidColors : User.BaseColors;

            const index = Math.abs(hashCode) % colors.length;
            const newColor =
                colors[index] || (isGrouped && User.DefaultSolidColor) || User.DefaultColor;
            if (!isGrouped) {
                User.ColorMapping[userId] = newColor;
            }
            return newColor;
        } else {
            const index = Math.abs(hashCode) % User.BaseColorsOld.length;
            let newColor = User.BaseColorsOld[index];
            if (!newColor) {
                newColor = User.DefaultColorOld;
            }
            User.ColorMapping[userId] = User.BaseColorsOld[index]!;
            return newColor;
        }
    }

    constructor(
        public readonly id: string = 'no-id',
        public readonly email: string = 'anonymous@descript.com',
        public readonly firstName: string = '',
        public readonly lastName: string = '',
        public readonly roles: Role[] = [Role.Public],
        public readonly profileImageUrl: string | undefined = undefined,
        public readonly profileImageCdnUrl: string | undefined = undefined,
        public readonly freeTranscriptionMinutes: number = 0,
        public readonly availablePrepaidMinutes: number = 0,
        public readonly referralCode: string = '',
        public readonly referralTargetId: string = '',
        // UserSummary on Project.owner is missing some fields
        public readonly createdAt: Date | undefined = undefined,
        public readonly userGroup: UserGroup | undefined = undefined,
        public readonly appUseCases: string | undefined = undefined,
        public readonly gclid: string | undefined = undefined,
        public readonly reverseTrialStartedAt: Date | undefined = undefined,
        public syncState: SyncState = {},
        // ownerships only available on project users, set on FetchProjectOwnershipsAction.done
        // https://www.notion.so/descript/Project-Ownership-Data-Pattern-for-External-Projects-f241c81721904570be9968e7fbd9a316?pvs=4
        public ownerships: Ownership[] | undefined = undefined,
    ) {}

    static shallowCopy(user: User): User {
        return new User(
            user.id,
            user.email,
            user.firstName,
            user.lastName,
            user.roles,
            user.profileImageUrl,
            user.profileImageCdnUrl,
            user.freeTranscriptionMinutes,
            user.availablePrepaidMinutes,
            user.referralCode,
            user.referralTargetId,
            user.createdAt,
            user.userGroup,
            user.appUseCases,
            user.gclid,
            user.reverseTrialStartedAt,
            user.syncState,
            user.ownerships,
        );
    }

    static fromJson(json: UserJson): User {
        const createdAt = json.created_at ? new Date(json.created_at) : undefined;
        const reverseTrialStartedAt = json.reverse_trial_started_at
            ? new Date(json.reverse_trial_started_at)
            : undefined;

        return new User(
            json.id,
            json.email,
            json.first_name,
            json.last_name,
            json.roles,
            json.profile_image_url,
            json.profile_image_cdn_url,
            json.free_transcription_minutes,
            json.available_prepaid_minutes,
            json.referral_code,
            json.referral_target_id,
            createdAt,
            json.user_group,
            json.app_use_cases,
            json.gclid,
            reverseTrialStartedAt,
        );
    }

    static toJSON(user: User): UserJson {
        return {
            id: user.id,
            created_at: user.createdAt?.toISOString(),
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
            roles: user.roles,
            profile_image_url: user.profileImageUrl,
            profile_image_cdn_url: user.profileImageCdnUrl,
            free_transcription_minutes: user.freeTranscriptionMinutes,
            available_prepaid_minutes: user.availablePrepaidMinutes,
            referral_code: user.referralCode,
            referral_target_id: user.referralTargetId,
            user_group: user.userGroup,
            app_use_cases: user.appUseCases,
            gclid: user.gclid,
            reverse_trial_started_at: user.reverseTrialStartedAt?.toISOString(),
        };
    }

    equalsUser(other: User): boolean {
        return this.id === other.id;
    }

    equals(other: User): boolean {
        return this.equalsUser(other);
    }

    hasRole(role: Role): boolean {
        return this.roles.indexOf(role) >= 0;
    }

    currentUserPermissionsForProject(project: Project): UserProjectPermissions {
        if (project.owner?.id === this.id) {
            return {
                hasEditorPermissions: true,
                hasOwnerPermissions: true,
            };
        }

        const hasOwnerPermissions = project.currentUserOwnershipRole === OwnershipRole.Owner;
        const hasEditorPermissions =
            hasOwnerPermissions ||
            project.currentUserOwnershipRole === OwnershipRole.Collaborator;

        return {
            hasEditorPermissions,
            hasOwnerPermissions,
        };
    }

    get name(): string {
        return `${this.firstName || ''} ${this.lastName || ''}`.trim();
    }

    get initials(): string {
        return (this.firstName || '').charAt(0) + (this.lastName || '').charAt(0);
    }

    get referralLink(): string {
        return `https://descript.com/r/${this.referralCode}`;
    }

    color(isCurrentUser: boolean, isRedesign: boolean, isGrouped?: boolean): string {
        if (!isCurrentUser) {
            return User.ColorForUserId(this.id, isRedesign, isGrouped);
        }
        return User.DefaultColor;
    }

    userSummary(): User {
        return new User(this.id, this.email, this.firstName, this.lastName);
    }

    jsonUserSummary(): UserJson {
        return {
            id: this.id,
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            roles: this.roles,
            created_at: this.createdAt?.toISOString(),
        };
    }

    pubnubUserSummary(): UserJson {
        return {
            id: this.id,
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            ...(this.profileImageCdnUrl !== undefined
                ? { profile_image_cdn_url: this.profileImageCdnUrl }
                : {}),
        };
    }

    analyticsSummary(): AnalyticsSummary {
        const useCasesSummary = {
            use_case_audiograms: false,
            use_case_transcription: false,
            use_case_video_editing: false,
            use_case_screen_recording: false,
            use_case_podcasting: false,
            use_case_overdub: false,
            use_case_storyboard_onboarding: false,
        };
        if (this.appUseCases) {
            const parsedUseCases = JSON.parse(this.appUseCases);
            parsedUseCases.forEach((useCase: string) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (useCasesSummary as any)['use_case_' + useCase] = true;
            });
        }
        return {
            id: this.id,
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            name: this.name,
            admin: this.hasRole(Role.Admin),
            created_at: this.createdAt?.toISOString(),
            free_transcription_minutes: this.freeTranscriptionMinutes,
            referral_code: this.referralCode,
            user_group: this.userGroup,
            gclid: this.gclid,
            ...useCasesSummary,
        };
    }
}

// eslint-disable-next-line no-null/no-null
export const nullProjectEditorVariant = null;
export const projectEditorVariants = ['quick_recording', nullProjectEditorVariant] as const;
export type ProjectEditorVariant = (typeof projectEditorVariants)[number];

/**
 * True if the editor variant is a Quick Recording *and* this app does not support the new, non-QEM quick recording flow
 * TODO: https://linear.app/descript/issue/REC-3548/remove-qem-code-paths
 * Remove this once we remove the QEM
 */
export function shouldRenderInQEM(editorVariant: ProjectEditorVariant | undefined): boolean {
    return (
        editorVariant === 'quick_recording' &&
        getInitialFeatureFlag('quick-recorder-version') === 1
    );
}

export type ProjectJson = {
    id?: string;
    drive_id?: string;
    name?: string;
    owner?: UserJson;
    created_at?: string;
    revisions?: RevisionJson[];
    permissions?: ProjectPermissionsJson;
    parent_id?: ParentFolderId;
    last_viewed_at?: string;
    last_updated_at?: string;
    source_project_id?: string;
    in_recordings_folder?: boolean;
    editor_variant?: ProjectEditorVariant;
    is_live_collab_enabled?: boolean;
    is_storyboard_enabled?: boolean;
    template?: Partial<ProjectTemplateInfoJSON>;
    recent_metadata?: DocumentMetadataJson;
    thumbnail_url?: string;
    has_thumbnail?: boolean;
    requester_user_ownership_role?: OwnershipRole | null | undefined;
    /** below for GET /projects/shared_to/:userId endpoint only */
    drive_name?: string;
    drive_logo_cdn_url?: string;
    merge_strategy?: MergeStrategy;
};

type ProjectDescription = Readonly<{
    id: string;
    driveId: string;
    workspaceId: string;
    name: string;
    createdAt: Date;
    owner: User | undefined;
    isNew: boolean;
    permissions: ProjectPermissions;
    /** Source project ID (if this project was duplicated from another). */
    sourceProjectId?: string;
}>;

export const DEFAULT_PROJECT_NAME = 'Untitled Project';
export const DEFAULT_TEMPLATE_NAME = getTemplateRename('Untitled Template', true);

export enum MergeStrategy {
    MultiTimeline = 'multi-timeline',
    Branch = 'branch',
}

export class Project implements IEquatable<Project>, ProjectDescription {
    public id: string;
    public driveId: string;
    public workspaceId: string;
    public name: string;
    public readonly createdAt: Date;
    public readonly owner: User | undefined;
    public isNew: boolean;
    public permissions: ProjectPermissions;
    public parentFolderId: ParentFolderId;
    public lastViewedAt: Date | undefined;
    public lastUpdatedAt: Date | undefined;
    public sourceProjectId: string | undefined;
    public editorVariant: ProjectEditorVariant | undefined;
    public isLiveCollabEnabled: boolean | undefined;
    public isStoryboardEnabled: boolean | undefined;
    public template: ProjectTemplateInfo | Record<string, never> | undefined;
    public recentMetadata: DocumentMetadata | undefined;
    public thumbnailUrl: string | undefined;
    // null if role does not exist; undefined if role is not fetched
    public currentUserOwnershipRole: OwnershipRole | null | undefined;
    public driveName: string | undefined;
    public driveLogoCdnUrl: string | undefined;
    public readonly mergeStrategy: MergeStrategy | undefined;

    constructor({
        id = '',
        driveId = '',
        workspaceId = '',
        name = DEFAULT_PROJECT_NAME,
        createdAt = new Date(),
        owner,
        isNew = true,
        permissions = {
            publicAccess: ProjectPermissionLevel.None,
            driveAccess: ProjectPermissionLevel.None,
        },
        parentFolderId = RootFolder,
        lastViewedAt,
        lastUpdatedAt,
        sourceProjectId,
        editorVariant,
        isLiveCollabEnabled,
        isStoryboardEnabled,
        template,
        recentMetadata,
        thumbnailUrl,
        currentUserOwnershipRole,
        driveName,
        driveLogoCdnUrl,
        mergeStrategy,
    }: Partial<Project>) {
        this.id = id;
        this.driveId = driveId;
        this.workspaceId = workspaceId;
        this.name = name;
        this.createdAt = createdAt;
        this.owner = owner;
        this.isNew = isNew;
        this.permissions = permissions;
        this.parentFolderId = parentFolderId;
        this.lastViewedAt = lastViewedAt;
        this.lastUpdatedAt = lastUpdatedAt;
        this.sourceProjectId = sourceProjectId;
        this.editorVariant = editorVariant;
        this.isLiveCollabEnabled = isLiveCollabEnabled;
        this.isStoryboardEnabled = isStoryboardEnabled;
        this.template = template;
        this.recentMetadata = recentMetadata;
        this.thumbnailUrl = thumbnailUrl;
        this.currentUserOwnershipRole = currentUserOwnershipRole;
        this.driveName = driveName;
        this.driveLogoCdnUrl = driveLogoCdnUrl;
        this.mergeStrategy = mergeStrategy;
    }

    get isTemplate(): boolean {
        return this.template !== undefined;
    }

    get isPublishedTemplate(): boolean {
        return this.template !== undefined && Object.keys(this.template).length > 0;
    }

    equals(other: Project): boolean {
        return (
            this.owner?.id === other.owner?.id &&
            this.id === other.id &&
            this.driveId === other.driveId &&
            this.workspaceId === other.workspaceId &&
            this.name === other.name &&
            this.createdAt.getTime() === other.createdAt.getTime() &&
            this.isNew === other.isNew &&
            this.permissions.publicAccess === other.permissions.publicAccess &&
            this.permissions.driveAccess === other.permissions.driveAccess &&
            this.parentFolderId === other.parentFolderId &&
            this.lastViewedAt?.getTime() === other.lastViewedAt?.getTime() &&
            this.lastUpdatedAt?.getTime() === other.lastUpdatedAt?.getTime() &&
            this.sourceProjectId === other.sourceProjectId &&
            this.editorVariant === other.editorVariant &&
            this.isLiveCollabEnabled === other.isLiveCollabEnabled &&
            this.isStoryboardEnabled === other.isStoryboardEnabled &&
            this.template?.versionId === other.template?.versionId &&
            this.template?.isPublic === other.template?.isPublic &&
            this.template?.galleryOrder === other.template?.galleryOrder &&
            templateContentsIsEqual(this.template?.contents, other.template?.contents) &&
            recentMetadataIsEqual(this.recentMetadata, other.recentMetadata) &&
            this.thumbnailUrl === other.thumbnailUrl &&
            this.mergeStrategy === other.mergeStrategy
        );
    }

    shallowCopy(): Project {
        return new Project({
            id: this.id,
            driveId: this.driveId,
            workspaceId: this.workspaceId,
            name: this.name,
            createdAt: this.createdAt,
            owner: this.owner,
            isNew: this.isNew,
            permissions: {
                publicAccess: this.permissions.publicAccess,
                driveAccess: this.permissions.driveAccess,
            },
            parentFolderId: this.parentFolderId,
            lastViewedAt: this.lastViewedAt,
            lastUpdatedAt: this.lastUpdatedAt,
            sourceProjectId: this.sourceProjectId,
            editorVariant: this.editorVariant,
            isLiveCollabEnabled: this.isLiveCollabEnabled,
            isStoryboardEnabled: this.isStoryboardEnabled,
            template: this.template,
            recentMetadata: this.recentMetadata,
            thumbnailUrl: this.thumbnailUrl,
            currentUserOwnershipRole: this.currentUserOwnershipRole,
            driveName: this.driveName,
            driveLogoCdnUrl: this.driveLogoCdnUrl,
            mergeStrategy: this.mergeStrategy,
        });
    }

    static fromJson(json: ProjectJson): Project {
        const createdAt = json.created_at ? new Date(json.created_at) : new Date();
        const lastViewedAt = json.last_viewed_at ? new Date(json.last_viewed_at) : undefined;
        const lastUpdatedAt = json.last_updated_at ? new Date(json.last_updated_at) : undefined;
        let owner: User | undefined = undefined;
        if (json.owner) {
            owner = User.fromJson(json.owner);
        }
        const permissions = json.permissions && {
            publicAccess: json.permissions.public_access || ProjectPermissionLevel.None,
            driveAccess: json.permissions.member_access || ProjectPermissionLevel.None,
        };
        // TODO [workspaces]: Remove when we add backend support for workspaces. (https://app.asana.com/0/1199653420240807/list)
        const parentId =
            json.in_recordings_folder && json.drive_id
                ? generateRecordingsFolderId(json.drive_id)
                : json.parent_id;

        const project = new Project({
            id: json.id,
            driveId: json.drive_id,
            // TODO [workspaces]: Replace when we add backend support for workspaces. Read and write to json. (https://app.asana.com/0/1199653420240807/list)
            workspaceId:
                json.drive_id && permissions
                    ? generateWorkspaceId(
                          json.drive_id,
                          permissions.driveAccess === ProjectPermissionLevel.None
                              ? WorkspaceType.PERSONAL
                              : WorkspaceType.DRIVE,
                      )
                    : undefined,
            name: json.name,
            createdAt,
            owner,
            isNew: false,
            permissions,
            parentFolderId: parentId,
            lastViewedAt,
            lastUpdatedAt,
            sourceProjectId: json.source_project_id,
            editorVariant: json.editor_variant,
            isLiveCollabEnabled: json.is_live_collab_enabled,
            isStoryboardEnabled: json.is_storyboard_enabled,
            template: json.template ? projectTemplateInfoFromJSON(json.template) : undefined,
            recentMetadata: documentMetadataFromJSON(json.recent_metadata),
            thumbnailUrl: json.thumbnail_url,
            currentUserOwnershipRole: json.requester_user_ownership_role,
            driveName: json.drive_name,
            driveLogoCdnUrl: json.drive_logo_cdn_url,
            mergeStrategy: json.merge_strategy,
        });
        return project;
    }

    static toJSON(project: Project): ProjectJson {
        let owner: UserJson | undefined = undefined;
        if (project.owner) {
            owner = User.toJSON(project.owner);
        }
        const permissions = {
            public_access: project.permissions.publicAccess,
            member_access: project.permissions.driveAccess,
        };

        return {
            id: project.id,
            drive_id: project.driveId,
            name: project.name,
            owner: owner,
            created_at: project.createdAt.toISOString(),
            permissions,
            parent_id: project.parentFolderId,
            last_viewed_at: project.lastViewedAt?.toISOString(),
            last_updated_at: project.lastUpdatedAt?.toISOString(),
            source_project_id: project.sourceProjectId,
            editor_variant: project.editorVariant,
            is_live_collab_enabled: project.isLiveCollabEnabled,
            is_storyboard_enabled: project.isStoryboardEnabled,
            template: project.template
                ? projectTemplateInfoToJSON(project.template)
                : undefined,
            recent_metadata: documentMetadataToJSON(project.recentMetadata),
            thumbnail_url: project.thumbnailUrl,
            requester_user_ownership_role: project.currentUserOwnershipRole,
            drive_name: project.driveName,
            merge_strategy: project.mergeStrategy,
        };
    }

    static sortByOwner(a: Project, b: Project) {
        if (a.owner && b.owner) {
            return Sorting.layerSortFns<User>(Sorting.sortByName, Sorting.sortByID)(
                a.owner,
                b.owner,
            );
        } else if (a.owner) {
            return ComparisonResult.OrderedAscending;
        } else if (b.owner) {
            return ComparisonResult.OrderedDescending;
        }
        return ComparisonResult.OrderedSame;
    }

    static latestRevision(revisions: Revision[]): Revision | undefined {
        let latestRevision: Revision | undefined;
        for (const revision of revisions) {
            if (
                !latestRevision ||
                Project.sortByIsNewRevisionNumber(revision, latestRevision) === 1
            ) {
                latestRevision = revision;
            }
        }
        return latestRevision;
    }

    static getCloudBaseRevisionId(
        revisions: Revision[],
        revision: Revision,
    ): string | undefined {
        const map = new Map<string, Revision>();
        for (const rev of revisions) {
            map.set(rev.revisionId, rev);
        }
        let result: Revision = revision;
        while (result.isNew) {
            if (result.baseRevisionId === undefined) {
                if (result.revisionNumber !== 1) {
                    trackError(
                        new DescriptError(
                            `Local revision ${result.revisionId} has no base revision`,
                            ErrorCategory.ProjectLoad,
                        ),
                        'get-cloud-base-revision',
                        {
                            category: ErrorCategory.Revisions,
                            extra: { projectId: result.projectId },
                        },
                    );
                }
                return undefined;
            }
            const baseRevision = map.get(result.baseRevisionId);
            if (baseRevision === undefined) {
                // If the revision is not in our list, it's an old cloud revision or a bug
                // (in which case we'll get an error later when we try to load it)
                return result.baseRevisionId;
            }
            result = baseRevision;
        }
        return result.revisionId;
    }

    static latestCloudRevision(revisions: Revision[]): Revision | undefined {
        let latestRevision: Revision | undefined;
        for (const revision of revisions) {
            if (
                !revision.isNew &&
                (!latestRevision ||
                    revision.revisionNumber > latestRevision.revisionNumber ||
                    (revision.revisionNumber === latestRevision.revisionNumber &&
                        revision.createdAt > latestRevision.createdAt))
            ) {
                latestRevision = revision;
            }
        }
        return latestRevision;
    }
    static sortByIsNewRevisionNumber(rev1: Revision, rev2: Revision): 1 | 0 | -1 {
        if (rev1.isNew !== rev2.isNew) {
            // Sort new ones first
            return rev2.isNew ? -1 : 1;
        }
        // Sort larger revision numbers first
        if (rev2.revisionNumber > rev1.revisionNumber) {
            return -1;
        }
        if (rev1.revisionNumber > rev2.revisionNumber) {
            return 1;
        }
        if (rev2.createdAt > rev1.createdAt) {
            return -1;
        }
        if (rev1.createdAt > rev2.createdAt) {
            return 1;
        }
        return 0;
    }

    static isDefaultName(name: string | undefined): boolean {
        return name === DEFAULT_PROJECT_NAME || name === DEFAULT_TEMPLATE_NAME;
    }

    /**
     * A method for determining whether or not a given
     * project uses the 'branch proposal' merging strategy.
     *
     * @param project
     * @returns boolean
     */
    static usesBranchMergeStrategy(project: Project): boolean {
        return project.mergeStrategy === MergeStrategy.Branch;
    }
}

export type TemplateProject = SetNonNullable<Project, 'template'>;
