// Copyright 2023 Descript, Inc

import { AssetTracingMetadata, SpanStatus, SpanTag } from '@descript/analytics';
import { TypeChecking } from '@descript/descript-model';
import { RemoteArtifact } from './Types';
import * as ClientErrors from '../Utilities/Errors';
import { JobError } from '../Utilities/JobError';
import { ErrorCategory, Errors } from '@descript/errors';

export class AssetSyncError extends Error {
    category = ErrorCategory.GlobalAssetSync;

    public static isAssetSyncError(err: unknown): err is AssetSyncError {
        return err instanceof AssetSyncError;
    }

    constructor(
        public readonly status: SpanStatus,
        public override readonly message: string,
        public readonly tags: AssetTracingMetadata = {},
    ) {
        super(`${status}: ${message}`);
    }
}

export class ArtifactConflictError extends AssetSyncError {
    constructor(
        public override readonly status:
            | SpanStatus.alreadyExists
            | SpanStatus.failedPrecondition,
        public override readonly message: string,
        public readonly artifact: RemoteArtifact,
        public readonly conflict: /** Checksum for current upload contradicts checksum given previously */
        | {
                  type: 'md5';
                  uploadedMd5: string;
              }
            /** File size for current upload contradicts file size given previously */
            | {
                  type: 'size';
                  uploadedSize: number;
              }
            /** Artifact status does not allow this operation */
            | {
                  type: 'status';
              }
            /** Some resource, such as an upload session or signed URL, has expired */
            | {
                  type: 'expired';
              }
            /** No uploaded file was found when trying to commit */
            | {
                  type: 'no_file';
              },
    ) {
        super(status, message, {
            [SpanTag.appAssetId]: artifact.assetGuid,
            [SpanTag.appArtifactId]: artifact.guid,
            ['app.conflict_type']: conflict.type,
        });
    }
}

/**
 * Whether or not an error should be retried, or if it is fatal
 */
export function isTemporaryError(e: AssetSyncError): boolean {
    switch (e.status) {
        case SpanStatus.alreadyExists:
        case SpanStatus.dataLoss:
        case SpanStatus.failedPrecondition:
        case SpanStatus.invalidArgument:
        case SpanStatus.notFound:
        case SpanStatus.ok:
        case SpanStatus.outOfRange:
        case SpanStatus.permissionDenied:
        case SpanStatus.unimplemented:
            return false;
        case SpanStatus.internal: // Server error
        case SpanStatus.aborted: // Race conditions
        case SpanStatus.cancelled:
        case SpanStatus.deadlineExceeded:
        case SpanStatus.resourceExhausted:
        case SpanStatus.unauthenticated:
        case SpanStatus.unavailable:
        case SpanStatus.unknownError:
            return true;
        default:
            TypeChecking.staticAssertNever(e.status);
    }
}

const SYS_ERRS_TO_REPORT = new Set([
    'UNKNOWN',
    'EADDRNOTAVAIL',
    'EAFNOSUPPORT',
    'EBADF',
    'EDESTADDRREQ',
    'EFAULT',
    'EINVAL',
    'EMSGSIZE',
    'ENOTDIR',
    'EISDIR',
    'ENOTSOCK',
    'ENOTSUP',
    'ENOSYS',
    'EPROTO',
    'EPROTONOSUPPORT',
    'EPROTOTYPE',
    'ECHARSET',
    'EAIFAMNOSUPPORT',
    'EAISERVICE',
    'EAISOCKTYPE',
    'ESRCH',
    'ENAMETOOLONG',
    'ENOTEMPTY',
]);

export function shouldReportError(e: unknown): boolean {
    if (!Errors.isError(e)) {
        return true;
    } else if (Errors.isNetworkError(e)) {
        return false;
    } else if (e instanceof JobError) {
        return false;
    } else if (ClientErrors.isSystemError(e)) {
        if (isFatalSystemError(e)) {
            return false;
        } else {
            return SYS_ERRS_TO_REPORT.has(e.code);
        }
    } else if (Errors.isRequestError(e)) {
        // 400 indicates we probably have a bug, and it wouldn't be
        // obvious server-side
        return e.statusCode === 400;
    } else if (AssetSyncError.isAssetSyncError(e)) {
        switch (e.status) {
            case SpanStatus.alreadyExists:
            case SpanStatus.notFound:
            case SpanStatus.ok:
            case SpanStatus.outOfRange:
            case SpanStatus.failedPrecondition:
            case SpanStatus.permissionDenied:
            case SpanStatus.aborted: // Race conditions
            case SpanStatus.cancelled:
            case SpanStatus.deadlineExceeded:
            case SpanStatus.resourceExhausted:
            case SpanStatus.unauthenticated:
            case SpanStatus.unavailable:
                return false;
            case SpanStatus.unknownError:
            case SpanStatus.internal:
            case SpanStatus.dataLoss:
            case SpanStatus.invalidArgument:
            case SpanStatus.unimplemented:
                return true;
            default:
                TypeChecking.staticAssertNever(e.status);
        }
    } else {
        return true;
    }
}

const FATAL_ERROR_CODES = new Set([
    'EACCES',
    'ENOENT',
    'EISDIR',
    'ENOSYS',
    'EINVAL',
    'ECHARSET',
    'EEXIST',
    'ENAMETOOLONG',
    'EPERM',
    'ELOOP',
    'EXDEV',
    'ENOTEMPTY',
    'EROFS',
]);

export function isFatalSystemError(error: unknown): boolean {
    return ClientErrors.isSystemError(error) && FATAL_ERROR_CODES.has(error.code);
}

export function mapRequestError(error: Errors.RequestError): AssetSyncError {
    const message = `Request HTTP error ${error.statusCode}: ${error.message}`;
    let status: SpanStatus;
    if (error.statusCode >= 500) {
        status = SpanStatus.unavailable;
    } else if (error.statusCode >= 400) {
        switch (error.statusCode) {
            case 404:
                status = SpanStatus.notFound;
                break;
            /**
             * This is a backstop for error handling. In general, upstream
             * logic should handle this response in a business-logic-specific
             * way rather than relying on generic error handling.
             */
            case 409:
            case 412:
                status = SpanStatus.failedPrecondition;
                break;
            case 403:
                status = SpanStatus.permissionDenied;
                break;
            case 401:
                status = SpanStatus.unauthenticated;
                break;
            case 400:
                status = SpanStatus.invalidArgument;
                break;
            case 422:
                status = SpanStatus.aborted;
                break;
            default:
                status = SpanStatus.unknownError;
        }
    } else {
        status = SpanStatus.unknownError;
    }
    return new AssetSyncError(status, message);
}

export function errToString(err: unknown): string {
    if (err === undefined || err === '') {
        return 'null';
    }
    if (err instanceof Error || typeof err !== 'object') {
        return String(err);
    }
    // eslint-disable-next-line no-null/no-null
    if (err === null) {
        return 'null';
    }
    if (Symbol.toStringTag in err) {
        return String(err);
    }
    if (err.constructor?.name) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return `${err.constructor.name}: ${err}`;
    }
    return String(err);
}
