// Copyright © 2023 Descript, Inc. All rights reserved.

type OnTrackFn = (event: string, properties?: Record<string, unknown>) => void;

const trackListeners = new Set<OnTrackFn>();

export function addTrackListener(listener: OnTrackFn): void {
    trackListeners.add(listener);
}

export function removeTrackListener(listener: OnTrackFn): void {
    trackListeners.delete(listener);
}

export function getTrackListeners(): Set<OnTrackFn> {
    return trackListeners;
}
