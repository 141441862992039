// Copyright 2024 Descript, Inc

type IgnoreFilter = string | RegExp;

function matchesFilter(filters: IgnoreFilter[], error: { message: string }): boolean {
    for (const ignoredError of filters) {
        if (typeof ignoredError === 'string' && error.message.includes(ignoredError)) {
            return true;
        }

        if (ignoredError instanceof RegExp && ignoredError.test(error.message)) {
            return true;
        }
    }

    return false;
}

const IGNORED_BUT_TRACKED_ERRORS: IgnoreFilter[] = [
    // SQLite errors are on Desktop 1 and we don't plan to fix them
    'SQLITE_IOERR:',
    'SQLITE_ERROR:',
    'SQLITE_FULL:',
    'SQLITE_CORRUPT:',
    'SQLITE_CANTOPEN:',
    'SQLITE_NOTADB:',
    // Can't do much about no space left on device errors
    'ENOSPC:',
];

/** This error should be not be sent to Sentry, but should be tracked in our event tracker. */
export function isErrorIgnoredButTracked(error: Error): boolean {
    return matchesFilter(IGNORED_BUT_TRACKED_ERRORS, error);
}

const IGNORED_ERRORS: IgnoreFilter[] = [
    'AbortError: The user aborted a request',
    // On Firefox, we get this error when the user does anything that
    // might interrupt a network request, like closing the tab or navigating
    // away from the page. We don't want to track these errors.
    'NS_BINDING_ABORTED:',
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
    'ResizeObserver loop limit exceeded',
    // We don't care about bad network connections
    'ERR_ADDRESS_UNREACHABLE',
    'ERR_CONNECTION_RESET',
    'ERR_CERT_COMMON_NAME_INVALID',
    'ERR_CONNECTION_CLOSED',
    'ERR_SOCKET_NOT_CONNECTED',
    'ERR_CERT_DATABASE_CHANGED',
    'ERR_CONNECTION_REFUSED',
    'ERR_NETWORK_ACCESS_DENIED',
    'ECONNRESET',
    'operation timed out',
    'ERR_INTERNET_DISCONNECTED',
    'ERR_NAME_NOT_RESOLVED',
    'ERR_NETWORK_CHANGED',
    'ERR_NETWORK_IO_SUSPENDED',
    'ERR_TIMED_OUT',
    'ERR_CONNECTION_TIMED_OUT',
    /^NetworkError: /,
    // iOS returns this error when anything goes wrong with the fetch
    // (eg. the navigate while a network request is in progress)
    'NetworkError: Load failed',
    'TypeError: Load failed',
    ...IGNORED_BUT_TRACKED_ERRORS,
];

/** Whether or not to ignore an error and not send it to Sentry. */
export function isErrorIgnored(error: { message: string }) {
    return matchesFilter(IGNORED_ERRORS, error);
}
