import { useFeatureFlag } from '../Browser/FeatureFlags/useFeatureFlag';

export function useCustomLayoutManagement() {
    return Boolean(useFeatureFlag('workflows-custom-layout-management'));
}

// if using custom layout management, rename 'template' to 'layout pack'
// should account for pluralization and capitalization
export function useTemplateRename() {
    const isCustomLayoutManagementEnabled = useCustomLayoutManagement();
    const renameFn = (name: string) => {
        if (!isCustomLayoutManagementEnabled) {
            return name;
        }

        return name
            .replace(/\btemplate\b/g, 'layout pack')
            .replace(/\btemplates\b/g, 'layout packs')
            .replace(/\bTemplate\b/g, 'Layout pack')
            .replace(/\bTemplates\b/g, 'Layout packs');
    };

    return { getTemplateCopy: renameFn };
}

export function getTemplateRename(name: string, titleCase = false) {
    return name
        .replace(/\btemplate\b/g, 'layout pack')
        .replace(/\btemplates\b/g, 'layout packs')
        .replace(/\bTemplate\b/g, `Layout ${titleCase ? 'Pack' : 'pack'}`)
        .replace(/\bTemplates\b/g, `Layout ${titleCase ? 'Pack' : 'pack'}`);
}
