// Copyright 2022 Descript, Inc

import * as ApiClientBase from './ApiClientBase';
import * as ApiTarget from './ApiTarget';
import { ApiTargetName } from './ApiTarget';
import { getNextEmoji } from '../Utilities/Logging';
import * as ClientErrors from '../Utilities/Errors';
import {
    Auth0MessageParams,
    handleReauthorize,
    isValidJWT,
    logoutChannel,
} from './Auth0Client';
import { AppSettings } from '../App/AppSettings';
import { updateApiTargetFromUserSetting } from './ApiTargetUpdater';
import {
    AsyncContext,
    recordSpanError,
    recordSpanOk,
    SpanStatus,
    SpanTag,
    trackEvent,
    withSpanAsync,
} from '@descript/analytics';
import { propagation } from '@opentelemetry/api';
import { Nstring } from '@descript/descript-model';
import { trackError } from '../Utilities/ErrorTracker';
import * as NUserSettings from '../App/UserSettings';
import { DebugSettings, PlatformHelpers, removeEmpty } from '@descript/descript-core';
import { isDesktop2 } from '../Desktop2';
import * as UserClient from './UserClient';
import { getClientAttributeHeaders } from '../Utilities/ClientAttributes';
import {
    AccountLinkingApiContext,
    auth0AccountLinkingLabel,
} from '../Utilities/AccountLinking';
import {
    ApiClient_BlobRequest,
    ApiClient_PublicApiRequest,
    ApiClient_RawResponseRequest,
    ApiClient_Reauthorize,
    ApiClient_Request,
    Fetch,
} from '../Utilities/Tracing/names';
import { ErrorCategory, DescriptError, Errors } from '@descript/errors';
import { devWeb } from '../App/Constants/HostsConstants';
import { TargetProductionApi } from '../App/MenuCommands/DeveloperCommands';
import getAuth0AppConfig from './Auth0Config';

export { JSON_TYPE_HEADERS, RequestType } from './ApiClientBase';

type ExposedFetchOptions = Pick<RequestInit, 'keepalive' | 'signal'>;

const jsonParser = async function <T>(response: Response): Promise<T> {
    // Allow for empty responses from the API
    const text = await response.text();
    return text.length > 0 ? JSON.parse(text) : {};
};

const blobParser = function (response: Response): Promise<Blob> {
    return response.blob();
};

const rawResponseParser = function (response: Response): Promise<Response> {
    return Promise.resolve(response);
};

let isAppSuspended = false;
/**
 * When an electron app is suspended, typically when the OS sleeps, CPU access
 * is throttled. In this state we get small sips of CPU every 5 - 20 minutes.
 * Reauthorization in particular is sensitive to failed requests (COR-8282) as
 * we can only use a refreshToken once with 2 minutes of leeway, so if the app
 * suspends right after a reauthorization request starts, Auth0 thinks we got
 * the response when we didn't, causing a future reauthorization request to 403
 * with a token reuse error which causes an unexpected logout.
 */
export function suspendApp() {
    ApiClientBase.apiLog.debug('Network access suspended');
    isAppSuspended = true;
}
/**
 * See ApiClient.suspendApp
 */
export function resumeApp() {
    ApiClientBase.apiLog.debug('Network access resumed');
    isAppSuspended = false;
}

// History of reauthorize promises keyed by refresh token
const reauthorizePromises: Record<string, Promise<void>> = {};
const reauthorizedRefreshTokens: Set<string> = new Set<string>();

export type LogoutCallbackPayload = {
    ctx: AsyncContext;
    reloadApiTarget: boolean;
    logoutOfAuth0: boolean;
    userInitiated: boolean;
    auth0MessageParams?: Auth0MessageParams;
};
type LogoutCallback = (payload: LogoutCallbackPayload) => Promise<void>;
export let logoutCallback: LogoutCallback;

export function installLogoutCallback(cb: LogoutCallback) {
    logoutCallback = cb;
}

let invalidClientErrorCallback: () => Promise<void>;
export function installInvalidClientErrorCallback(cb: () => Promise<void>) {
    invalidClientErrorCallback = cb;
}

let accountLinkingErrorCallback: (context: AccountLinkingApiContext) => Promise<void>;
export function installAccountLinkingErrorCallback(cb: typeof accountLinkingErrorCallback) {
    accountLinkingErrorCallback = cb;
}

const PROXIED_APIS: Record<string, ApiTargetName> = {
    // defined here: https://github.com/descriptinc/server-infrastructure/blob/master/k8s/production/resources/ingress.yaml#L182
    'https://web.descript.com': 'production',
    'https://share.descript.com': 'production',
    'https://qa-web.descript.com': 'production',
    'https://qa-share.descript.com': 'production',
    // defined here: https://github.com/descriptinc/server-infrastructure/blob/master/k8s/staging/resources/ingress.yaml#L159
    'https://staging-web.descript.com': 'staging',
    'https://staging-share.descript.com': 'staging',
};

const BUILD_TYPE = process.env.REACT_APP_BUILD_TYPE || 'local';

export function ProxyUrl(): string {
    // Enable staging or local app to connect to local API
    if (
        (BUILD_TYPE === 'local' || DescriptFeatures.DEV_TOOLS) &&
        ApiTarget.prefix() === 'localhost-'
    ) {
        return (
            process.env.DESCRIPT_API_HOST ||
            DebugSettings.getValue('Localhost.URL', 'http://localhost:3100/v2')
        );
    }

    const targetName = ApiTarget.targetName();

    // Staging web doesn't have a CORS proxy set up so connecting to local API is disabled
    if (BUILD_TYPE === 'local' && process.env.PRODUCT === 'web' && targetName === 'localhost') {
        return process.env.REACT_APP_DESCRIPT_API_PROXY || `${devWeb}/v2`;
    }

    if (BUILD_TYPE === 'local' && process.env.PRODUCT === 'web-share') {
        return process.env.REACT_APP_DESCRIPT_API_PROXY || 'http://localhost:3002/v2';
    }

    // Only on web…
    if (typeof location !== 'undefined') {
        // Use local API proxy to avoid CORS when available
        const proxyTarget = PROXIED_APIS[location.origin];
        if (proxyTarget && targetName === proxyTarget) {
            return `${location.origin}/v2`;
        }
    }

    return `https://${ApiTarget.prefix()}api.descript.com/v2`;
}

export const PublicAlignerUrl = () => {
    return ApiTarget.targetName() === 'production'
        ? 'https://aligner.descript.com'
        : 'https://staging-aligner.descript.com';
};

export function PublicProxyUrl(): string {
    // Enable staging or local app to connect to local API
    if (
        (BUILD_TYPE === 'local' || DescriptFeatures.DEV_TOOLS) &&
        process.env.PRODUCT === 'electron' &&
        ApiTarget.prefix() === 'localhost-'
    ) {
        return process.env.DESCRIPT_API_HOST || 'http://localhost:3100/public/v1';
    }

    // Staging web doesn't have a CORS proxy set up so connecting to local API is disabled
    if (BUILD_TYPE === 'local' && process.env.PRODUCT === 'web') {
        return process.env.REACT_APP_DESCRIPT_API_PROXY || `${devWeb}/v1`;
    }

    if (BUILD_TYPE === 'local' && process.env.PRODUCT === 'web-share') {
        return process.env.REACT_APP_DESCRIPT_API_PROXY || 'http://localhost:3002/v1';
    }

    return `https://${ApiTarget.prefix()}api.descript.com/public/v1`;
}

export function ApiVersion(): string {
    return 'v1';
}

export function isLoggedIn(): boolean {
    const refreshToken = ApiTarget.getRefreshToken();
    return refreshToken !== undefined;
}

export async function logout({
    ctx,
    reloadApiTarget = false,
    logoutOfAuth0 = false,
    userInitiated = false,
    auth0MessageParams,
}: {
    ctx: AsyncContext;
    reloadApiTarget?: boolean;
    logoutOfAuth0?: boolean;
    userInitiated?: boolean;
    auth0MessageParams?: Auth0MessageParams;
}) {
    const userId = NUserSettings.Application.currentUserId.get();
    ApiClientBase.authLog(`ApiClient.logout ${reloadApiTarget ? '(reloadApiTarget)' : ''}`);
    NUserSettings.Application.currentUserId.clear();
    NUserSettings.Application.projectFilter.clear();
    NUserSettings.Application.showVersion4Onboarding.clear();
    NUserSettings.Application.showStoryboardNewCardOnboarding.clear();
    NUserSettings.Application.showLiveTranscriptionUnavailableMessage.clear();
    NUserSettings.Application.bootCachePayload.clear();
    NUserSettings.Application.googleAuth.clear();
    NUserSettings.Application.hasDrive.clear();
    NUserSettings.Recording.driveId.clear();
    // Only remove if you're on you're way out, not if you're on your way in and
    // not yet signed in
    if (isLoggedIn() && auth0MessageParams?.submitLabel !== auth0AccountLinkingLabel) {
        NUserSettings.Application.accountLinkingContext.clear();
    }

    // On web we transit through auth0 so we don't need to do this
    if (
        isLoggedIn() &&
        logoutOfAuth0 &&
        userId &&
        (isDesktop2 || PlatformHelpers.isElectron())
    ) {
        // On the backend this deletes both the client grant and all auth0 sessions
        await UserClient.deleteAuthorizedClient(ctx, userId, getAuth0AppConfig().clientId);
    }

    if (reloadApiTarget) {
        await AppSettings.onIdle();
        updateApiTargetFromUserSetting();
    } else {
        await ApiTarget.clearTokens();
    }

    logoutChannel?.postMessage({
        reloadApiTarget,
        logoutOfAuth0,
        userInitiated,
        auth0MessageParams,
    });
    if (!logoutCallback) {
        throw new DescriptError('Logout callback not installed', ErrorCategory.AppArchitecture);
    }
    await logoutCallback({
        ctx,
        reloadApiTarget,
        logoutOfAuth0,
        userInitiated,
        auth0MessageParams,
    });
}

// Main only
async function mainReauthorize(ctx: AsyncContext): Promise<void> {
    return await withSpanAsync(ApiClient_Reauthorize, { ctx }, async (newCtx) => {
        if (isAppSuspended) {
            throw new OfflineError("App is suspended, can't reauthorize");
        }
        ApiClientBase.authLog('ApiClient.reauthorize');
        let refreshToken: string | undefined;
        try {
            const rawRefreshToken = ApiTarget.getRefreshToken();
            if (!rawRefreshToken) {
                const err = new Errors.RequestError('No refresh token', 401);
                recordSpanError(newCtx.span, err, SpanStatus.notFound);
                throw err;
            }
            refreshToken = String(rawRefreshToken);
            if (refreshToken in reauthorizePromises) {
                ApiClientBase.authLog('Reauthorize used existing promise', refreshToken);
            } else {
                ApiClientBase.authLog('Reauthorize created new promise', refreshToken);
                reauthorizePromises[refreshToken] = handleReauthorize(
                    refreshToken,
                    ApiTarget.setTokens,
                );
            }
            await reauthorizePromises[refreshToken];
            reauthorizedRefreshTokens.add(refreshToken);
            ApiClientBase.authLog('Reauthorize succeeded', refreshToken);
            recordSpanOk(newCtx.span);
        } catch (e) {
            const error = e as Error;
            recordSpanError(newCtx.span, error);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newCtx.span.setAttribute(SpanTag.httpStatusCode, (error as any).statusCode);
            ApiClientBase.authLog(`Reauthorize failed: ${error.message}`, refreshToken);
            if (refreshToken) {
                delete reauthorizePromises[refreshToken];
            }
            if (
                Errors.isRequestError(error) &&
                (error.statusCode === 401 || error.statusCode === 403)
            ) {
                if (isLoggedIn()) {
                    ApiClientBase.authLog('Logout - reauthorize failed');
                    trackEvent('logout', { source: 'api-client-reauthorize-failed' });
                    await logout({ ctx });
                }
                throw Errors.forcedLogoutError(error);
            } else {
                throw error;
            }
        }
    });
}

export let reauthorize = mainReauthorize;

export function installReauthorizeCallback(cb: () => Promise<void>): void {
    reauthorize = cb;
}

export function resetReauthorizeCallback(): void {
    reauthorize = mainReauthorize;
}

export function isReauthorizationPending(): boolean {
    return Object.keys(reauthorizePromises).some(
        (refreshToken) => !reauthorizedRefreshTokens.has(refreshToken),
    );
}

export async function finishReauthorization(): Promise<void> {
    await Promise.all(Object.values(reauthorizePromises));
}

export function authHeader(authToken: string): Record<string, string> {
    return {
        [ApiClientBase.descriptAuthHeader]: 'auth0', // Required header when using access tokens from Auth0
        Authorization: `Bearer ${authToken}`,
    };
}

export async function request<T>(
    ctx: AsyncContext,
    method: ApiClientBase.RequestType,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query?: Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: Record<string, any>,
    fetchOpts?: Partial<ExposedFetchOptions>,
    typeHeaders: Record<string, string> = ApiClientBase.JSON_TYPE_HEADERS,
    usePublicApi?: boolean,
    useAlignerAPI: boolean = false,
    delegateAuth?: string,
): Promise<T> {
    return await withSpanAsync(ApiClient_Request, { ctx }, async (newCtx) => {
        return await requestHelper<T>(
            newCtx,
            jsonParser,
            method,
            path,
            query,
            data,
            typeHeaders,
            undefined,
            fetchOpts,
            usePublicApi,
            useAlignerAPI,
            delegateAuth,
        );
    });
}

export async function blobRequest(
    ctx: AsyncContext,
    method: ApiClientBase.RequestType,
    path: string,
    query?: Record<string, unknown>,
    data?: Record<string, unknown>,
    fetchOpts?: Partial<ExposedFetchOptions>,
): Promise<Blob> {
    return await withSpanAsync(ApiClient_BlobRequest, { ctx }, async (newCtx) => {
        const blob = await requestHelper(
            newCtx,
            blobParser,
            method,
            path,
            query,
            data,
            undefined,
            undefined,
            fetchOpts,
        );
        newCtx.span.setAttribute(SpanTag.httpResponseContentLength, blob.size);
        return blob;
    });
}

export async function rawResponseRequest(
    ctx: AsyncContext,
    method: ApiClientBase.RequestType,
    path: string,
    query?: Record<string, unknown>,
    data?: Record<string, unknown>,
    fetchOpts?: Partial<ExposedFetchOptions>,
    typeHeaders: Record<string, string> = ApiClientBase.JSON_TYPE_HEADERS,
): Promise<Response> {
    return await withSpanAsync(ApiClient_RawResponseRequest, { ctx }, async (newCtx) => {
        const response = await requestHelper(
            newCtx,
            rawResponseParser,
            method,
            path,
            query,
            data,
            typeHeaders,
            undefined,
            fetchOpts,
        );
        return response;
    });
}

export async function publicApiRequest<T>(
    ctx: AsyncContext,
    method: ApiClientBase.RequestType,
    path: string,
    query?: Record<string, unknown>,
    data?: Record<string, unknown>,
    fetchOpts?: Partial<ExposedFetchOptions>,
    typeHeaders: Record<string, string> = ApiClientBase.JSON_TYPE_HEADERS,
): Promise<T> {
    return await withSpanAsync(ApiClient_PublicApiRequest, { ctx }, async (newCtx) => {
        return await requestHelper<T>(
            newCtx,
            jsonParser,
            method,
            path,
            query,
            data,
            typeHeaders,
            undefined,
            fetchOpts,
            true,
        );
    });
}

export async function authorizeAndGetHeaders(): Promise<Record<string, string> | undefined> {
    await finishReauthorization();
    const rawAuthToken = ApiTarget.getAuthToken();
    const authToken = rawAuthToken !== undefined ? String(rawAuthToken) : undefined;
    if (isLoggedIn() && (!authToken || !isValidJWT(authToken))) {
        // Pre-emptively fail with a 401 when logged in with an invalid auth token, with same message used to trigger reauthorization
        // Note: we don't distinguish between requests that require auth and those that don't, so this could trigger reauthorization on a request that technically doesn't require it
        throw new Errors.RequestError('Unauthorized', 401);
    }
    if (authToken) {
        return authHeader(authToken);
    }
    return undefined;
}

let startAlertTimer: NodeJS.Timeout | undefined;

async function requestHelper<T>(
    ctx: AsyncContext,
    parseResponse: (response: Response) => Promise<T>,
    method: ApiClientBase.RequestType,
    path: string,
    query: Record<string, unknown> | undefined = undefined,
    data: Record<string, unknown> | undefined = undefined,
    typeHeaders: Record<string, string> = {},
    retryCount: number = 3,
    fetchOpts?: Partial<ExposedFetchOptions>,
    publicApi?: boolean,
    useAlignerAPI: boolean = false,
    delegateAuth?: string,
): Promise<T> {
    if (isAppSuspended) {
        throw new OfflineError("App is suspended, can't make network requests");
    }

    try {
        const start = Date.now();
        const emoji = getNextEmoji();

        // Build request url
        let url: URL;
        if (useAlignerAPI) {
            url = new URL(PublicAlignerUrl());
        } else if (publicApi) {
            url = new URL(PublicProxyUrl());
        } else {
            url = new URL(ProxyUrl());
        }
        url.pathname += path;
        if (query) {
            Object.entries(removeEmpty(query)).forEach(([name, value]) => {
                url.searchParams.set(name, String(value));
            });
        }

        // Build headers
        //
        // NOTE: if you add new headers here, you must also add them to the list of accepted
        // headers in the cors.ts file in the server repo.
        const authHeaders = delegateAuth
            ? authHeader(delegateAuth)
            : await authorizeAndGetHeaders();
        const headers = {
            ...authHeaders,
            ...typeHeaders,
            ...getClientAttributeHeaders(),
            'Accept-version': ApiVersion(),
        };
        propagation.inject(ctx, headers);
        const body =
            typeof window !== 'undefined' && data instanceof FormData
                ? data
                : data
                  ? JSON.stringify(data)
                  : undefined;

        const requestData = new Request(url.toString(), {
            method,
            headers,
            body,
        });

        // Send request
        let response: Response;
        try {
            ApiClientBase.networkLog({ id: emoji, summary: '⏱', request: requestData });
            response = await withSpanAsync(
                Fetch,
                {
                    ctx,
                    attributes: {
                        [SpanTag.spanKind]: 'client',
                        [SpanTag.httpMethod]: method,
                        [SpanTag.httpPath]: url.pathname,
                        [SpanTag.netPeerName]: url.hostname,
                        [SpanTag.netPeerPort]: url.port,
                        [SpanTag.httpScheme]: url.protocol,
                        [SpanTag.httpRequestContentLength]: body
                            ? typeof body === 'string'
                                ? Nstring.utf8ByteLength(body)
                                : undefined
                            : 0,
                    },
                },
                async (fetchCtx) => {
                    const fetchResponse = await fetch(
                        // Must instantiate Request inside fetch so the following safari bug isn't hit
                        // https://bugs.webkit.org/show_bug.cgi?id=203617
                        new Request(url.toString(), {
                            method,
                            headers,
                            body,
                        }),
                        fetchOpts,
                    );
                    fetchCtx.span.setAttribute(SpanTag.httpStatusCode, fetchResponse.status);
                    return fetchResponse;
                },
            );

            if (startAlertTimer) {
                clearTimeout(startAlertTimer);
            }
        } catch (e) {
            const error = e as Error;
            if (
                !startAlertTimer &&
                process.env.NODE_ENV === 'development' &&
                error &&
                typeof error === 'object' &&
                error.message === 'Failed to fetch' &&
                url.host.includes('localhost')
            ) {
                // Only show the alert if the API is down for more than 30s seconds
                startAlertTimer = setTimeout(async () => {
                    startAlertTimer = undefined;

                    const result = confirm(
                        "Failed to contact the local API server. Please check that it's running. Press OK to connect to the production API.",
                    );

                    if (result) {
                        await TargetProductionApi.runOnMain?.();
                    }
                }, 30_000);
            }

            ApiClientBase.networkLog({
                id: emoji,
                summary: '❌',
                request: requestData,
                start,
                error: error.message,
            });
            throw Errors.networkError(error);
        }

        // Process response
        if (response.status >= 200 && response.status < 400) {
            ApiClientBase.networkLog({
                id: emoji,
                summary: '✅',
                request: requestData,
                start,
                response,
            });
            return await parseResponse(response);
        } else if (
            response.status === 429 ||
            response.status === 502 ||
            response.status === 503
        ) {
            ApiClientBase.networkLog({
                id: emoji,
                summary: '❌',
                request: requestData,
                start,
                error: response.statusText,
            });
            throw Errors.networkError(
                new Errors.RequestError(
                    response.statusText,
                    response.status,
                    {},
                    method,
                    path,
                    query,
                    response.headers.get('x-request-id') ?? undefined,
                ),
            );
        } else {
            ApiClientBase.networkLog({
                id: emoji,
                summary: '🚸',
                request: requestData,
                start,
                response,
            });
            let json = {};
            try {
                json = await response.json();
                // eslint-disable-next-line no-empty
            } catch {}
            throw new Errors.RequestError(
                response.statusText,
                response.status,
                json,
                method,
                path,
                query,
                response.headers.get('x-request-id') ?? undefined,
            );
        }
    } catch (e) {
        const error = e as Error;
        if (Errors.isUnauthorizedError(error) && retryCount > 0 && isLoggedIn()) {
            // Reauthorize and retry, unless it's an email taken error in which case just retry to wait out race condition of multiple requests attempting to create an account at the same time
            if (!ClientErrors.isEmailTakenError(error)) {
                await reauthorize(ctx);
            }
            return await requestHelper(
                ctx,
                parseResponse,
                method,
                path,
                query,
                data,
                typeHeaders,
                retryCount - 1,
                fetchOpts,
                publicApi,
                useAlignerAPI,
                delegateAuth,
            );
        } else if (Errors.isUnauthorizedError(error) && retryCount === 0) {
            if (ClientErrors.isEmailTakenError(error) && isLoggedIn()) {
                ApiClientBase.authLog('Logout - email taken');
                trackEvent('logout', { source: 'api-client-email-taken' });
                trackError(error, 'email-taken', {
                    category: ErrorCategory.Auth,
                });
                await logout({
                    ctx,
                    reloadApiTarget: false,
                    logoutOfAuth0: true,
                });
            }
            throw new Errors.RetryMaxReachedError(error);
        } else if (ClientErrors.isInvalidClientError(error)) {
            // TODO: COR-12815 fix root cause and remove
            if (invalidClientErrorCallback) {
                await invalidClientErrorCallback();
            }
            throw error;
        } else if (ClientErrors.isAccountLinkingError(error)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const nextConnection: string | undefined = (error as any).json?.next_connection;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const nextConnectionDisplayName: string | undefined = (error as any).json
                ?.next_connection_display_name;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const email: string | undefined = (error as any).json?.email;
            if (nextConnection && nextConnectionDisplayName && email) {
                await accountLinkingErrorCallback({
                    nextConnection,
                    nextConnectionDisplayName,
                    email,
                });
            }
            throw error;
        } else {
            throw error;
        }
    }
}

export class RequestError extends Errors.RequestError {}
export class OfflineError extends Errors.OfflineError {}
export class RetryMaxReachedError extends Errors.RetryMaxReachedError {}
