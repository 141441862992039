// Copyright 2020 Descript, Inc

import * as ApiClientBase from './ApiClientBase';
import { randomBytes, sha256 } from './Auth0HelpersElectron';
import { v4 as uuidv4 } from 'uuid';
import { IAppSettings } from '../App/AppSettings';
import getAuth0AppConfig, { getApplication, getTenant } from './Auth0Config';
import { trackError } from '../Utilities/ErrorTracker';
import { ErrorCategory, DescriptError, Errors } from '@descript/errors';
import { getNextEmoji } from '../Utilities/Logging';
import decodeJWT from 'jwt-decode';
import { isDesktop2 } from '../Desktop2';
import { AppConstants, Routes } from '../App/Constants';
import { loadUserSetting } from './ApiTarget';
import * as UserClient from './UserClient';
import { errorCategoryContext } from '@descript/analytics';

const defaultCtx = errorCategoryContext(ErrorCategory.Auth);

const oauthTokenPath = '/oauth/token';
export const codeResponseType = 'code';
const verifierIdResponseType = 'verifier_id';
export const embedTokenFetchType = 'embed_token_fetch';
const scope = [
    'offline_access', // For refresh tokens - make sure API Settings has 'Allow Offline Access' enabled in the Auth0 dashboard
    'openid',
    'profile',
];
const verifierKeyPrefix = 'Auth0.PKCEVerifier';
const nonceStateKey = 'Auth0.NonceState';

type NonceState = {
    nonce: string;
    state: string;
};

export const InitialScreenQueryKey = 'descript_initial_screen';
export enum InitialScreen {
    login = 'login',
    signUp = 'signUp',
}

export interface Auth0MessageParams {
    loginHint?: string;
    submitLabel?: string;
    welcomeMessage?: string;
    connection?: string;
}

interface Auth0EmailInviteParams {
    inviteId: string;
    inviteNonce?: string;
}

interface Auth0LinkInviteParams {
    inviteLinkNonce?: string;
    inviteLinkDriveId?: string;
}

export let logoutChannel: BroadcastChannel | undefined;
export let authorizedChannel: BroadcastChannel | undefined;
if (typeof window !== 'undefined' && 'BroadcastChannel' in window) {
    logoutChannel = new BroadcastChannel('logout_channel');
    authorizedChannel = new BroadcastChannel('authorized_channel');
}
// Uses sessionStorage to isolate the last location to each tab/window
const authLastLocationKey = 'auth:lastLocation';
export function setLastUrl(url: string) {
    if (typeof sessionStorage === 'undefined') {
        return;
    }
    sessionStorage.setItem(authLastLocationKey, url);
}
export function getLastUrl(): string | undefined {
    if (typeof sessionStorage === 'undefined') {
        return undefined;
    }
    const url = sessionStorage.getItem(authLastLocationKey);
    if (!url) {
        return undefined;
    }
    return url;
}
export function removeLastUrl() {
    if (typeof sessionStorage === 'undefined') {
        return;
    }
    sessionStorage.removeItem(authLastLocationKey);
}

function getVerifierKey(verifierId: string) {
    return `${verifierKeyPrefix}.${verifierId}`;
}

async function clearVerifierKeys(storage: IAppSettings) {
    const oldVerifierKeys = Object.keys(storage.getAll()).filter((s) =>
        s.startsWith(verifierKeyPrefix),
    );
    if (oldVerifierKeys.length >= 4) {
        oldVerifierKeys.forEach((key) => storage.delete(key));
    }
    await storage.onIdle?.();
}

function appendDeepLinkTarget(redirectUrl: URL): URL {
    if (isDesktop2 && globalThis.DescriptDesktopAPI?.windowTargetId) {
        redirectUrl.searchParams.append(
            'deepLinkTarget',
            globalThis.DescriptDesktopAPI?.windowTargetId,
        );
    }
    return redirectUrl;
}

function appendVerifierId(
    redirectUrl: URL,
    verifierId: string,
    redirectToWebAfterAuth0: boolean,
) {
    if (redirectToWebAfterAuth0) {
        // We want to append the verified Id to the url that comes back to the desktop not the url to web
        const toDesktop = redirectUrl.searchParams.get('returnTo');
        if (toDesktop) {
            let toDesktopUrl = new URL(toDesktop);
            toDesktopUrl.searchParams.append(verifierIdResponseType, verifierId);
            toDesktopUrl = appendDeepLinkTarget(toDesktopUrl);
            redirectUrl.searchParams.delete('returnTo');
            redirectUrl.searchParams.set(
                AppConstants.Auth.descriptReturnTo,
                toDesktopUrl.toString(),
            );
        }
    } else {
        redirectUrl.searchParams.append(verifierIdResponseType, verifierId);
        redirectUrl = appendDeepLinkTarget(redirectUrl);
    }
    return redirectUrl;
}

export async function generateAuthorizationURL({
    storage,
    state,
    initialScreen,
    auth0MessageParams,
    auth0EmailInviteParams,
    auth0LinkInviteParams,
    auth0CallbackParams,
    redirectToWebAfterAuth0 = false,
}: {
    storage: IAppSettings;
    state?: string;
    initialScreen?: string;
    auth0MessageParams?: Auth0MessageParams;
    auth0EmailInviteParams?: Auth0EmailInviteParams;
    auth0LinkInviteParams?: Auth0LinkInviteParams;
    auth0CallbackParams?: URLSearchParams;
    redirectToWebAfterAuth0?: boolean;
}): Promise<string> {
    loadUserSetting();
    const url = new URL('/authorize', getAuth0AppConfig().baseUrl);
    url.searchParams.append('audience', getAuth0AppConfig().apiAudience);
    url.searchParams.append('client_id', getAuth0AppConfig().clientId);
    url.searchParams.append('response_type', codeResponseType);
    url.searchParams.append('scope', scope.join(' '));
    // PKCE
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);
    // We can't let these stack up or network requests will start to
    // fail due to 'too many cookies'
    await clearVerifierKeys(storage);
    const verifierId = uuidv4();
    storage.set(getVerifierKey(verifierId), verifier);
    await storage.onIdle?.();
    url.searchParams.append('code_challenge_method', 'S256');
    url.searchParams.append('code_challenge', challenge);
    // Get the verifierId back so that we can know which of the verifiers from
    // which window to actually use. You won't necessarily return to the same
    // tab or window. We can't use state because it may get overwritten by
    // another tab or window.
    const redirectUrl = new URL(getAuth0AppConfig(redirectToWebAfterAuth0).authorizeCallback);

    if (auth0CallbackParams) {
        auth0CallbackParams.forEach((value, key) => {
            redirectUrl.searchParams.append(key, value);
        });
    }
    const redirectUrlWithVerifier = appendVerifierId(
        redirectUrl,
        verifierId,
        redirectToWebAfterAuth0,
    );

    url.searchParams.append('redirect_uri', `${redirectUrlWithVerifier.toString()}`);

    // Descript state
    if (state) {
        state = stateToNonce(storage, state);
        url.searchParams.append('state', state);
    }
    if (initialScreen) {
        url.searchParams.append(InitialScreenQueryKey, initialScreen);
    }
    const { loginHint, submitLabel, welcomeMessage, connection } = auth0MessageParams ?? {};
    // Login hint to prefill email field
    if (loginHint) {
        url.searchParams.append('login_hint', loginHint);
    }
    // Descript submit button label
    if (submitLabel) {
        url.searchParams.append('descript_submit_label', submitLabel);
    }
    // Descript welcome message
    if (welcomeMessage) {
        url.searchParams.append('descript_welcome_message', welcomeMessage);
    }
    if (connection) {
        url.searchParams.append('connection', connection);
    }
    if (auth0EmailInviteParams && auth0LinkInviteParams) {
        throw new DescriptError(
            'Cannot use both email and link invitation params',
            ErrorCategory.Auth,
        );
    } else if (auth0EmailInviteParams) {
        // descript email invite
        const { inviteId, inviteNonce } = auth0EmailInviteParams;
        if (inviteId) {
            url.searchParams.append('descript_invite_id', inviteId);
        }
        if (inviteNonce) {
            url.searchParams.append('descript_invite_nonce', inviteNonce);
        }
    } else if (auth0LinkInviteParams) {
        // Descript link invite
        const { inviteLinkNonce, inviteLinkDriveId } = auth0LinkInviteParams;
        if (inviteLinkNonce) {
            url.searchParams.append('descript_invite_link_nonce', inviteLinkNonce);
        }
        if (inviteLinkDriveId) {
            url.searchParams.append('descript_invite_link_drive_id', inviteLinkDriveId);
        }
    }
    return url.toString();
}

export function isAuthorizationRedirect(redirectedURL: string): boolean {
    const url = new URL(redirectedURL);
    if (url.href.startsWith(getAuth0AppConfig().authorizeCallback)) {
        return true;
    }
    return false;
}

export function getRelayedState(
    redirectedURL: string,
    storage: IAppSettings,
): string | undefined {
    const url = new URL(redirectedURL);
    const state = url.searchParams.get('state');
    // eslint-disable-next-line no-null/no-null
    if (state !== null) {
        return nonceToState(storage, state);
    }
    return undefined;
}

export async function handleAuthorizationRedirect(
    redirectedURL: string,
    saveTokensCallback: (accessToken: string, refreshToken: string) => Promise<void>,
    storage: IAppSettings,
    isRedirectToWebAfterAuth0 = false,
    redirectToProjectBrowser = true,
) {
    ApiClientBase.authLog('Auth0Client.handleAuthorizationRedirect');
    const url = new URL(redirectedURL);
    if (
        isAuthorizationRedirect(redirectedURL) ||
        isDesktop2 ||
        // When deployed to staging-web, this ends up with an extra `/` at the end
        url.pathname.startsWith(Routes.privateEmbedAuth)
    ) {
        const code = url.searchParams.get(codeResponseType);
        const verifierId = url.searchParams.get(verifierIdResponseType);

        if (code && verifierId) {
            const rawVerifier = storage.get(getVerifierKey(verifierId));
            // We can't let these stack up or network requests will start to
            // fail due to 'too many cookies'
            await clearVerifierKeys(storage);
            if (!rawVerifier) {
                // Hide the callback url from history so users can't find their way
                // to old callback urls on their own so easily.
                if (redirectToProjectBrowser) {
                    window.location.replace(
                        new URL(Routes.projectBrowser, window.location.origin),
                    );
                }
                throw new Errors.RequestError('Missing verifier', 500);
            }
            const verifier = String(rawVerifier);
            const start = Date.now();
            const emoji = getNextEmoji();
            const request = new Request(`${getAuth0AppConfig().baseUrl}${oauthTokenPath}`, {
                method: ApiClientBase.RequestType.POST,
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    grant_type: 'authorization_code',
                    client_id: getAuth0AppConfig().clientId,
                    code,
                    redirect_uri: getAuth0AppConfig(isRedirectToWebAfterAuth0)
                        .authorizeCallback,
                    ...(verifier ? { code_verifier: verifier } : {}),
                }),
            });
            let response: Response;
            try {
                ApiClientBase.networkLog({ id: emoji, summary: '⏱', request });
                response = await fetch(request);
            } catch (e) {
                const error = e as Error;
                ApiClientBase.networkLog({
                    id: emoji,
                    summary: '❌',
                    request,
                    start,
                    error: error.message,
                });
                throw Errors.networkError(error);
            }
            if (!response.ok) {
                ApiClientBase.networkLog({
                    id: emoji,
                    summary: '🚸',
                    request,
                    start,
                    response,
                });
                throw new Errors.RequestError(response.statusText, response.status);
            }
            ApiClientBase.networkLog({ id: emoji, summary: '✅', request, start, response });
            const responseBody = await response.json();
            const accessToken = responseBody.access_token;
            if (!accessToken) {
                throw new Errors.RequestError('Missing access token', 500);
            }
            const refreshToken = responseBody.refresh_token;
            if (!refreshToken) {
                // For refresh tokens - make sure API Settings has 'Allow Offline Access' enabled in the Auth0 dashboard
                trackError(new Errors.RequestError('Missing refresh token', 500), 'authorize', {
                    category: ErrorCategory.Auth,
                });
            }
            await saveTokensCallback(accessToken, refreshToken || '');
            void UserClient.markAuthorized(defaultCtx(), true).catch((e) => {
                const error = e as Error;
                trackError(error, 'request-auth0-sync', { category: ErrorCategory.Auth });
            });
            authorizedChannel?.postMessage(undefined);
        }
    }
}

export async function handleReauthorize(
    refreshToken: string,
    saveTokensCallback: (accessToken: string, refreshToken: string) => Promise<void>,
): Promise<void> {
    if (!refreshToken) {
        throw new Errors.RequestError('No refresh token', 401);
    }
    ApiClientBase.authLog('Auth0Client.handleReauthorize', refreshToken);
    const start = Date.now();
    const emoji = getNextEmoji();
    // We can't use getAuth0AppConfig since this is also used in share-route
    // and that fn access the window object
    const tenant = getTenant();
    const application = getApplication(tenant);
    const request = new Request(`${tenant.tenantBaseUrl}${oauthTokenPath}`, {
        method: ApiClientBase.RequestType.POST,
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            grant_type: 'refresh_token',
            client_id: application.clientId,
            refresh_token: refreshToken,
        }),
    });
    let response: Response;
    try {
        ApiClientBase.networkLog({ id: emoji, summary: '⏱', request });
        response = await fetch(request);
    } catch (e) {
        const error = e as Error;
        ApiClientBase.networkLog({
            id: emoji,
            summary: '❌',
            request,
            start,
            error: error.message,
        });
        throw Errors.networkError(error);
    }
    if (!response.ok) {
        ApiClientBase.networkLog({ id: emoji, summary: '🚸', request, start, response });
        throw new Errors.RequestError(response.statusText, response.status);
    }
    ApiClientBase.networkLog({ id: emoji, summary: '✅', request, start, response });
    const responseBody = await response.json();
    const newAccessToken = responseBody.access_token;
    if (!newAccessToken) {
        throw new Errors.RequestError('Missing new access token', 500);
    }
    const newRefreshToken = responseBody.refresh_token;
    if (!newRefreshToken) {
        // For refresh tokens - make sure API Settings has 'Allow Offline Access' enabled in the Auth0 dashboard
        trackError(new Errors.RequestError('Missing new refresh token', 500), 'reauthorize', {
            category: ErrorCategory.Auth,
        });
    }
    await saveTokensCallback(newAccessToken, newRefreshToken || '');
    void UserClient.markAuthorized(defaultCtx(), false).catch((e) => {
        const error = e as Error;
        trackError(error, 'request-auth0-sync', { category: ErrorCategory.Auth });
    });
    authorizedChannel?.postMessage(undefined);
}

// returnTo must be added to Auth0 Allowed Logout URLs
export function generateLogoutURL({
    storage,
    state,
    returnTo,
}: {
    storage: IAppSettings;
    state?: string;
    returnTo?: string;
}): string {
    const url = new URL('/v2/logout', getAuth0AppConfig().baseUrl);
    url.searchParams.append('client_id', getAuth0AppConfig().clientId);
    const returnToURL = new URL(returnTo || getAuth0AppConfig().logoutCallback);
    // Descript state
    if (state) {
        const nonce = stateToNonce(storage, state);
        returnToURL.searchParams.append('state', nonce);
    }
    url.searchParams.append('returnTo', returnToURL.toString());
    return url.toString();
}

export async function generateExternalBrowserAuthURLs({
    storage,
    auth0MessageParams,
    redirectToWebAfterAuth0 = false,
}: {
    storage: IAppSettings;
    auth0MessageParams?: Auth0MessageParams;
    redirectToWebAfterAuth0?: boolean;
}): Promise<{ signIn: string; signUp: string }> {
    const signInBase = await generateAuthorizationURL({
        storage,
        // This doesn't actually work on desktop which is why we still have
        // the resumeAccountLinking function for account linking
        state: undefined,
        initialScreen: undefined,
        auth0MessageParams,
        auth0EmailInviteParams: undefined,
        auth0LinkInviteParams: undefined,
        redirectToWebAfterAuth0,
    });
    const signInURL = new URL(signInBase);
    signInURL.searchParams.append(InitialScreenQueryKey, InitialScreen.login);
    const signUpURL = new URL(signInBase);
    signUpURL.searchParams.append(InitialScreenQueryKey, InitialScreen.signUp);
    return {
        signIn: signInURL.toString(),
        signUp: signUpURL.toString(),
    };
}

export function isLogoutRedirect(redirectedURL: string): boolean {
    const url = new URL(redirectedURL);
    if (url.href.startsWith(getAuth0AppConfig().logoutCallback)) {
        return true;
    }
    return false;
}

export function isValidJWT(jwtToken: string): boolean {
    try {
        decodeJWT(jwtToken);
    } catch (e) {
        return false; // Malformed
    }
    return true;
}

function base64URLEncode(input: Buffer): string {
    return input.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

function generateCodeVerifier(): string {
    return base64URLEncode(randomBytes(32));
}

async function generateCodeChallenge(verifier: string): Promise<string> {
    return base64URLEncode(await sha256(verifier));
}

function generateNonce(): string {
    return base64URLEncode(randomBytes(16));
}

function stateToNonce(storage: IAppSettings, state: string): string {
    const nonce = generateNonce();
    const nonceState: NonceState = {
        nonce,
        state,
    };
    storage.set(nonceStateKey, nonceState);
    return nonce;
}

function nonceToState(storage: IAppSettings, nonce: string): string | undefined {
    const nonceState = storage.get(nonceStateKey) as NonceState;
    if (nonceState && nonce === nonceState.nonce) {
        return nonceState.state;
    }
    return undefined;
}

export const test = {
    getVerifier: function (storage: IAppSettings, verifierId: string) {
        return storage.get(getVerifierKey(verifierId));
    },
    deleteVerifier: function (storage: IAppSettings) {
        Object.keys(storage.getAll())
            .filter((s) => s.startsWith(verifierKeyPrefix))
            .forEach((key) => storage.delete(key));
    },
    authCode: 'testl2XsB2GlXtestjPMtxdtest',
    accessToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MDE5MzUyMjQsImV4cCI6OTU2NDE1OTc4MjQsImF1ZCI6Ind3dy5leGFtcGxlLmNvbSIsInN1YiI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJHaXZlbk5hbWUiOiJKb2hubnkiLCJTdXJuYW1lIjoiUm9ja2V0IiwiRW1haWwiOiJqcm9ja2V0QGV4YW1wbGUuY29tIiwiUm9sZSI6WyJNYW5hZ2VyIiwiUHJvamVjdCBBZG1pbmlzdHJhdG9yIl19.s96w6xzv-6sGwkmxOcau_wL7JPmrXmzSMahL2yFXgUo',
    expiredAccessToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MDE5MzUyMjQsImV4cCI6MTYwMTkzNzAyNCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.e9YJ8pjg5r_HTGoDkiWDLf8L06sR5UpG6i6m07XThWo',
    refreshToken:
        'v1.Mizx-QRj_HMa-testl2XsB2GlXtestjPMtxd4RZIOtestk33Jcuuctest85wrwxstestz5JgYNKtestBxuJtest',
};
