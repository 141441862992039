// Copyright 2024 Descript, Inc

// eslint-disable-next-line no-restricted-imports
import baseInvariant from 'invariant';
import { ErrorCategory } from './ErrorCategory';
import { DescriptError } from './DescriptError';

export function invariant(
    condition: unknown,
    message: string,
    category: ErrorCategory,
): asserts condition {
    try {
        baseInvariant(condition, message);
    } catch (_e) {
        throw new DescriptError(message, category);
    }
}
