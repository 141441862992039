// Copyright © 2024 Descript, Inc. All rights reserved.

import path from 'path';
import type { Integration, Event, Stacktrace } from '@sentry/types';

/**
 * Rewrite file paths in place to be app:///<filename> instead the system path
 */
export function rewritePathsInWebWorkerElectron(stacktrace: Stacktrace | undefined): void {
    if (!stacktrace?.frames) {
        return;
    }
    for (const frame of stacktrace.frames) {
        if (frame.filename !== undefined && !frame.filename.startsWith('app:')) {
            const basename = path.basename(frame.filename);
            frame.filename = `app:///${basename}`;
        }
        if (frame.abs_path !== undefined && !frame.abs_path.startsWith('app:')) {
            const basename = path.basename(frame.abs_path);
            frame.abs_path = `app:///${basename}`;
        }
    }
}

export const appPathIntegration: Integration = {
    name: 'descript.appPath',
    setupOnce() {
        // no-op; will become optional w/ Sentry 8
    },
    processEvent(event: Event): Event | PromiseLike<Event | null> | null {
        if (event.exception?.values) {
            for (const exception of event.exception.values) {
                rewritePathsInWebWorkerElectron(exception.stacktrace);
            }
        }
        return event;
    },
};
