// Copyright 2024 Descript, Inc

/* eslint-disable no-null/no-null */

import type { Event, Integration, EventHint } from '@sentry/types';
import { isErrorIgnored } from './isErrorIgnored';
import { getErrorMessageFromEvent } from './getErrorFromEvent';

const THROTTLE_WINDOW = 15 * 60 * 1000;
const ALLOWED_IN_WINDOW = 5;

/** A map of error types to the times they were most recently sent */
const rateLimitsStartTimes: Record<string, number> = {};
const rateLimitsCounts: Record<string, number> = {};

/**
 * Drop certain events from being sent to Sentry.
 */
export class IgnoredEventsIntegration implements Integration {
    name = 'descript.ignoredEvents';

    private readonly throttleWindow: number;

    constructor(options: { throttleWindow?: number } = {}) {
        this.throttleWindow = options.throttleWindow ?? THROTTLE_WINDOW;
    }

    setupOnce(): void {
        // no-op; will become optional w/ Sentry 8
    }

    async processEvent(event: Event, hint: EventHint): Promise<Event | null> {
        const error = await getErrorMessageFromEvent(event);

        // If the error is ignored drop the event
        if (error && isErrorIgnored(error)) {
            return null;
        }

        if (error && error.name && error.message) {
            const key = `${error.name}:${error.message}`;
            const lastTime = rateLimitsStartTimes[key];

            // We're in the throttle window
            if (lastTime && Date.now() - lastTime < this.throttleWindow) {
                // Drop the event if we've already seen it ALLOWED_IN_WINDOW times
                if (
                    typeof rateLimitsCounts[key] === 'number' &&
                    rateLimitsCounts[key] >= ALLOWED_IN_WINDOW
                ) {
                    return null;
                }

                // Otherwise, increment the count and let the event through
                rateLimitsCounts[key] =
                    typeof rateLimitsCounts[key] === 'number' ? rateLimitsCounts[key] + 1 : 1;
            } else {
                // Add metadata about the amount of time
                if (rateLimitsCounts[key] && rateLimitsCounts[key] > ALLOWED_IN_WINDOW) {
                    event.extra = {
                        ...event.extra,
                        error_rate_limits_count: rateLimitsCounts[key] - ALLOWED_IN_WINDOW,
                    };
                }

                rateLimitsCounts[key] = 1;
                rateLimitsStartTimes[key] = Date.now();
            }
        }

        return event;
    }
}
