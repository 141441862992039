import { hexToRgba } from '../../lib/colors';
import { pxToRem } from '../../lib/pxToRem';
import type { ThemeColors } from './types';

export const theme = {
    fontFamilies: {
        bold: '"Inter", "Helvetica Neue", Helvetica, Arial, sans-serif',
        medium: '"Inter", "Helvetica Neue", Helvetica, Arial, sans-serif',
        mono: 'InputMonoRegular, "Lucida Console", Monaco, monospace',
        regular: '"Inter", "Helvetica Neue", Helvetica, Arial, sans-serif',
        semibold: '"Inter", "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    fontSizes: {
        xsmall: pxToRem(9),
        small: pxToRem(10),
        mono: pxToRem(11),
        base: pxToRem(12),
        large: pxToRem(16),
        xlarge: pxToRem(32),
    },
    lineHeights: {
        small: pxToRem(14),
        base: pxToRem(16),
        large: pxToRem(24),
        xlarge: pxToRem(38),
    },
    mediaQueries: {
        small: '(max-width: 413px)',
        medium: '(min-width: 414px)',
        large: '(min-width: 768px)',
        xlarge: '(min-width: 1051px)',
        coarse: '(pointer: coarse)',
        /**
         * Used for devices that don't actively use '(pointer: coarse)', such as a desktop PC, a laptop or a tablet with connected mouse.
         * When using logical operators ('not', 'only') in a @media query, the media type (here 'screen') becomes mandatory.
         * See for more: https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries#syntax
         */
        nonCoarse: 'not screen and (pointer: coarse)',
    },
    zIndex: {
        sideNav: 10,
        header: 20,
        menu: 30,
        snackbar: 45,
        tooltip: 50,
        modal: 60,
    },
};

export const systemColors = {
    // Custom Descript color palette
    descript: {
        lightMode: {
            grey: {
                // background
                0: '#FFFFFF',
                // popover background
                5: '#F2F2F2',
                // 100A borders & dividers
                100: '#E6E6E6',
                // unselected
                400: '#858585',
                // primary
                800: '#343434',
                // states & headlines
                900: '#1A1A1A',
            },
            blue: {
                // primary action
                500: '#0045E5',
            },
            green: {
                500: '#009B00',
            },
        },
        darkMode: {
            grey: {
                // background
                0: '#1A1A1A',
                // popover background
                5: '#262626',
                // 100A borders & dividers
                100: 'rgba(255, 255, 255, 0.16)',
                // unselected
                400: '#CCCCCC',
                // primary
                800: '#E6E6E6',
                // states & headlines
                900: '#FFFFFF',
            },
            blue: {
                // primary action
                500: '#6F94EC',
            },
            green: {
                500: '#009B00',
            },
        },
    },

    /** #1f2d3d */
    blue: '#1f2d3d',
    /** input focus */
    blueDark: '#6F94EC',
    /** #2b3f56 */
    blueLight: '#2b3f56',
    /** #00c9df */
    cyan: '#00c9df',
    /** #e2fbfd */
    cyanLight: '#e2fbfd',
    /** #c8d1dc */
    grey: '#c8d1dc',
    /** #6b7785 */
    greyDark: '#6b7785',
    /** #e6eaef */
    greyLight: '#e6eaef',
    /** #eff3f5 */
    greyLightest: '#eff3f5',
    /** #feaa2c */
    orange: '#feaa2c',
    /** #ffeed5 */
    orangeLight: '#ffeed5',
    /** #f63135 */
    red: '#f63135',
    /** #fddddd */
    redLight: '#fddddd',
    /** #1bebb9 */
    teal: '#1bebb9',
    /** #d1fbf1 */
    tealLight: '#d1fbf1',
    /** #fad71f */
    yellow: '#fad71f',
    /** #ffffff */
    white: '#ffffff',

    green: '#009B00',
};

export const getThemeCSS = (colors: ThemeColors) => `
  --body-bg: ${colors.background};
  --body-bg-accent: ${colors.backgroundAccent};
  --body-color: ${colors.baseText};

  --backdrop: rgba(0, 0, 0, 0.6);
  --backdrop-hidden: rgba(0, 0, 0, 0);

  --badge-default-bg: ${colors.background};
  --badge-default-border: ${colors.border};
  --badge-error-bg: ${systemColors.redLight};
  --badge-error-border: ${systemColors.red};
  --badge-info-bg: ${systemColors.cyanLight};
  --badge-info-border: ${systemColors.cyan};
  --badge-success-bg: ${systemColors.tealLight};
  --badge-success-border: ${systemColors.teal};
  --badge-warning-bg: ${systemColors.orangeLight};
  --badge-warning-border: ${systemColors.orange};

  --button-color: var(--body-color);
  --button-disabled-bg: ${colors.backgroundAccent};
  --button-disabled-border: ${colors.border};
  --button-disabled-color: ${colors.supportiveText};
  --button-ghost-color: var(--body-color);
  --button-secondary-bg: ${colors.background};
  --button-secondary-border: ${colors.border};
  --button-secondary-color: ${colors.baseText};
  --button-secondary-disabled-bg: ${colors.background};
  --button-secondary-disabled-border: ${colors.border};
  --button-secondary-disabled-color: ${colors.border};

  --card-bg: ${colors.background};
  --card-bg-hover: ${colors.backgroundAccent};
  --card-border: ${colors.border};
  --card-footer-bg: ${colors.background};
  --card-grey-bg: ${colors.backgroundAccent};
  --card-header-bg: ${colors.background};
  --card-loader-bg: ${colors.background};
  --card-shadow: rgba(0, 0, 0, 0.04);
  --card-warning-bg: ${systemColors.orangeLight};
  --card-warning-border: ${systemColors.orange};
  --card-success-bg: ${systemColors.tealLight};
  --card-success-border: ${systemColors.teal};

  --checkboxtoggle-bg: ${colors.background};
  --checkboxtoggle-border: ${colors.border};
  --checkboxtoggle-checked-bg: ${colors.accent};
  --checkboxtoggle-checked-border: ${colors.accent};
  --checkboxtoggle-disabled-bg: ${colors.backgroundAccent};
  --checkboxtoggle-disabled-border: ${colors.border};
  --checkboxtoggle-disabled-fill-bg: ${colors.background};
  --checkboxtoggle-fill-bg: ${colors.system.white};
  --checkboxtoggle-fill-shadow: rgba(0,0,0,0.1);

  --focus-border: ${colors.system.blueDark};
  --focus-shadow: ${hexToRgba(colors.system.blueDark, 0.6)};

  --input-bg: ${colors.background};
  --input-border: ${colors.border};
  --input-color: ${colors.baseText};
  --input-disabled-bg: ${colors.backgroundAccent};
  --input-disabled-color: ${colors.supportiveText};
  --input-disabled-placeholder-color: ${colors.supportiveText};
  --input-error-border: ${systemColors.red};
  --input-error-color: ${systemColors.red};
  --input-error-shadow: ${hexToRgba(systemColors.red, 0.6)};
  --input-placeholder-color: ${colors.supportiveText};
  --input-suffix-bg: ${colors.backgroundAccent};
  --input-suffix-color: ${colors.baseText};

  --menu-bg: ${colors.backgroundAccent};
  --menu-item-hover-bg: ${hexToRgba(colors.background, 0.5)};

  --percentagebar-bg: ${colors.backgroundAccent};
  --percentagebar-inset-shadow: ${colors.border};

  --radio-border: ${colors.border};
  --radio-checked: ${colors.baseText};

  --sidenav-active-bg: ${colors.accent};
  --sidenav-active-border: ${colors.accent};
  --sidenav-active-color: ${colors.accentText};
  --sidenav-bg: ${colors.backgroundAccent};
  --sidenav-border: ${colors.border};
  --sidenav-color: ${colors.baseText};
  --sidenav-item-bg: ${colors.backgroundAccent};
  --sidenav-item-hover-bg: ${colors.background};

  --snackbar-error-bg: ${systemColors.red};
  --snackbar-error-color: ${systemColors.white};
  --snackbar-info-bg: ${colors.background};
  --snackbar-info-color: ${colors.background};
  --snackbar-success-bg: ${colors.baseText};
  --snackbar-success-color: ${colors.background};

  --table-border: ${colors.border};
  --table-head-bg: ${colors.backgroundAccent};
  --table-loading-bg: ${hexToRgba(colors.background, 0.5)};
  --table-row-hoverable-bg: ${colors.backgroundAccent};

  --tabs-indicator: ${colors.accent};
  --tabs-bg: ${colors.background};
  --tabs-scroll-bg-op0: ${hexToRgba(colors.background, 0)};

  --text-default: ${colors.baseText};
  --text-error: ${systemColors.red};
  --text-info: ${systemColors.cyan};
  --text-inverse: ${colors.background};
  --text-muted: ${colors.supportiveText};
  --text-success: ${colors.accent};
  --text-warning: ${systemColors.orange};
  --text-white: #ffffff;

  --toggle-icon-hover-bg: ${colors.background};
  --toggle-icon-hover-border: ${colors.border};

  --tooltip-bg: ${colors.backgroundAccent};
  --tooltip-border: ${colors.border};
  --tooltip-color: ${colors.baseText};
  --tooltip-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

  --room-bg: ${colors.roomBackground};
`;
