// Copyright 2022 Descript, Inc

export const DEBUG_SETTINGS_SERIALIZED_VERSION_1 = 1; // Initial version
export const DEBUG_SETTINGS_SERIALIZED_VERSION_2 = 2; // Store default, enum, and optional description
export const DEBUG_SETTINGS_SERIALIZED_VERSION_CURRENT = DEBUG_SETTINGS_SERIALIZED_VERSION_2;

/**
 * Supported types of data for the DebugSettings
 * This could be expanded in the future to be more types, if-needed
 * Clients outside of `descript-core` should use `DebugSettings.ValueType`
 * from `DebugSettings.ts` instead
 */
export type DebugSettingsEntryValueType = number | string | boolean;

export type DebugSettingsEnumValueType = Exclude<DebugSettingsEntryValueType, boolean>; // only `number` or `string`
export type DebugSettingsRangeValueType = Readonly<{ min?: number; max?: number }>;
export type DebugSettingsConstrainedValueType =
    | readonly DebugSettingsEnumValueType[]
    | DebugSettingsRangeValueType;

/**
 *
 */
export type DebugSettingsEntryType = {
    value: DebugSettingsEntryValueType;
    defaultValue: DebugSettingsEntryValueType;
    constrainedValues?: DebugSettingsConstrainedValueType;
    description?: string;
};

/**
 *
 */
export type SerializedDebugSettingsBase = {
    version: number;
};

/**
 *
 */
export type SerializedDebugSettingsV1 = SerializedDebugSettingsBase & {
    version: 1; // DEBUG_SETTINGS_SERIALIZED_VERSION_1
    data: Record<string, DebugSettingsEntryValueType>;
};

/**
 *
 */
export type SerializedDebugSettingsV2 = SerializedDebugSettingsBase & {
    version: 2; // DEBUG_SETTINGS_SERIALIZED_VERSION_2
    data: Record<string, DebugSettingsEntryType>;
};

/**
 * Consistent object to hold serialized data from the DebugSettings
 * Clients outside of `descript-core` should use `DebugSettings.SerializedType`
 * from `DebugSettings.ts` instead
 */
export type SerializedDebugSettings = SerializedDebugSettingsV2;
