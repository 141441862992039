// Copyright 2024 Descript, Inc

import { DebugSettings } from '@descript/descript-core';
import { MediaAssetType } from '@descript/descript-model';
import { DescriptError, ErrorCategory } from '@descript/errors';

export type FileType = {
    name: string;
    extensions: string[];
    mimeTypes: string[];
    web: boolean;
};
export type FileTypes = Record<string, FileType>;

/**
 * Supported file types
 * https://help.descript.com/hc/en-us/articles/10164098416909-Supported-file-types
 */
export const supportedFileTypes: FileTypes = {
    wav: {
        name: 'Waveform Audio File Format',
        extensions: ['wav'],
        mimeTypes: ['audio/wav', 'audio/x-wav', 'audio/wave', 'audio/x-pn-wav'],
        web: true,
    },
    mp3: {
        name: 'MPEG-1 Audio Layer 3',
        extensions: ['mp3'],
        mimeTypes: ['audio/mpeg', 'audio/mp3'],
        web: true,
    },
    aac: {
        name: 'Advanced Audio Coding',
        extensions: ['aac'],
        mimeTypes: ['audio/aac', 'audio/x-aac'],
        web: false,
    },
    aiff: {
        name: 'Audio Interchange File Format',
        extensions: ['aiff', 'aif', 'aifc'],
        mimeTypes: ['audio/aiff', 'audio/x-aiff', 'audio/x-aif', 'audio/x-aifc'],
        web: false,
    },
    flac: {
        name: 'Free Lossless Audio Codec',
        extensions: ['flac'],
        mimeTypes: ['audio/flac', 'audio/x-flac'],
        web: false,
    },
    mpeg: {
        name: 'MPEG-1',
        extensions: ['mpeg', 'mpg'],
        mimeTypes: ['video/mpeg', 'video/mpg', 'video/mp1s'],
        web: false,
    },
    mp4: {
        name: 'MPEG-4',
        extensions: ['mp4', 'm4v', 'm4a'],
        mimeTypes: ['video/mp4', 'video/m4v', 'audio/mp4', 'audio/x-m4a', 'video/x-m4v'],
        web: true,
    },
    '3gpp': {
        name: '3rd Generation Partnership Project',
        extensions: ['3gpp', 'mp4', 'm4v', 'm4a', '3gp'],
        mimeTypes: ['video/3gpp'],
        web: true,
    },
    mov: {
        name: 'QuickTime File Format',
        extensions: ['mov'],
        mimeTypes: ['video/quicktime'],
        web: true,
    },
    bmp: {
        name: 'Bitmap Image File',
        extensions: ['bmp'],
        mimeTypes: ['image/bmp'],
        web: true,
    },
    jpeg: {
        name: 'Joint Photographic Experts Group',
        extensions: ['jpg', 'jpeg'],
        mimeTypes: ['image/jpeg'],
        web: true,
    },
    png: {
        name: 'Portable Network Graphics',
        extensions: ['png'],
        mimeTypes: ['image/png'],
        web: true,
    },
    gif: {
        name: 'Graphics Interchange Format',
        extensions: ['gif'],
        mimeTypes: ['image/gif'],
        web: true,
    },
    tiff: {
        name: 'Tagged Image File Format',
        extensions: ['tiff', 'tif'],
        mimeTypes: ['image/tiff'],
        web: false,
    },
    webp: {
        name: 'WebP',
        extensions: ['webp'],
        mimeTypes: ['image/webp'],
        web: true,
    },
    webm: {
        name: 'WebM',
        extensions: ['webm'],
        mimeTypes: ['video/webm'],
        web: true,
    },
    mkv: {
        name: 'Matroska',
        extensions: ['mkv'],
        mimeTypes: ['video/x-matroska'],
        web: true,
    },
    opus: {
        name: 'Opus',
        extensions: ['opus'],
        mimeTypes: ['audio/opus'],
        web: true,
    },
};

/**
 * Supported file types, including fonts. These are not included in the standard
 * list because we don't support adding fonts directly into the app.
 */
const supportedFileTypesAndFonts: FileTypes = {
    ...supportedFileTypes,
    ttf: {
        name: 'TrueType Font',
        extensions: ['ttf'],
        mimeTypes: ['font/ttf'],
        web: true,
    },
    otf: {
        name: 'OpenType Font',
        extensions: ['otf'],
        mimeTypes: ['font/otf'],
        web: true,
    },
};

export const unsupportedFileTypes: FileTypes = {
    avi: {
        name: 'Audio Video Interleave',
        extensions: ['avi'],
        mimeTypes: ['video/avi'],
        web: false,
    },
    wmv: {
        name: 'Windows Media Video',
        extensions: ['wmv'],
        mimeTypes: ['video/x-ms-wmv'],
        web: false,
    },
    wma: {
        name: 'Windows Media Audio',
        extensions: ['wma'],
        mimeTypes: ['audio/x-ms-wma'],
        web: false,
    },
    ogv: {
        name: 'Ogg Video',
        extensions: ['ogv'],
        mimeTypes: ['video/ogg'],
        web: false,
    },
    ogg: {
        name: 'Ogg Vorbis',
        extensions: ['ogg'],
        mimeTypes: ['audio/ogg'],
        web: true,
    },
    mts: {
        name: 'MPEG Transport Stream',
        extensions: ['mts'],
        mimeTypes: ['video/mp2t'],
        web: false,
    },
    mxf: {
        name: 'Material Exchange Format',
        extensions: ['mxf'],
        mimeTypes: ['application/mxf'],
        web: false,
    },
    exif: {
        name: 'Exchangeable Image File Format',
        extensions: ['exif'],
        mimeTypes: ['image/x-exif'],
        web: false,
    },
    '3gp2': {
        name: '3rd Generation Partnership Project 2',
        extensions: ['3gpp2', '3gp2', '3g2'],
        mimeTypes: ['video/3gpp2'],
        web: false,
    },
};

export const acceptedMimeTypes = new Set(
    Object.values(supportedFileTypes)
        .flatMap((type) => type.mimeTypes)
        .map((mimeType) => mimeType.toLowerCase()),
);
export const acceptedWebExtensions = new Set(
    Object.values(supportedFileTypes)
        .filter((type) => type.web)
        .flatMap((type) => type.extensions)
        .map((extension) => extension.toLowerCase()),
);

export const acceptedDesktopExtensions = new Set(
    Object.values(supportedFileTypes)
        .flatMap((type) => type.extensions)
        .map((extension) => extension.toLowerCase()),
);

export function isAcceptedMediaMimeType(mimeType: string): boolean {
    switch (mimeType) {
        case 'audio/opus':
            return DebugSettings.getValue('file-types.opus', false, 'Allow adding Opus');
        case 'video/x-matroska':
        case 'video/webm':
            return DebugSettings.getValue('file-types.mkv', false, 'Allow adding MKV and WebM');
    }
    return acceptedMimeTypes.has(mimeType.toLowerCase());
}

/**
 * Mapping of mimetype to file extensions associated with that mimetype..
 */
export const mimeTypeToExtensions = Object.fromEntries(
    Object.values(supportedFileTypesAndFonts).flatMap(({ mimeTypes, extensions }) => {
        return mimeTypes.map((mimeType) => [mimeType, extensions]);
    }),
);

function isMediaAssetType(value: string): value is MediaAssetType {
    const mediaAssetTypes: MediaAssetType[] = [
        'video',
        'audio',
        'image',
        'font',
        'imputation',
        'roomtone',
        'bounced_scene',
        'unknown',
    ];
    return (mediaAssetTypes as string[]).includes(value);
}

export function getMediaAssetTypeFromFileExtension(fileExtension: string): MediaAssetType {
    fileExtension = fileExtension.toLowerCase().replace('.', '');

    const supportedFileType = supportedFileTypes[fileExtension];
    if (!supportedFileType) {
        throw new DescriptError(
            `Unsupported file extension: ${fileExtension}`,
            ErrorCategory.VideoMediaEngine,
        );
    }

    const assetType = supportedFileType.mimeTypes[0]!.split('/')[0];

    if (!isMediaAssetType(assetType!)) {
        throw new DescriptError(
            `Unsupported asset type: ${assetType}`,
            ErrorCategory.VideoMediaEngine,
        );
    }

    return assetType;
}
