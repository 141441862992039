// Copyright 2019 Descript, Inc

export enum ExportFileFormat {
    // Text
    Word = 'word',
    RTF = 'rtf',
    TXT = 'txt',
    HTML = 'html',
    Markdown = 'md',

    // Subtitles
    SRT = 'srt',
    VTT = 'vtt',

    // Audio
    AAC = 'aac',
    WAV = 'wav',
    MP3 = 'mp3',

    // Video
    MP4 = 'mp4',
    GIF = 'gif',

    // Session
    AAF = 'aaf',
    OMF = 'omf',
    SESX = 'sesx',
    EDL = 'edl',
    FCP = 'fcp',
    Premiere = 'premiere',
    DaVinciResolve = 'davinci_resolve',

    // Debugging doc export
    JSON = 'json',

    // project backup, a zipped version of the project JSON
    DEBACKUP = 'debackup',

    ZIP = 'zip',
}

export const audioFormats = [ExportFileFormat.AAC, ExportFileFormat.WAV, ExportFileFormat.MP3];

export const timelineFormats = [
    ExportFileFormat.AAF,
    ExportFileFormat.SESX,
    ExportFileFormat.EDL,
    ExportFileFormat.FCP,
    ExportFileFormat.Premiere,
    ExportFileFormat.DaVinciResolve,
];

export const transcriptFormats = [
    ExportFileFormat.HTML,
    ExportFileFormat.Markdown,
    ExportFileFormat.Word, // Microsoft Word
    ExportFileFormat.TXT, // Plain text
    ExportFileFormat.RTF,
];

export const subtitleFormats = [ExportFileFormat.SRT, ExportFileFormat.VTT];

export const copyableFormats: ExportFileFormat[] = [
    ExportFileFormat.Markdown,
    ExportFileFormat.TXT,
    ExportFileFormat.HTML,
    ExportFileFormat.RTF,
];

export const fileFormatsSupportingMarkers = [
    ExportFileFormat.MP3,
    ExportFileFormat.AAC,
    ExportFileFormat.MP4,
];

export const fileFormatsSupportingArtwork = [ExportFileFormat.MP3, ExportFileFormat.AAC];

export function hasConfigurableBitrate(format: ExportFileFormat): boolean {
    return format !== ExportFileFormat.WAV;
}

export function isVideoFormat(format: ExportFileFormat): boolean {
    return format === ExportFileFormat.MP4 || format === ExportFileFormat.GIF;
}

export function isVideoOnlyFormat(format: ExportFileFormat): boolean {
    return format === ExportFileFormat.GIF;
}

export function exportScaledLowerResolutions(format: ExportFileFormat): boolean {
    return format === ExportFileFormat.GIF;
}

export function isSourceResolutionExportDefault(format: ExportFileFormat): boolean {
    return format !== ExportFileFormat.GIF;
}

export function doesVideoFormatSupportAudio(format: ExportFileFormat): boolean {
    // not all video file formats support audio as metadata (say gif)
    return format === ExportFileFormat.MP4;
}

export function doesVideoFormatSupportSubtitles(format: ExportFileFormat): boolean {
    // not all video file formats support subtitles as metadata (say gif)
    return format === ExportFileFormat.MP4;
}

export function doesFileFormatSupportMarkers(format: ExportFileFormat): boolean {
    return fileFormatsSupportingMarkers.includes(format);
}

export function doesFileFormatSupportArtwork(format: ExportFileFormat): boolean {
    return fileFormatsSupportingArtwork.includes(format);
}

export function doesFormatSupportCopying(format: ExportFileFormat): boolean {
    return copyableFormats.includes(format);
}
