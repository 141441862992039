// Copyright 2024 Descript, Inc
/**
 * Paywall features that ALSO have usage quotas in addition to drive plan and
 * membership type permissions. For features that do NOT use quotas, use
 * ToggledFeatureId instead.
 */
export enum TrackedFeatureId {
    aiActionAddChapters = 'add_chapters',
    aiActionCustomPrompt = 'ai_custom_prompt',
    aiActionEditForClarity = 'edit_for_clarity',
    aiActionPublishGroup = 'publish_group',
    aiActionRemoveRetakes = 'remove_retakes',
    aiActionRepurposeMediaGroup = 'repurpose_media_group',
    aiActionTranslateCaptions = 'translate_captions',
    aiActionWriteGroup = 'write_group',
    aiEffectAutomaticMulticam = 'automatic_multicam',
    aiEffectCenterActiveSpeaker = 'center_active_speaker',
    aiEffectEyeContact = 'eye_contact',
    aiEffectGreenScreen = 'green_screen',
    aiEffectStudioSound = 'studio_sound',
    // this feature was renamed, but the value has to remain because it's used server-side
    aiSpeechImputation = 'regenerate_and_overdub',
    aiSpeechTextToSpeech = 'text_to_speech',
    aiSpeechTranslateDubbing = 'translate_dubbing',
    fillerWordRemoval = 'filler_word_removal',
    imageGeneration = 'image_generation',
    shortenWordGaps = 'shorten_word_gaps',
}

/**
 * Features behind drive plan and membership type paywalls that are NOT quota
 * tracked. For features that also have quota paywalls, use TrackedFeatureId.
 */
export enum ToggledFeatureId {
    AdvancedMediaFormats = 'advanced_media_formats',
    BatchExport = 'batch_export',
    ExportTimeline = 'export_timeline',
    FreeMembersAreBasic = 'free_members_are_basic',
    TranslationWithCorrection = 'translation_with_correction',
    PreSeason6AISpeechPaywallBehavior = 'pre_season_6_ai_speech_paywall_behavior',
    PreSeason6EyeContactGreenScreenStudioSoundPaywallBehavior = 'pre_season_6_eye_contact_green_screen_studio_sound_paywall_behavior',
    PreSeason6FillerWordRemovalPaywallBehavior = 'pre_season_6_filler_word_removal_paywall_behavior',
    PreSeason6ShortenWordGapsPaywallBehavior = 'pre_season_6_shorten_word_gaps_paywall_behavior',
}

export enum ConfiguredFeatureId {
    MaxExportResolutionName = 'max_export_resolution_name',
    MaxFileUploadSizeInGB = 'max_file_upload_size_in_gb',
    MaxFileUploadResolutionName = 'max_file_upload_resolution_name',
    MaxFileBitRateInMbps = 'max_file_bit_rate_in_mbps',
    MaxPublishResolutionName = 'max_publish_resolution_name',
}

export type ConfiguredFeatureToPermission = {
    [ConfiguredFeatureId.MaxExportResolutionName]: ResolutionName;
    [ConfiguredFeatureId.MaxFileUploadSizeInGB]: number;
    [ConfiguredFeatureId.MaxFileUploadResolutionName]: ResolutionName;
    [ConfiguredFeatureId.MaxFileBitRateInMbps]: number;
    [ConfiguredFeatureId.MaxPublishResolutionName]: ResolutionName;
};

// Should match names of NamedResolutions in client/src/Utilities/Resolution.ts
export type ResolutionName = '720p' | '1080p' | '1440p' | '4K' | '5K';

export enum TrackedFeatureCategoryId {
    advancedAISuite = 'advanced_ai_suite',
    basicAISuite = 'basic_ai_suite',
    dubbingSuite = 'dubbing_suite',
    professionalAISuite = 'professional_ai_suite',
    // this category was renamed, but the value has to remain because it's used server-side
    imputationSuite = 'regenerate_and_overdub_suite',
    textToSpeechSuite = 'text_to_speech_suite',
}

type TrackedFeatureToCategoryType = {
    [k in TrackedFeatureId]: TrackedFeatureCategoryId;
};

// TODO: dynamically load the mappings from the backend
export const TrackedFeatureToCategory: TrackedFeatureToCategoryType = {
    [TrackedFeatureId.aiActionEditForClarity]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionRemoveRetakes]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionAddChapters]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionPublishGroup]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionWriteGroup]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionRepurposeMediaGroup]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionCustomPrompt]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.imageGeneration]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiEffectStudioSound]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiEffectGreenScreen]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiEffectAutomaticMulticam]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiEffectCenterActiveSpeaker]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.fillerWordRemoval]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.shortenWordGaps]: TrackedFeatureCategoryId.basicAISuite,
    [TrackedFeatureId.aiActionTranslateCaptions]: TrackedFeatureCategoryId.advancedAISuite,
    [TrackedFeatureId.aiEffectEyeContact]: TrackedFeatureCategoryId.advancedAISuite,
    [TrackedFeatureId.aiSpeechTextToSpeech]: TrackedFeatureCategoryId.textToSpeechSuite,
    [TrackedFeatureId.aiSpeechImputation]: TrackedFeatureCategoryId.imputationSuite,
    [TrackedFeatureId.aiSpeechTranslateDubbing]: TrackedFeatureCategoryId.dubbingSuite,
};
