// Copyright 2023 Descript, Inc

import { PlatformHelpers, getAppVersion, JSONObject } from '@descript/descript-core';

const version = getAppVersion();
const build = process.env.REACT_APP_BUILD_NUMBER || process.env.GITHUB_SHA || 'web';
export const appGenericContext: Readonly<JSONObject> = {
    app: {
        name: 'Descript',
        // for Amplitude's "Version" property, for their "Releases" concept
        version,
        build,
    },
};

export function getGenericAppAnalyticsProperties() {
    return {
        platform: PlatformHelpers.platform(),
        build: process.env.PRODUCT || 'unknown',
        build_type: process.env.REACT_APP_BUILD_TYPE || 'unknown',
        version,
        build_number: build,
        bundle_id: PlatformHelpers.clientID(),
        os_version: PlatformHelpers.getOS(),
    };
}
