// Copyright 2022 Descript, Inc

import 'tailwindcss/lib/css/preflight.css';
import '@descript/design-tokens/cursor.css';
import '@descript/design-tokens/icons.css';
import '@descript/design-tokens/animation.css';
import '@descript/design-tokens/tokens.css';
import '@descript/design-tokens/dark-tokens.css';
import '@descript/design-tokens/utility.css';
import '@descript/design-tokens/font.css';
import '@descript/design-tokens/z-index.css';
import './index.css';
import '@descript/design-tokens/animations.css';
