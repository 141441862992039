// Copyright 2023 Descript, Inc

import { init } from '@sentry/browser';
import { Integration } from '@sentry/types';
import { PlatformHelpers } from '@descript/descript-core';
import { appPathIntegration } from './Sentry/Integrations/appPathIntegration';

function getIntegrations(integrations: Integration[]): Integration[] {
    if (PlatformHelpers.isElectron()) {
        // Note: renderer and main sentry instances are initialized elsewhere;
        // this is only for web workers

        // we are initializing this from a web worker on Electron, so we need to
        // parse file paths to be app:///<filename> instead the system path
        return [...integrations, appPathIntegration];
    }
    // use the default integrations
    return integrations;
}

export function initializeSentry() {
    init({
        enabled: Boolean(process.env.SENTRY_DSN),
        dsn: process.env.SENTRY_DSN,
        release: process.env.SENTRY_RELEASE,
        environment: process.env.SENTRY_ENVIRONMENT,
        integrations: getIntegrations,
    });
}
