// Copyright 2020 Descript, Inc

import { createScopedLogger } from '@descript/descript-core';
import { ellipsis } from '../Utilities/Logging';
import * as Sentry from '@sentry/core';

export enum RequestType {
    GET = 'get',
    PUT = 'put',
    PATCH = 'patch',
    POST = 'post',
    DELETE = 'delete',
    HEAD = 'head',
}

export const JSON_TYPE_HEADERS = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
};

export const apiLog = createScopedLogger({ name: 'API' });

export function authLog(message: string, refreshToken?: string) {
    if (refreshToken) {
        message += ` (${refreshToken.slice(-1)})`;
    }
    apiLog.debug(message);
    Sentry.addBreadcrumb({
        category: 'auth',
        level: 'info',
        message,
    });
}

export function networkLog({
    id,
    summary,
    request,
    response,
    start,
    error,
}: {
    id?: string;
    summary: string;
    request: Request;
    response?: Response;
    start?: number;
    error?: string;
}) {
    const requestInfo = `${request.method} ${request.url} ${
        response ? '' : ellipsis(request.body)
    }`;
    const responseInfo = response ? response.status : '';
    const timingInfo = start ? `${Date.now() - start} ms` : '';
    const errorInfo = error ? `ERROR: ${error}` : '';
    apiLog.debug(
        `${id} ${summary} ${responseInfo} ${requestInfo} - ${timingInfo} - ${errorInfo}`,
    );
}

export const descriptAuthHeader = 'x-descript-auth';
