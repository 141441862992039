// Copyright 2019 Descript, Inc

import { Project, User } from '../Api/Project';
import { PublishTypes } from '../Actions/UserPreferenceActions';
import { ParentFolderId } from '../Api/FolderClientConstants';
import { AppConstants } from '../App/Constants';
import { ProjectPermissionLevel } from '../Api/ProjectPermissions';
import { QueryFilter } from '../App/Constants/ProjectBrowser';

export enum ProjectFilter {
    Folder = 'Folder',
    Tutorials = 'Tutorials',
    AISpeakers = 'AISpeakers',
    Workspace = 'Workspace',
    /**
     * Shared with Me is displayed in the UI like a ProjectSubFilter
     * but it is a separate view in the backend
     */
    SharedWithMe = 'Shared with me',
    /**
     * Downloaded used to be a top-level filter and a ProjectSubFilter
     * Now the top-level filter is removed in the UI
     */
    Downloaded = 'Downloaded', // a top level filter and a ProjectSubFilter
    /**
     * Note: if you see a bug with "Home" in the ProjectBrowserBreadcrumbs
     * it means projectFilter is ProjectFilter.All and editorVariant is EditorVariantFilter.All
     * this isn't a valid UI state so it defaults to showing "Home" with both recent projects and recordings
     * (see below note about ProjectFilter.RecentProjects and ProjectFilter.QuickRecordings)
     */
    All = 'Home',
    /**
     * These ProjectFilter values are not fully implemented yet
     * Recent projects and quick recordings are currently pointed to ProjectFilter.All with an EditorVariantFilter set
     * in the future we should move to migrating fully to using these project filters
     */
    RecentProjects = 'Projects', // not fully implemented
    QuickRecordings = 'Quick recordings', // not fully implemented
}

export function matchesProjectFilter(
    projectFilter: ProjectFilter,
    project: Project,
    currentUser: User | undefined,
    driveId: string | undefined,
    downloaded: boolean,
) {
    const isInCurrentLocation = driveId && project.driveId === driveId;
    const { owner } = project;
    switch (projectFilter) {
        case ProjectFilter.All:
        case ProjectFilter.Workspace:
        case ProjectFilter.Folder:
            return (
                isInCurrentLocation &&
                owner &&
                currentUser &&
                // Double check we're not showing admin-only projects in list
                // Exclude projects shared with us for these views
                (currentUser.id === owner.id ||
                    project.permissions.publicAccess !== ProjectPermissionLevel.None ||
                    project.permissions.driveAccess !== ProjectPermissionLevel.None)
            );
        case ProjectFilter.SharedWithMe:
            return (
                owner &&
                currentUser &&
                currentUser.id !== owner.id &&
                currentUser.ownerships?.some((o) => o.projectId === project.id)
            );
        case ProjectFilter.Downloaded:
            return (
                downloaded &&
                owner &&
                currentUser &&
                (currentUser.id === owner.id ||
                    project.permissions.publicAccess !== ProjectPermissionLevel.None ||
                    project.permissions.driveAccess !== ProjectPermissionLevel.None ||
                    currentUser.ownerships?.some((o) => o.projectId === project.id))
            );
        default:
            return true;
    }
}

export function getModifiedProjectFilter(
    projectFilter: ProjectFilter,
    editorVariantFilter: EditorVariantFilter,
): ProjectFilter {
    if (projectFilter === ProjectFilter.All) {
        switch (editorVariantFilter) {
            case EditorVariantFilter.Projects:
                return ProjectFilter.RecentProjects;
            case EditorVariantFilter.Recordings:
                return ProjectFilter.QuickRecordings;
            default:
                return projectFilter;
        }
    }
    if (projectFilter === ProjectFilter.SharedWithMe) {
        return ProjectFilter.RecentProjects;
    }
    return projectFilter;
}

export enum ProjectBrowserTableColumn {
    Name = 'NAME',
    Duration = 'DURATION',
    Size = 'SIZE',
    CreatedAt = 'CREATED',
    LastUpdatedAt = 'LAST UPDATED',
    ThreeDot = 'THREE DOT',
    Owner = 'OWNER',
    Drive = 'DRIVE', // shared with me only
}

export function getDefaultSortAscending(column: ProjectBrowserTableColumn): boolean {
    switch (column) {
        case ProjectBrowserTableColumn.Owner:
        case ProjectBrowserTableColumn.Name:
        case ProjectBrowserTableColumn.Drive:
            return true;
        default:
            return false;
    }
}

export type ProjectFilterSettings = {
    lastPath: string | undefined;
    lastSortColumn: ProjectBrowserTableColumn;
    lastSortAscending: boolean;
    filter: ProjectFilter;
    editorVariantFilter: EditorVariantFilter;
    projectSubFilter: ProjectSubFilter;
    driveId: string | undefined;
    folderId: ParentFolderId;
    workspaceId: string | undefined;
};

export const AllPagesFilter = 'All Pages';
export const ScreenRecordingFilter = 'Recordings';
export const publishFilters = [AllPagesFilter, ...PublishTypes, ScreenRecordingFilter] as const;
export type PublishFilter = (typeof publishFilters)[number];

export enum EditorVariantFilter {
    All = 'All',
    Projects = 'Projects',
    Recordings = 'Recordings',
}
export function matchesEditorVariantFilter(
    editorVariantFilter: EditorVariantFilter,
    project: Project,
    isRedesign: boolean = true,
) {
    switch (editorVariantFilter) {
        case EditorVariantFilter.Projects:
            if (isRedesign) {
                return project.editorVariant !== 'quick_recording' && project.lastViewedAt;
            }
            return project.editorVariant !== 'quick_recording';
        case EditorVariantFilter.Recordings:
            return project.editorVariant === 'quick_recording';
        default:
            return true;
    }
}

export function getTypedQueryFilter(
    queryFilterString: string | undefined,
): QueryFilter | undefined {
    switch (queryFilterString) {
        case AppConstants.ProjectBrowser.filterRecentProjects:
            return AppConstants.ProjectBrowser.filterRecentProjects;
        case AppConstants.ProjectBrowser.filterQuickRecordings:
            return AppConstants.ProjectBrowser.filterQuickRecordings;
        case AppConstants.ProjectBrowser.filterSharedWithMe:
            return AppConstants.ProjectBrowser.filterSharedWithMe;
        case AppConstants.ProjectBrowser.filterDownloaded:
            return AppConstants.ProjectBrowser.filterDownloaded;
        default:
            return undefined;
    }
}

export function getProjectFilterFromQuery(queryFilter: QueryFilter | undefined): ProjectFilter {
    switch (queryFilter) {
        case AppConstants.ProjectBrowser.filterSharedWithMe:
            return ProjectFilter.SharedWithMe;
        case AppConstants.ProjectBrowser.filterDownloaded:
            return ProjectFilter.Downloaded;
        case AppConstants.ProjectBrowser.filterRecentProjects:
        case AppConstants.ProjectBrowser.filterQuickRecordings:
        default:
            return ProjectFilter.All;
    }
}

/**
 * Note: ProjectFilter.All and EditorVariantFilter.All is a bad state, pointing to "Home" in the UI
 * This is because we don't have a valid UI state for showing both recent projects and recordings
 */
export function getEditorVariantFilterFromQuery(
    queryFilter: QueryFilter | undefined,
): EditorVariantFilter | undefined {
    switch (queryFilter) {
        case AppConstants.ProjectBrowser.filterRecentProjects:
            return EditorVariantFilter.Projects;
        case AppConstants.ProjectBrowser.filterQuickRecordings:
            return EditorVariantFilter.Recordings;
        /**
         * Shared With Me should always show both projects and recordings
         * Even though UI is nested under Projects
         */
        case AppConstants.ProjectBrowser.filterSharedWithMe:
            return EditorVariantFilter.All;
        /**
         * Downloaded could be either
         * Projects or Recordings if in Projects or Quick Recordings tab
         * All if on Workspaces tabs
         * So we don't set the editorVariantFilter here
         */
        case AppConstants.ProjectBrowser.filterDownloaded:
        default:
            return undefined;
    }
}

export function getQueryFilterFromProjectFilter(
    projectFilter: ProjectFilter,
): QueryFilter | undefined {
    switch (projectFilter) {
        case ProjectFilter.RecentProjects:
            return AppConstants.ProjectBrowser.filterRecentProjects;
        case ProjectFilter.QuickRecordings:
            return AppConstants.ProjectBrowser.filterQuickRecordings;
        case ProjectFilter.SharedWithMe:
            return AppConstants.ProjectBrowser.filterSharedWithMe;
        /**
         * Downloaded could be recent projects, quick recordings, or both
         * so similarly we cannot determine.
         * Also includes other ProjectFilters like Folder, Workspace, Tutorials, AISpeakers
         */
        case ProjectFilter.Downloaded:
        default:
            return undefined;
    }
}

export enum ProjectSubFilter {
    All = 'Recent',
    Downloaded = 'Downloaded',
    OwnedByMe = 'Owned by me',
}

export function matchesProjectSubFilter(
    projectSubFilter: ProjectSubFilter,
    project: Project,
    currentUser: User | undefined,
    downloaded: boolean,
) {
    const owner = project?.owner;
    switch (projectSubFilter) {
        case ProjectSubFilter.OwnedByMe:
            return owner && currentUser?.id === owner.id;
        case ProjectSubFilter.Downloaded:
            return downloaded;
        default:
            return true;
    }
}
