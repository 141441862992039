// Copyright 2024 Descript, Inc

import { DescriptError } from './DescriptError';
import { ErrorCategory } from './ErrorCategory';

export const TimedOutErrorName = 'TimedOut';
export const NetworkingErrorName = 'NetworkingError';
const NetworkErrorName = 'NetworkError';
// Error names from other APIs
const SimpleUrlLoaderErrorPrefix = 'net::';
const ForcedLogoutError = 'ForcedLogoutError';

export interface IErrorWithUnderlyingError {
    name: string;
    message: string;
    stack?: string;
    underlyingError: Error;
}

export function timedOut(): Error {
    const error = new DescriptError('Timed out', ErrorCategory.GlobalAssetSync);
    error.name = TimedOutErrorName;
    return error;
}

export function errorWithUnderlyingError(
    underlyingError: Error,
    name: string,
): IErrorWithUnderlyingError {
    const error: Partial<IErrorWithUnderlyingError> = new DescriptError(
        underlyingError.message,
        ErrorCategory.AppArchitecture,
    );
    error.name = name;
    error.underlyingError = underlyingError;
    error.stack = underlyingError.stack;
    return error as IErrorWithUnderlyingError;
}

export function isErrorWithUnderlyingError(e: unknown): e is IErrorWithUnderlyingError {
    const error = e as IErrorWithUnderlyingError;
    if (
        error instanceof Error &&
        error.name !== undefined &&
        error.message !== undefined &&
        error.underlyingError !== undefined
    ) {
        return true;
    }
    return false;
}

export function networkError(underlyingError: Error): IErrorWithUnderlyingError {
    return errorWithUnderlyingError(underlyingError, NetworkErrorName);
}

export class OfflineError extends Error {
    category = ErrorCategory.AppArchitecture;
    constructor(message: string, name: string = 'OfflineError') {
        super(message);
        this.name = name;
    }
}

export class RequestError extends Error {
    category = ErrorCategory.AppArchitecture;

    constructor(
        message: string,
        readonly statusCode: number,
        public readonly json: Readonly<{
            error?: string;
            message?: string;
            errorCode?: string;
            code?: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data?: { [key: string]: any };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            attributes?: { [key: string]: any };
        }> = {},
        public readonly method: string | undefined = undefined,
        public readonly path: string | undefined = undefined,
        public readonly query: Record<string, unknown> | undefined = undefined,
        public readonly requestId: string | undefined = undefined,
    ) {
        super(message);
        this.json = json;
        this.method = method;
        this.path = path;
        this.query = query;

        if (
            method !== undefined &&
            method.length > 0 &&
            path !== undefined &&
            path.length > 0
        ) {
            // Replace any UUIDs in the path with {id}
            const uuidRegex = /[a-f0-9-]{32,36}/gi;
            const statusCodeString = statusCode !== undefined ? `${statusCode} ` : '';
            const standardizedPath = path.replace(uuidRegex, '{id}');
            this.name = `RequestError - ${statusCodeString}${method}:${standardizedPath}`;
        } else {
            this.name = 'RequestError';
        }
    }
    getSentryContext() {
        return {
            id: this.requestId,
            path: this.path,
        };
    }
    isClientError() {
        return this.statusCode >= 400 && this.statusCode < 500;
    }
}

export function isNetworkError(error: {
    name?: unknown;
    code?: unknown;
    message?: unknown;
}): boolean {
    const { name, code, message } = error;
    return (
        name === NetworkErrorName ||
        name === NetworkingErrorName ||
        code === NetworkErrorName ||
        code === NetworkingErrorName ||
        name === 'OfflineError' ||
        name === 'FetchError' ||
        (name === 'TypeError' && message === 'Failed to fetch') ||
        (typeof name === 'string' && name.startsWith(SimpleUrlLoaderErrorPrefix)) ||
        (typeof code === 'string' && code.startsWith(SimpleUrlLoaderErrorPrefix)) ||
        (typeof message === 'string' && message.startsWith(SimpleUrlLoaderErrorPrefix)) ||
        error instanceof OfflineError
    );
}

export function isRequestError(e: Error): e is RequestError {
    return e instanceof RequestError;
}

export function isBadRequestError(error: Error): boolean {
    return (
        error.message === 'Bad Request' || (isRequestError(error) && error.statusCode === 400)
    );
}

export function isUnauthorizedError(error: Error): boolean {
    return (
        error.message === 'Unauthorized' ||
        (isRequestError(error) && error.statusCode === 401) ||
        ('code' in error && error.code === 'unauthorized') // emitted by trimerge-sync
    );
}

export function isNotFoundError(error: Error): boolean {
    return (
        error.message === 'Not Found' ||
        (isRequestError(error) && error.statusCode === 404) ||
        error.name === 'NotFoundError'
    );
}

export function isResizeObserverError(error: Error): boolean {
    return Boolean(
        error.message && error.message.indexOf('ResizeObserver loop limit exceeded') !== -1,
    );
}

export function isTooLargeError(error: Error): boolean {
    return isRequestError(error) && error.statusCode === 413;
}

export function isForbiddenError(error: Error): boolean {
    return error.message === 'Forbidden' || (isRequestError(error) && error.statusCode === 403);
}

export class RetryMaxReachedError extends RequestError {
    constructor(public readonly error: Error) {
        super('retryCount max reached: ' + error.message, 429);
        this.name = 'RetryMaxReachedError';
    }
}

export function forcedLogoutError(underlyingError: Error): IErrorWithUnderlyingError {
    return errorWithUnderlyingError(underlyingError, ForcedLogoutError);
}

export function isError(error: unknown): error is Error {
    return error instanceof Error;
}

export function isForcedLogoutError(error: unknown): boolean {
    if (!isError(error)) {
        return false;
    }
    if (error.name === ForcedLogoutError) {
        return true;
    }
    if (isErrorWithUnderlyingError(error)) {
        return isForcedLogoutError(error.underlyingError);
    }
    return false;
}

export class DisplayMessageError extends Error {
    category = ErrorCategory.AppArchitecture;
    readonly displayMessage: string;
    constructor(message: string) {
        super(message);
        this.displayMessage = message;
    }
}

export function isRateLimitError(error: Error): boolean {
    return isRequestError(error) && error.statusCode === 429;
}

export function isTransientError(error: Error): boolean {
    return isRequestError(error) && (error.statusCode >= 500 || error.statusCode === 429);
}

export function isResourceUnattainableError(error: RequestError): boolean {
    return error.statusCode === 404 || error.statusCode === 403;
}
