// Copyright 2022 Descript, Inc

import {
    DebugSettingsConstrainedValueType,
    DebugSettingsEntryValueType,
} from './DebugSettingsTypes';
import { IDebugSettings } from './IDebugSettings';
import { DescriptError, ErrorCategory } from '@descript/errors';

/**
 * static singleton instance
 */
let debugSettings: IDebugSettings | undefined;

/**
 * Sets the global backing instance for this session
 * @param instance - the singleton backing instance of `IDebugSettings`
 * @throws Error if `setInstance` has already been called
 */
export function setInstance(instance: IDebugSettings): void {
    if (getInstance()) {
        throw new DescriptError(
            'Debug Settings Instance already exists',
            ErrorCategory.AppArchitecture,
        );
    }
    debugSettings = instance;
}

/**
 * @returns the static singleton instance (if set) otherwise `undefined`
 */
export function getInstance(): Readonly<IDebugSettings> | undefined {
    return debugSettings;
}

/**
 * Convenience function for basic options
 * @returns the current value for `key`, otherwise `defaultValue` if `key` doesn't already exist.
 * If `key` didn't already exist, then the `defaultValue` is added into the database.
 *
 * Environments that do not have debug settings (i.e. production) will use `defaultValue`
 */
export function getValue<T extends DebugSettingsEntryValueType>(
    key: string,
    defaultValue: T,
    description?: string,
    constrainedValues?: DebugSettingsConstrainedValueType,
): T {
    return (
        (debugSettings?.get(key, defaultValue, description, constrainedValues) as T) ??
        defaultValue
    );
}

export { DEBUG_SETTINGS_SERIALIZED_VERSION_CURRENT as CurrentVersion } from './DebugSettingsTypes';
export type {
    DebugSettingsConstrainedValueType as ConstrainedValueType,
    DebugSettingsEntryType as EntryType,
    DebugSettingsEntryValueType as ValueType,
    DebugSettingsEnumValueType as EnumValueType,
    DebugSettingsRangeValueType as RangeValueType,
    SerializedDebugSettings as SerializedType,
    SerializedDebugSettingsBase as BaseSerializedType,
} from './DebugSettingsTypes';
