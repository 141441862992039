// Copyright 2024 Descript, Inc

import { addTrackListener, setAnalyticsLogger } from '@descript/analytics';
import { snakeCase } from 'snake-case';
import { createScopedLogger } from '@descript/descript-core';
import { MarketingCookies } from '../App/Constants/AppConstants';

export function setupAppAnalyticsLogging(): void {
    const analyticsLogger = createScopedLogger({
        name: 'ANALYTICS',
        color: '#7e20a5',
        enabledEnvs: ['staging'],
    });
    setAnalyticsLogger(analyticsLogger);

    if (DescriptFeatures.EVENT_ERRORS) {
        /**
         *  List of keys which are allowed to be non-snake_case
         *  (usually not to break existing dashboards)
         */
        const SNAKE_CASE_IGNORE_KEYS: ReadonlySet<string> = new Set([
            'hashCode',
            'existingData',
            // These all start with underscores, which is technically not snake case according to `snakeCase()`
            ...MarketingCookies.marketingCookies,
        ]);

        /**
         * List of events which are allowed to break snake case rule
         */
        const SNAKE_CASE_IGNORE_EVENTS: ReadonlySet<string> = new Set([
            // export has lots of snake case properties :D
            'ffmpeg_video_export',
            'ffmpeg_audio_export',
            'browser_video_export',
            'browser_audio_export',
            'filler_word_detection_completed', // used by filler word detection
        ]);

        /**
         * Checks that all keys in the object are in snake_case. If not, throws an error.
         */
        function checkSnakeCaseValues(
            event: string,
            properties?: Record<string, unknown>,
        ): void {
            if (!properties || SNAKE_CASE_IGNORE_EVENTS.has(event)) {
                return;
            }
            for (const key of Object.keys(properties)) {
                const keyStr = String(key);
                if (keyStr !== snakeCase(keyStr) && !SNAKE_CASE_IGNORE_KEYS.has(keyStr)) {
                    analyticsLogger.error(
                        `Event ${event} does not use snake_case properties for key ${keyStr}`,
                    );
                }
            }
        }

        addTrackListener(checkSnakeCaseValues);
    }
}
