// Copyright 2023 Descript, Inc
import { createScopedLogger } from '../createScopedLogger';

const logger = createScopedLogger({ name: 'CORE_LAZY' });

export function lazy<R>(fn: () => R): () => R {
    let value: R | undefined;

    return function (): R {
        if (value === undefined) {
            value = fn() as R;
        }
        if (value instanceof Promise) {
            value.catch((e) => {
                logger.error(e, { message: 'lazy() caught error' });
                value = undefined;
            });
        }
        return value;
    };
}
