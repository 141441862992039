// Copyright 2020 Descript, Inc

export function setIfChanging<T extends object, K extends keyof T>(
    item: T,
    key: K,
    value: T[K],
): T {
    if (value === item[key]) {
        return item;
    }
    return {
        ...item,
        [key]: value,
    };
}

export function deleteIfExists<T extends Record<string, unknown>, K extends string>(
    item: T,
    key: K,
): Omit<T, K> {
    if (!(key in item)) {
        return item;
    }

    const itemWithoutKey = { ...item };
    delete itemWithoutKey[key];

    return itemWithoutKey;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapObjectValuesIfChanging<T extends Record<string, any>, K extends keyof T>(
    obj: T,
    map: (key: K, value: T[K]) => T[K],
): T {
    let newObj: T | undefined;
    for (const [key, value] of Object.entries(obj)) {
        const newValue = map(key as K, value as T[K]);
        if (newValue !== value) {
            if (!newObj) {
                newObj = { ...obj };
            }
            newObj[key as K] = newValue;
        }
    }
    return newObj || obj;
}
