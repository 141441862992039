// Copyright 2024 Descript, Inc

type GanttOptions = {
    title?: string;
};

export class GanttTimer {
    private startTimes: { [key: string]: number } = {};
    private stopTimes: { [key: string]: number } = {};
    private baseTime: number | undefined;

    start(label: string) {
        const currentTime = performance.now();
        if (this.baseTime === undefined) {
            this.baseTime = currentTime; // Set the base time to the first start time
        }
        this.startTimes[label] = currentTime;
    }

    stop(label: string) {
        this.stopTimes[label] = performance.now();
    }

    gantt({ title = 'Task Timeline' }: GanttOptions = {}) {
        if (this.baseTime === undefined) {
            return 'No tasks have been started.';
        }

        let ganttChart =
            'gantt\n    title ' +
            title +
            '\n    dateFormat  x\n    axisFormat  %s.%L\n    section Tasks\n';

        for (const label in this.startTimes) {
            if (this.stopTimes[label]) {
                const start = (this.startTimes[label]! - this.baseTime).toFixed(0);
                const duration = (this.stopTimes[label] - this.startTimes[label]!).toFixed(0);
                ganttChart += `    ${label} : ${start}, ${duration}ms\n`;
            }
        }

        return ganttChart;
    }

    private static instances: Map<string, GanttTimer> = new Map();

    static getInstance(name: string) {
        const timer = GanttTimer.instances.get(name);

        if (timer) {
            return timer;
        }

        const newTimer = new GanttTimer();

        GanttTimer.instances.set(name, newTimer);

        return newTimer;
    }

    static clearInstance(name: string) {
        GanttTimer.instances.delete(name);
    }
}
