// Copyright 2024 Descript, Inc
import { isLocalStorageAvailable } from '@descript/client/src/App/WebAppLocalStorageSettings';
import { DebugSettings, DebugSettingsBase } from '@descript/descript-core';
import { DescriptError, ErrorCategory } from '@descript/errors';

export class DebugSettingsWeb extends DebugSettingsBase {
    private static instance: DebugSettingsWeb;

    private inMemorySettings: Record<
        string,
        {
            value: DebugSettings.ValueType;
            defaultValue?: DebugSettings.ValueType;
            description?: string;
            constrainedValues?: DebugSettings.ConstrainedValueType;
        }
    > = {};

    public static async initialize(): Promise<void> {
        if (DescriptFeatures.DEBUG_SETTINGS) {
            if (!DebugSettingsWeb.instance) {
                DebugSettingsWeb.instance = new DebugSettingsWeb();
                DebugSettings.setInstance(DebugSettingsWeb.instance);

                if (isLocalStorageAvailable) {
                    const keys = Object.keys(localStorage);
                    const settings: DebugSettings.SerializedType = {
                        version: DebugSettings.CurrentVersion,
                        data: {},
                    };

                    for (const key of keys) {
                        const value = localStorage.getItem(key);

                        if (key.startsWith('debug.') && value) {
                            settings.data[key.replace('debug.', '')] = JSON.parse(value);
                        }
                    }
                    this.instance.updateFromSerialized(settings);
                }
            } else {
                throw new DescriptError(
                    'DebugSettingsWeb already initialized',
                    ErrorCategory.AppArchitecture,
                );
            }
        } else {
            // NOP - not used in release builds until there's a need
            // any calls to get a value from the database will result in
            // the default value being returned
        }
    }

    private constructor() {
        super();
    }

    public override set(
        key: string,
        value: DebugSettings.ValueType,
        defaultValue?: DebugSettings.ValueType,
        description?: string,
        constrainedValues?: DebugSettings.ConstrainedValueType,
    ): void {
        if (isLocalStorageAvailable) {
            const oldValue = JSON.parse(localStorage.getItem(`debug.${key}`) || '{}');
            localStorage.setItem(
                `debug.${key}`,
                JSON.stringify({
                    value,
                    defaultValue: defaultValue ?? oldValue.defaultValue,
                    description: description ?? oldValue.description,
                    constrainedValues: constrainedValues ?? oldValue.constrainedValues,
                }),
            );
        } else {
            this.inMemorySettings[key] = {
                value,
                defaultValue: defaultValue ?? this.inMemorySettings[key]?.defaultValue,
                description: description ?? this.inMemorySettings[key]?.description,
                constrainedValues:
                    constrainedValues ?? this.inMemorySettings[key]?.constrainedValues,
            };
        }

        super.set(key, value, defaultValue, description, constrainedValues);
    }

    public override delete(key: string): boolean {
        if (isLocalStorageAvailable) {
            localStorage.removeItem(`debug.${key}`);
        } else {
            delete this.inMemorySettings[key];
        }

        return super.delete(key);
    }
}
