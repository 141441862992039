import { systemColors, theme } from './theme';
import type { Theme } from './types';

export const defaultLightTheme: Theme = {
    ...theme,
    colors: {
        accent: systemColors.descript.lightMode.grey[5],
        accentText: systemColors.descript.lightMode.grey[400],
        background: systemColors.descript.lightMode.grey[0],
        backgroundAccent: systemColors.descript.lightMode.grey[5],
        baseText: systemColors.descript.lightMode.grey[800],
        border: systemColors.descript.lightMode.grey[100],
        supportiveText: systemColors.descript.lightMode.grey[400],
        system: systemColors,
        roomBackground: '#FAF8F7',
    },
};
