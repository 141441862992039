// Copyright © 2024 Descript, Inc. All rights reserved.
import { setConfig } from './eventTracker';
import { addIntegration, captureException, configureScope, withScope } from '@sentry/core';
import { trackEventWeb } from './track';

export function configureDefaultErrorTracker(): void {
    setConfig(trackEventWeb, {
        configureScope,
        withScope,
        captureException,
        addIntegration,
    });
}
