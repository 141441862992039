// Copyright 2020 Descript, Inc

import * as ApiTarget from './ApiTarget';
import { AppConstants, Hosts, Routes } from '../App/Constants';
import { isDesktop2 } from '../Desktop2';
import { desktopToWebHost } from '../App/Constants/HostsConstants';
import { targetName } from './ApiTarget';
import { DescriptError, ErrorCategory } from '@descript/errors';

// API AUDIENCE
const prodApiAudience = 'https://api.descript.com';
const stagingApiAudience = 'https://staging-api.descript.com';
const devApiAudience = 'https://dev-api.descript.com';
const localApiAudience = 'https://api.descript.com';
// TENANT DOMAIN
const prodTenantBaseUrl = 'https://auth0.descript.com';
const stagingTenantBaseUrl = 'https://staging-auth0.descript.com';
const devTenantBaseUrl = 'https://dev-auth0.descript.com';
const localTenantBaseUrl = 'https://dev-auth0.descript.com';

// A working config for current client
interface Auth0AppConfig {
    apiAudience: string;
    baseUrl: string;
    clientId: string;
    authorizeCallback: string; // Make sure to register these in Auth0 application settings 'Allowed Callback URLs'
    logoutCallback: string; // Make sure to register these in Auth0 application settings 'Allowed Logout URLs'
    allowedUrls: string[];
}

interface Auth0Application {
    clientId: string;
    callbackBaseUrls: string[];
    callbackBaseUrlRegex?: RegExp;
}

interface Auth0Applications {
    electron: Auth0Application;
    desktop2: Auth0Application;
    descriptRecorder: Auth0Application;
    web: Auth0Application;
}

interface Auth0Connection {
    baseUrl: string;
}

interface Auth0Connections {
    hubSpot?: Auth0Connection;
}

interface Auth0Tenant {
    apiAudience: string;
    tenantBaseUrl: string;
    applications: Auth0Applications;
    connections: Auth0Connections;
}

interface Auth0Config {
    prod: Auth0Tenant;
    staging: Auth0Tenant;
    dev: Auth0Tenant;
    local: Auth0Tenant;
}

const localAuth0Config: Auth0Tenant = {
    apiAudience: localApiAudience,
    tenantBaseUrl: localTenantBaseUrl,
    applications: {
        electron: {
            clientId: 'O2mkI0ERTMYfOKTlNpDvcvy452t4m4XG',
            callbackBaseUrls: [AppConstants.electronAppScheme],
        },
        desktop2: {
            clientId: 'lx89B26wNp8PDBZgREkPtD1OaWG9OZUO',
            callbackBaseUrls: [AppConstants.desktop2AppScheme],
        },
        descriptRecorder: {
            clientId: 'M1HhUVBQcn2pu4Vdu9OGQ4sIIuOvSNGH',
            callbackBaseUrls: [AppConstants.descriptRecorderAppScheme],
        },
        web: {
            clientId: 't6TQ7BsnAgLFGqB4L268OjLWEqQr9kSb',
            callbackBaseUrls: [
                Hosts.devWeb,
                ...(process.env.DEV_SERVER_SSL ? [Hosts.secureDevWeb] : []),
            ],
        },
    },
    connections: {},
};
const config: Auth0Config = {
    prod: {
        apiAudience: prodApiAudience,
        tenantBaseUrl: prodTenantBaseUrl,
        applications: {
            electron: {
                clientId: '9nzV0ZyJrWMSk1KWTnSFc6jiXBjbVEjN',
                callbackBaseUrls: [AppConstants.electronAppScheme],
            },
            desktop2: {
                clientId: 'Hu6TVSKOIzlfyNBHLsodzv9Voe9x7ozh',
                callbackBaseUrls: [AppConstants.desktop2AppScheme],
            },
            descriptRecorder: {
                clientId: 'CsS65RXRZFgKp0UX3wnSP7xLwN8rKVsM',
                callbackBaseUrls: [AppConstants.descriptRecorderAppScheme],
            },
            web: {
                clientId: 'VDfu7rg4pdCELWsrQjcw2tG63a8Qlymi',
                callbackBaseUrls: [
                    Hosts.web,
                    Hosts.qaWeb,

                    Hosts.stagingWeb,
                    Hosts.staging2Web,
                    ...(process.env.DEV_SERVER_SSL ? [Hosts.secureDevWeb] : []),
                ],
                callbackBaseUrlRegex: /^https:\/\/.*\.preview-web\.descript\.com/,
            },
        },
        connections: {
            hubSpot: {
                baseUrl: 'https://hubspot.okta.com',
            },
        },
    },
    staging: {
        apiAudience: stagingApiAudience,
        tenantBaseUrl: stagingTenantBaseUrl,
        applications: {
            electron: {
                clientId: 'iQir5FOTtsBRcK477h0IOLZybOXKnjLZ',
                callbackBaseUrls: [AppConstants.electronAppScheme],
            },
            desktop2: {
                clientId: 'JSb7C5pxU3tLa2dD7RjZ8xxJnDmCrXmh',
                callbackBaseUrls: [AppConstants.desktop2AppScheme],
            },
            descriptRecorder: {
                clientId: 'NIAvV1Uu0gab9xogFv6UQq8bSDjX5LCr',
                callbackBaseUrls: [AppConstants.descriptRecorderAppScheme],
            },
            web: {
                clientId: 'gvKNJgtVjdLAOnRA7iiJQ5PJuQFXdy26',
                callbackBaseUrls: [
                    Hosts.stagingWeb,
                    Hosts.staging2Web,
                    Hosts.devWeb,
                    ...(process.env.DEV_SERVER_SSL ? [Hosts.secureDevWeb] : []),
                ],
                callbackBaseUrlRegex: /^https:\/\/.*\.preview-web\.descript\.com/,
            },
        },
        connections: {
            hubSpot: {
                baseUrl: 'https://hubspot.oktapreview.com',
            },
        },
    },
    dev: {
        ...localAuth0Config,
        apiAudience: devApiAudience,
        tenantBaseUrl: devTenantBaseUrl,
    },
    local: localAuth0Config,
};

export function getTenant(): Auth0Tenant {
    switch (ApiTarget.targetName()) {
        case 'production':
            return config.prod;
        case 'staging':
            return config.staging;
        case 'dev':
            return config.dev;
        case 'localhost-with-staging-data':
            return config.staging;
        case 'localhost':
            return config.local;
        default:
            throw new DescriptError(
                `No tenant for api target: ${ApiTarget.targetName()}`,
                ErrorCategory.Auth,
            );
    }
}

export function getApplication(tenant: Auth0Tenant): Auth0Application {
    if (isDesktop2) {
        return tenant.applications.desktop2;
    }
    if (process.env.IS_RECORDER) {
        return tenant.applications.descriptRecorder;
    }
    switch (process.env.PRODUCT) {
        case 'electron':
            return tenant.applications.electron;
        case 'web':
        case 'web-share':
            return tenant.applications.web;
        default:
            throw new DescriptError(
                `No application for product: ${process.env.PRODUCT}`,
                ErrorCategory.Auth,
            );
    }
}

export function getCallbackBaseUrl(): string {
    if (isDesktop2) {
        return AppConstants.desktop2AppScheme;
    }
    if (process.env.IS_RECORDER) {
        return AppConstants.descriptRecorderAppScheme;
    }
    switch (process.env.PRODUCT) {
        case 'electron':
            return AppConstants.electronAppScheme;
        case 'web':
            return window.location.origin;
        case 'web-embed':
        case 'web-share':
            // Web share redirects to web for auth handling
            return (
                window.location.origin
                    .replace(Hosts.webShare, Hosts.web)
                    .replace(Hosts.qaWebShare, Hosts.qaWeb)
                    .replace(Hosts.stagingWebShare, Hosts.stagingWeb)
                    .replace(Hosts.staging2WebShare, Hosts.staging2Web)
                    // The "web" client uses cookies (ApiTarget) to determine which API it points to,
                    // so for staging-api-share we should just redirect to staging-web
                    .replace(Hosts.stagingApiWebShare, Hosts.stagingWeb)
                    .replace(Hosts.devWebShare, Hosts.devWeb)
            );
        default:
            throw new DescriptError(
                `No callback base url for product: ${process.env.PRODUCT}`,
                ErrorCategory.Auth,
            );
    }
}

function getAuth0AppConfig(redirectToWebAfterAuth0 = false): Auth0AppConfig {
    const tenant = getTenant();
    const application = getApplication(tenant);

    // Callbacks must be registered in Auth0 application settings 'Allowed Callback URLs' and 'Allowed Logout URLs'
    // If source origin differs from callback origin, it must be registered as 'Allowed Origins (CORS)'
    const desiredCallbackBaseUrl = getCallbackBaseUrl();

    if (
        !application.callbackBaseUrls.includes(desiredCallbackBaseUrl) &&
        !(
            application.callbackBaseUrlRegex &&
            application.callbackBaseUrlRegex.test(desiredCallbackBaseUrl)
        )
    ) {
        throw new DescriptError(
            `Auth0 config for ${ApiTarget.prefix()}${
                process.env.PRODUCT
            } does not support redirecting to ${desiredCallbackBaseUrl}.`,
            ErrorCategory.Auth,
        );
    }
    const authorizeCallback = desiredCallbackBaseUrl + Routes.auth0AuthorizeCallback;
    const logoutCallback = desiredCallbackBaseUrl + Routes.auth0LogoutCallback;
    const webToDesktopRedirectUrl = `${getWebClientForRedirect()}${Routes.auth0AuthorizeCallback}`;
    const authorizeCallbackFromWebToDesktop: URL = new URL(webToDesktopRedirectUrl);
    authorizeCallbackFromWebToDesktop.searchParams.set(
        'returnTo',
        new URL(authorizeCallback).toString(),
    );

    const appConfig: Auth0AppConfig = {
        apiAudience: tenant.apiAudience,
        baseUrl: tenant.tenantBaseUrl,
        clientId: application.clientId,
        authorizeCallback: redirectToWebAfterAuth0
            ? authorizeCallbackFromWebToDesktop.toString()
            : authorizeCallback,
        logoutCallback,
        allowedUrls: [
            tenant.tenantBaseUrl,
            authorizeCallback,
            authorizeCallbackFromWebToDesktop.toString(),
            ...(tenant.connections.hubSpot ? [tenant.connections.hubSpot.baseUrl] : []),
        ],
    };
    return appConfig;
}

export function getWebClientForRedirect(): string {
    const target = targetName();

    if (target === 'localhost' && process.env.DEV_SERVER_SSL !== 'true') {
        return Hosts.devWeb;
    }
    return desktopToWebHost[target];
}

export default getAuth0AppConfig;
