import { DescriptError, ErrorCategory } from '@descript/errors';

// Copyright 2019 Descript, Inc

let apiTargetUpdater: (() => void) | undefined = undefined;

export function installApiTargetUpdater(updater: () => void) {
    apiTargetUpdater = updater;
}

export function updateApiTargetFromUserSetting() {
    if (apiTargetUpdater === undefined) {
        throw new DescriptError('No API target updater defined', ErrorCategory.AppArchitecture);
    }
    apiTargetUpdater();
}
