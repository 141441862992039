// Copyright 2020 Descript, Inc

import { Action } from 'redux';
import { ActionTypes } from './ActionTypes';
import {
    CompositionId,
    Dimensions,
    TimeInterval,
    Speaker,
    Point2D,
    TranscriptionLanguage,
} from '@descript/descript-model';
import {
    IExportSettings,
    RecordingConfigurationPreference,
    QuickRecordingMode,
    QuickRecordingAudioDevicePreference,
    TemplateBrowserSortState,
    PopoverWithDimensions,
    QuickRecordingCompleteAction,
} from '../Reducers/userPreferences';
import { ProjectFilterSettings } from '../Utilities/ProjectFilters';
import { actionCreatorFactory } from './async-action-factory';
import { ErrorCategory } from '@descript/errors';
import { SortState } from '../Components/Sidebar/Sorting';
import { ProjectType } from '../Utilities/DefaultProjectTypes';
import { VideoRecordingQuality } from '../Recording/types';
import { ColorScheme } from '@descript/react-utils';
import { IconSidebarStateActionArgs } from '../Components/Sidebar/StoryboardRemasteredSidebar/Sidebar/IconSidebarItemValue';
import { TemplateSectionType } from '../Components/Sidebar/StoryboardRemasteredSidebar/Templates/TemplateSectionTypes';
import { DefaultSpeaker } from '../Components/DefaultSpeakerSelect';

const actionCreator = actionCreatorFactory({
    category: ErrorCategory.EditorInteraction,
});

export const LoadUserPreferencesAction = actionCreator<{ skipUiSettings: boolean }>(
    'LOAD_USER_PREFERENCES',
);

export interface ISetPatientPlaybackAction extends Action {
    enabled: boolean;
}
export const SetPatientPlaybackAction = (enabled: boolean): ISetPatientPlaybackAction => ({
    type: ActionTypes.SET_PATIENT_PLAYBACK,
    enabled,
});

export interface IChangeTimeIntervalUserPreferenceAction extends Action {
    value: TimeInterval;
}

export const SetPatientPlaybackPauseDelayAction = (
    value: TimeInterval,
): IChangeTimeIntervalUserPreferenceAction => ({
    type: ActionTypes.SET_PATIENT_PLAYBACK_PAUSE_DELAY,
    value,
});

export const SetPatientPlaybackPauseDurationAction = (
    value: TimeInterval,
): IChangeTimeIntervalUserPreferenceAction => ({
    type: ActionTypes.SET_PATIENT_PLAYBACK_PAUSE_DURATION,
    value,
});

export interface ISetExportSettingsAction<T extends IExportSettings> extends Action {
    updatedSettings: Partial<T>;
}

export const ResetProjectViewAction = actionCreator('RESET_PROJECT_VIEW');

export const ShowVUMeterAction = actionCreator<{
    visible: boolean;
}>('SHOW_VU_METER_IN_TIMELINE');

export const RequestToggleTimelineAction = actionCreator<{
    visible: boolean;
    origin: 'keyboard' | 'menubar' | 'sidebar';
}>('REQUEST_TOGGLE_TIMELINE');
export const ToggleTimelineLegacyAction = actionCreator<{ visible: boolean }>(
    'TOGGLE_TIMELINE_LEGACY',
);

export const RequestToggleScriptAction = actionCreator<{
    visible: boolean;
    origin: 'keyboard' | 'menubar' | 'sidebar';
}>('REQUEST_TOGGLE_SCRIPT');
export const ToggleScriptAction = actionCreator<{ visible: boolean }>('TOGGLE_SCRIPT');

export const UpdateTimelineSizeAction = actionCreator<{
    size: number;
}>('UPDATE_TIMELINE_SIZE');

export const UpdateTimelineScriptSizeAction = actionCreator<{
    size: number;
    isStoryboard: boolean;
}>('UPDATE_TIMELINE_SCRIPT_SIZE');

export const UpdatePopoverDimensions = actionCreator<{
    dimensions: Dimensions;
    popover: PopoverWithDimensions;
}>('UPDATE_POPOVER_DIMENSIONS');

export const UpdatePopoverPosition = actionCreator<{
    position: Point2D;
    popover: PopoverWithDimensions;
}>('UPDATE_POPOVER_POSITION');

export const UpdateProjectPageSidebarSizeAction = actionCreator<number>(
    'UPDATE_PROJECT_PAGE_SIDEBAR_SIZE',
);

export const SetProjectSidebarCollapsedAction = actionCreator<boolean>(
    'SET_PROJECT_SIDEBAR_COLLAPSED',
);

export const UpdateCardRailSizeAction = actionCreator<{
    size: number;
}>('UPDATE_CARD_RAIL_SIZE');

export const UpdateVideoPaneSizeAction = actionCreator<{
    size: number;
}>('UPDATE_VIDEO_PANE_SIZE');

export type VideoPanePosition = 'top' | 'right';

export const UpdateVideoPanePositionAction = actionCreator<{ position: VideoPanePosition }>(
    'UPDATE_VIDEO_PANE_POSITION',
);

export const UpdateMediaLibraryInspectorSizeAction = actionCreator<{
    size: number;
}>('UPDATE_MEDIA_LIBRARY_INSPECTOR_SIZE');

export const UpdateMediaTreeSortAction = actionCreator<SortState>('UPDATE_MEDIA_TREE_SORT');
export const UpdateProjectTreeSortAction = actionCreator<SortState>('UPDATE_PROJECT_TREE_SORT');
export const UpdateTemplateBrowserSortAction = actionCreator<TemplateBrowserSortState>(
    'UPDATE_TEMPLATE_BROWSER_SORT',
);
export const UpdateTemplateBrowserFilterAction = actionCreator<TemplateSectionType>(
    'UPDATE_TEMPLATE_BROWSER_FILTER',
);

export const UpdateShowAllLayoutsAction = actionCreator<boolean>('UPDATE_SHOW_ALL_LAYOUTS');

export enum CopyDestType {
    NewComposition = 'new_composition',
    Composition = 'composition',
    Folder = 'folder',
    Clipboard = 'clipboard',
}

export enum CopyRangeType {
    Exact = 'exact',
    Sentence = 'sentence',
}

export const SetSearchCopyRangeType = actionCreator<{ rangeType: CopyRangeType }>(
    'SET_SEARCH_COPY_RANGE_TYPE',
);

export const ToggleSearchSpotAudition = actionCreator('TOGGLE_SPOT_AUDITION');

export const UpdateProjectFilterAction = actionCreator<{
    projectFilter: Partial<ProjectFilterSettings>;
}>('UPDATE_PROJECT_FILTER');

export const SetWordGapShortenDurationAction = actionCreator<{
    duration: number;
}>('SET_WORD_GAP_SHORTEN_DURATION');

export const SetPreserveGapsOnDeleteAction = actionCreator<{
    preserveGapsOnDelete: boolean;
}>('SET_PRESERVE_GAPS_ON_DELETE');

export const SetDriveIdForAdminPageAndProjectFilter = actionCreator<{
    driveId: string | undefined;
}>('SET_DRIVE_ID_FOR_ADMIN_PAGE_AND_PROJECT_FILTER');

export const SaveClassicRecordingInputPreferencesAction = actionCreator<
    RecordingConfigurationPreference | undefined
>('SAVE_CLASSIC_RECORDING_INPUT_PREFERENCES');

export const SetAutomaticallyTranscribeClassicRecordingsAction = actionCreator<{
    automaticallyTranscribe: boolean;
}>('SET_AUTOMATICALLY_TRANSCRIBE_CLASSIC_RECORDINGS');

export const SetShowClassicRecordingComputerAudioModalPreferenceAction = actionCreator<{
    showComputerAudioModal: boolean;
}>('SET_SHOW_CLASSIC_RECORDING_COMPUTER_AUDIO_MODAL_PREFERENCE');

export const SetShowAllVersionsSettingAction = actionCreator<{
    showAll: boolean;
}>('SET_SHOW_ALL_VERSIONS_SETTING');

export const SetShowAllTracksSettingAction = actionCreator<{
    showAll: boolean;
}>('SET_SHOW_ALL_TRACKS_SETTING');

export const SetDefaultAssetQualityAction = actionCreator<{
    useOriginal: boolean;
}>('SET_DEFAULT_ASSET_QUALITY');

export const SetDefaultUseRoomtoneOnGapGlipsAction = actionCreator<boolean>(
    'SET_DEFAULT_USE_ROOMTONE_ON_GAP_CLIPS',
);

export const SetDefaultAutomaticallyLevelClipsAction = actionCreator<boolean>(
    'SET_DEFAULT_AUTOMATICALLY_LEVEL_CLIPS',
);

export const SetAutoGenerateProxyAction = actionCreator<boolean>('SET_AUTO_GENERATE_PROXY');

export const SetOpenLinksInDesktopApp = actionCreator<boolean>('SET_OPEN_LINKS_IN_DESKTOP_APP');

export const PublishTypes = ['Audio', 'Video'] as const;
export type PublishType = (typeof PublishTypes)[number];
export const PublishTypeDefault: PublishType = 'Audio';

export const SetPublishTypeAction = actionCreator<{ publishType: PublishType }>(
    'SET_PUBLISH_TYPE',
);

export const SetCreateCompositionOnTranscriptionAction = actionCreator<{
    value: boolean;
}>('SET_CREATE_COMPOSITION_ON_TRANSCRIPTION');

export const SetShowRoomtoneModalAction = actionCreator<boolean>('SET_SHOW_ROOMTONE_MODAL');

export const SetShowVersion4OnboardingAction = actionCreator<boolean>(
    'SET_SHOW_VERSION_4_ONBOARDING',
);

export const DismissStoryboardNewCardOnboardingAction = actionCreator(
    'DISMISS_SHOW_STORYBOARD_NEW_CARD_ONBOARDING',
);

export const SetShowRoomtonePropertiesAction = actionCreator<boolean>(
    'SET_SHOW_ROOMTONE_PROPERTIES',
);

export const UpdateLastVersionChangelogViewedAction = actionCreator(
    'UPDATE_LAST_VERSION_CHANGELOG_VIEWED',
);

export const UpdateLastVoiceModelIdPromotedAction = actionCreator<{
    voiceModelId: string;
}>('UPDATE_LAST_VOICE_MODEL_ID_PROMOTED');

export const UpdateDriveViewDisplayAction = actionCreator<{
    value: 'grid' | 'list';
}>('UPDATE_DRIVE_VIEW_DISPLAY_PROMOTED');

export const SaveCompositionPlayheadAction = actionCreator<{
    projectId: string;
    compositionId: CompositionId;
}>('SAVE_COMPOSITION_PLAYHEAD');

export const SaveLastUsedTemplateAction = actionCreator<{
    projectId: string;
    templateProjectId: string;
}>('SAVE_LAST_USED_TEMPLATE');

export const RemoveStaleSavedProjectCompositions = actionCreator(
    'REMOVE_STALE_SAVED_PROJECT_COMPOSITIONS',
);

// NOTE: This enum can be removed after the legacy design is phased out.
// We're using a string enum here for backwards compatibility (so that if
// we revert this update in the future, we don't have the plain numeric enum
// values displayed in the header bar).
export enum LastUpdatedType {
    LastModified = 'Last modified',
    LastViewed = 'Last opened by me',
    // Legacy value - actual display value will be 'Last viewed' if redesign
}
export const lastUpdatedTypes = [LastUpdatedType.LastModified, LastUpdatedType.LastViewed];
export const LastUpdatedTypeDefault: LastUpdatedType = LastUpdatedType.LastViewed;
export const getDisplayLastUpdatedType = (
    lastUpdatedType: LastUpdatedType,
    isRedesign: boolean,
) => {
    switch (lastUpdatedType) {
        case LastUpdatedType.LastViewed:
            return isRedesign ? 'Last viewed' : 'Last opened by me';
        case LastUpdatedType.LastModified:
        default:
            return 'Last modified';
    }
};

export const getLastUpdatedTypeFromDisplay = (
    displayLastUpdatedType: string,
): LastUpdatedType => {
    switch (displayLastUpdatedType) {
        case 'Last viewed':
        case 'Last opened by me':
            return LastUpdatedType.LastViewed;
        case 'Last modified':
        default:
            return LastUpdatedType.LastModified;
    }
};

export const SetLastUpdatedTypeAction = actionCreator<{ lastUpdatedType: LastUpdatedType }>(
    'SET_LAST_UPDATED_TYPE',
);

export const RedeemWatermarkRemovalAction = actionCreator<{
    driveId: string;
    projectId: string;
    compositionId: CompositionId;
}>('REDEEM_WATERMARK_REMOVAL_ACTION');

export const TogglePublishedBrowserCardViewAction = actionCreator(
    'TOGGLE_PUBLISHED_BROWSER_CARD_VIEW',
);

export const ToggleScreenRecordingTranscriptionEnabled = actionCreator<boolean | void>(
    'TOGGLE_SCREEN_RECORDING_TRANSCRIPTION_ENABLED',
);

export const SetHighlightMouseClicksAction = actionCreator<boolean>(
    'SET_HIGHLIGHT_MOUSE_CLICKS',
);

export const SetRecordingCompleteActionAction = actionCreator<QuickRecordingCompleteAction>(
    'SET_RECORDING_COMPLETE_ACTION',
);

export const SetHideRecordingControlsAction = actionCreator<boolean>(
    'SET_HIDE_RECORDING_CONTROLS',
);

export const SetShowRegionRecordingConfirmationAction = actionCreator<boolean>(
    'SET_SHOW_REGION_RECORDING_CONFIRMATION',
);

export const SetScreenRecordingFlipCameraAction = actionCreator<{ flipCamera: boolean }>(
    'SET_SCREEN_RECORDING_FLIP_CAMERA',
);

export const SetKeepOpenOnQuitAction = actionCreator<{ keepOpenOnQuit: boolean }>(
    'SET_KEEP_OPEN_ON_QUIT',
);

export const SetLaunchAtLoginAction = actionCreator<{ launchAtLogin: boolean }>(
    'SET_LAUNCH_AT_LOGIN',
);

export const SetHideWindowsOnScreenRecordAction = actionCreator<boolean>(
    'HIDE_WINDOWS_ON_SCREEN_RECORD',
);

export const SetScreenRecordingQualityForDriveAction = actionCreator<{
    quality: VideoRecordingQuality;
    driveId: string;
}>('SET_SCREEN_RECORDING_QUALITY_FOR_DRIVE');

export const SetWebRecordingQualityForDriveAction = actionCreator<{
    quality: VideoRecordingQuality;
    driveId: string;
}>('SET_WEB_RECORDING_QUALITY_FOR_DRIVE');

export const RequestSetScreenRecordingShortcutAction = actionCreator<{
    shortcut: string | undefined;
    previousShortcut: string | undefined;
}>('REQUEST_SET_SCREEN_RECORDING_SHORTCUT');
export const SetScreenRecordingShortcutAction = actionCreator<{
    shortcut: string | undefined;
    previousShortcut: string | undefined;
}>('SET_SCREEN_RECORDING_SHORTCUT');

export const ToggleScreenRecordingAudioDeviceEnabledAction = actionCreator(
    'TOGGLE_SCREEN_RECORDING_AUDIO_DEVICE_ENABLED',
);

export const SetScreenRecordingAudioDeviceAction = actionCreator<{
    value: QuickRecordingAudioDevicePreference | undefined;
}>('SET_SCREEN_RECORDING_AUDIO_DEVICE');

export const ToggleScreenRecordingStudioSoundEnabledAction = actionCreator<boolean | void>(
    'TOGGLE_SCREEN_RECORDING_STUDIO_SOUND_ENABLED',
);

export const ToggleScreenRecordingCountdownEnabledAction = actionCreator<boolean | void>(
    'TOGGLE_SCREEN_RECORDING_COUNTDOWN_ENABLED',
);

export const ToggleScreenRecordingAllowScreenCaptureKitAction = actionCreator<boolean | void>(
    'TOGGLE_SCREEN_RECORDING_ALLOW_SCREEN_CAPTURE_KIT',
);

export type MacAudioDriverInstallationMethod = 'modal' | 'background';
export const ToggleQuickRecordingIncludeSystemAudioAction = actionCreator.async<
    {
        /**
         * The desired new enabled state for system audio.
         * If unspecified, the current value is toggled.
         */
        requestedValue?: boolean;
        /**
         * On Mac, an audio driver is required before recording computer audio.
         *
         * If `modal` is specified, a driver installation modal is spawned to give users context and allow them to
         * explicitly install the necessary driver.
         *
         * If `background` is specified, the installation will be triggered directly,
         * as if the user hit "install" in the modal.
         * This is used in the recording tray context where a dialog isn't feasible.
         */
        macAudioDriverInstallationMethod: MacAudioDriverInstallationMethod;
    },
    {
        /**
         * The user can deny the Mac driver installation, in which case resolvedValue would be `false`.
         */
        resolvedValue: boolean;
    },
    Error
>('TOGGLE_SCREEN_RECORDING_SYSTEM_AUDIO');

export const ToggleScreenRecordingVideoDeviceEnabledAction = actionCreator(
    'TOGGLE_SCREEN_RECORDING_VIDEO_DEVICE_ENABLED',
);

export const ToggleScreenRecordingMonitorEnabledAction = actionCreator(
    'TOGGLE_SCREEN_RECORDING_MONITOR_ENABLED',
);

export const SetScreenRecordingVideoDeviceAction = actionCreator<{
    value: string | undefined;
}>('SET_SCREEN_RECORDING_VIDEO_DEVICE');

export const SetScreenRecordingDriveAction = actionCreator<{
    driveId: string;
    previousDriveId: string | undefined;
}>('SET_SCREEN_RECORDING_DRIVE');

export const SetScreenRecordingModeAction = actionCreator<{
    mode: QuickRecordingMode;
}>('SET_SCREEN_RECORDING_MODE');

export const SetOpenWebpageAfterPublishAction = actionCreator<{
    enabled: boolean;
}>('SET_OPEN_WEBPAGE_AFTER_PUBLISH_ENABLED');

export const SetShowAuthorOnPublishAction = actionCreator<{
    enabled: boolean;
}>('SET_SHOW_AUTHOR_ON_PUBLISH_ENABLED');

export const SetEnableCommentsOnPublishAction = actionCreator<{
    enabled: boolean;
}>('SET_ENABLE_COMMENTS_ON_PUBLISH_ACTION');

export const SetQuickEditDefaultSpeakerAction = actionCreator<{
    defaultSpeaker: Speaker | undefined;
}>('SET_QUICK_EDIT_DEFAULT_SPEAKER');

export const SetApplySpeakerLabelToSingleParagraph = actionCreator<boolean>(
    'SET_APPLY_SPEAKER_LABEL_TO_SINGLE_PARAGRAPH',
);

export const SetLearningRailVideoWidth = actionCreator<{
    videoWidth: number;
}>('SET_LEARNING_RAIL_VIDEO_WIDTH');

export const RequestSetOfflineStorageLocation = actionCreator(
    'REQUEST_SET_OFFLINE_STORAGE_LOCATION',
);

export const GetOfflineStorageLocationOverrideAction = actionCreator.async<
    void,
    { path: string | undefined },
    Error
>('GET_OFFLINE_STORAGE_LOCATION_OVERRIDE');

export type SetDefaultTranscriptionLanguageEntryPoint =
    | 'pre_transcription_modal'
    | 'post_transcription_modal'
    | 'user_preferences';
export const SetDefaultTranscriptionLanguageAction = actionCreator<{
    language: TranscriptionLanguage;
    source: SetDefaultTranscriptionLanguageEntryPoint;
}>('SET_TRANSCRIPTION_LANGUAGE');

export const SetDefaultProjectTypeAction = actionCreator<{
    projectType: ProjectType;
}>('SET_DEFAULT_PROJECT_TYPE');

export const SetColorSchemeAction = actionCreator<{
    colorScheme: ColorScheme;
}>('SET_COLOR_SCHEME');

export type SetAlwaysPromptForTranscriptionEntryPoint =
    | 'pre_transcription_modal'
    | 'onboarding'
    | 'user_preferences';
export const SetAlwaysPromptForTranscriptionAction = actionCreator<{
    alwaysPrompt: boolean;
    source: SetAlwaysPromptForTranscriptionEntryPoint;
}>('SET_ALWAYS_PROMPT_FOR_TRANSCRIPTION');

export const SetPromptForSpeakerDetectionAction = actionCreator<{
    prompt: boolean;
}>('SET_PROMPT_FOR_SPEAKER_DETECTION');

export const SetDefaultSpeakerAction = actionCreator<{
    defaultSpeaker: DefaultSpeaker | undefined;
}>('SET_DEFAULT_SPEAKER_ACTION');

export const SetShowLiveTranscriptionUnavailableMessageAction = actionCreator<{
    show: boolean;
}>('SET_SHOW_LIVE_TRANSCRIPTION_UNAVAILABLE_MESSAGE');

export const ToggleSnapToGuidesAction = actionCreator('TOGGLE_SNAP_TO_GUIDES');

export const AddRecentlyUsedColorAction = actionCreator<number[]>('ADD_RECENTLY_USED_COLOR');

export const ToggleTimelineAction = actionCreator<{
    origin:
        | 'sidebar'
        | 'keyboard'
        | 'menubar'
        | 'button'
        | 'shortcut'
        | 'conductor'
        | 'add_animation'
        | 'edit_animation_keyframes';
}>('TOGGLE_TIMELINE');

export const SetTimelineVisibleAction = actionCreator<{
    visible: boolean;
}>('SET_TIMELINE_VISIBLE');

export const SetCompositionAreaPercentageAction = actionCreator<{
    percentage: number;
}>('SET_COMPOSITION_AREA_PERCENTAGE');

export const ResetTimelineAction = actionCreator('RESET_TIMELINE_ACTION');

export const SetSidebarStateAction =
    actionCreator<IconSidebarStateActionArgs>('SET_SIDEBAR_STATE');
export const SetProjectCheckedForLateRevisions = actionCreator<{
    projectId: string;
}>('SET_PROJECT_CHECKED_FOR_LATE_REVISIONS');
