// Copyright 2024 Descript, Inc
import * as React from 'react';

import { useTheme } from '../../contexts/Theme';
import { isStaging } from '../../lib/env';

const cDailyBasePath = `https://c.${isStaging() ? 'staging.' : ''}daily.co/static/assets`;

interface Props {
    background?: string;
    fontsBasePath?: string;
}

export function GlobalCSS({
    background,
    fontsBasePath = cDailyBasePath,
}: React.PropsWithChildren<Props>) {
    const { fontFamilies, lineHeights } = useTheme();

    return (
        <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>{`
                @font-face {
                    font-family: 'InputMonoRegular';
                    font-display: swap;
                    font-style: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    src:
                        url('${fontsBasePath}/InputMonoRegular.woff2') format('woff2'),
                        url('${fontsBasePath}/InputMonoRegular.woff') format('woff'),
                        url('${fontsBasePath}/InputMonoRegular.eot') format('eot');
                }
                @font-face {
                    font-family: 'GraphikRegular';
                    font-display: swap;
                    font-style: 'normal';
                    font-weight: 'normal';
                    font-stretch: 'normal';
                    src:
                        url('${fontsBasePath}/GraphikRegular.woff2') format('woff2'),
                        url('${fontsBasePath}/GraphikRegular.woff') format('woff'),
                        url('${fontsBasePath}/GraphikRegular.eot') format('eot');
                }
                @font-face {
                    font-family: 'GraphikRegularItalic';
                    font-display: swap;
                    font-style: 'normal';
                    font-weight: 'normal';
                    font-stretch: 'normal';
                    src:
                        url('${fontsBasePath}/GraphikRegularItalic.woff2') format('woff2'),
                        url('${fontsBasePath}/GraphikRegularItalic.woff') format('woff'),
                        url('${fontsBasePath}/GraphikRegularItalic.eot') format('eot');
                }
                @font-face {
                    font-family: 'GraphikMedium';
                    font-display: swap;
                    font-style: 'normal';
                    font-weight: 'normal';
                    font-stretch: 'normal';
                    src:
                        url('${fontsBasePath}/GraphikMedium.woff2') format('woff2'),
                        url('${fontsBasePath}/GraphikMedium.woff') format('woff'),
                        url('${fontsBasePath}/GraphikMedium.eot') format('eot');
                }
                @font-face {
                    font-family: 'GraphikMediumItalic';
                    font-display: swap;
                    font-style: 'normal';
                    font-weight: 'normal';
                    font-stretch: 'normal';
                    src:
                        url('${fontsBasePath}/GraphikMediumItalic.woff2') format('woff2'),
                        url('${fontsBasePath}/GraphikMediumItalic.woff') format('woff'),
                        url('${fontsBasePath}/GraphikMediumItalic.eot') format('eot');
                }
                @font-face {
                    font-family: 'GraphikSemibold';
                    font-display: swap;
                    font-style: 'normal';
                    font-weight: 'normal';
                    font-stretch: 'normal';
                    src:
                        url('${fontsBasePath}/GraphikSemibold.woff2') format('woff2'),
                        url('${fontsBasePath}/GraphikSemibold.woff') format('woff'),
                        url('${fontsBasePath}/GraphikSemibold.eot') format('eot');
                }
                @font-face {
                    font-family: 'GraphikSemiboldItalic';
                    font-display: swap;
                    font-style: 'normal';
                    font-weight: 'normal';
                    font-stretch: 'normal';
                    src:
                        url('${fontsBasePath}/GraphikSemiboldItalic.woff2') format('woff2'),
                        url('${fontsBasePath}/GraphikSemiboldItalic.woff') format('woff'),
                        url('${fontsBasePath}/GraphikSemiboldItalic.eot') format('eot');
                }

                *,
                *::before,
                *::after {
                    box-sizing: border-box;
                }

                :root {
                    --base-font-size: 12;
                }

                html {
                    font-size: 75%;
                    height: -webkit-fill-available;
                    margin: 0;
                }

                body {
                    background: var(--body-bg);
                    color: var(--body-color);
                    line-height: ${lineHeights.base};
                    margin: 0;
                    min-height: 100vh;
                    min-height: -webkit-fill-available;
                    overflow-x: hidden;
                    padding: 0;
                }
                .scroll-lock {
                    overflow: hidden;
                    padding-top: var(--header-height);
                }
                ul,
                ol {
                    list-style-position: outside;
                    margin: 0;
                    padding: 8px 0 8px 16px;
                    text-indent: 0;
                }
                @media screen and (max-device-width: 480px) {
                    body {
                        -webkit-text-size-adjust: none;
                    }
                }
            `}</style>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>
                {`
                    body {
                        ${background ? `background-color: ${background};` : ''}
                        font-family: ${fontFamilies.regular};
                    }
                `}
            </style>
        </>
    );
}
