// Copyright 2022 Descript, Inc

import { JSONValue } from '@descript/descript-core';
import { PromiseOrValue } from 'promise-or-value';

export interface IAppSettings {
    set: (key: string, value: AppSettingsValue) => void;
    get: (key: string) => AppSettingsValue;
    delete: (key: string) => void;
    getAll: () => Record<string, AppSettingsValue>;
    /** Loads all settings from the backing store */
    updateAll?: () => PromiseOrValue<void>;
    /**
     * Promise that resolves when settings are done processing, in the event that `set` or `delete`
     * trigger any async operations
     */
    onIdle?: () => Promise<void>;
}

export type AppSettingsValue = JSONValue;

export class AppSettings {
    static instance: IAppSettings;
    static authInstance: IAppSettings;

    static install(settings: IAppSettings, authSettings: IAppSettings) {
        AppSettings.instance = settings;
        AppSettings.authInstance = authSettings;
    }

    /**
     * Resolves when all settings have been persisted
     */
    static async onIdle(): Promise<void> {
        await this.instance.onIdle?.();
        await this.authInstance.onIdle?.();
    }
}

// Keys (or substrings of keys) that hold sensitive data
export const sensitiveAppSettings = [
    // Auth tokens (these can be prefixed with env, ex: staging-Auth0.accessToken)
    'Auth',
];
