import { systemColors, theme } from './theme';
import type { Theme } from './types';

export const defaultDarkTheme: Theme = {
    ...theme,
    colors: {
        accent: systemColors.descript.darkMode.blue[500],
        accentText: systemColors.descript.darkMode.grey[5],
        background: systemColors.descript.darkMode.grey[0],
        backgroundAccent: systemColors.descript.darkMode.grey[5],
        baseText: systemColors.descript.darkMode.grey[800],
        border: systemColors.descript.darkMode.grey[100],
        supportiveText: systemColors.descript.darkMode.grey[400],
        system: systemColors,
        roomBackground: systemColors.descript.darkMode.grey[5],
    },
};
