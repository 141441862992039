// Copyright 2022 Descript, Inc

import * as PlatformHelpers from '../platform/PlatformHelpers';
import { unUnderscored } from '../string/StringHelpers';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const oneFracDigitsFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
});

export const twoFracDigitsFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export function truncateZeros(formattedCurrency: string): string {
    return formattedCurrency.replace('.00', '');
}

export const EMAIL_REGEX = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}/i;

export const isValidEmail = (email: string): boolean => {
    return new RegExp(`^${EMAIL_REGEX.source}$`, 'i').test(email);
};

export const projectRouteRegex =
    /^#\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/;

/** Truncates a long string by reducing its contents in the end and replacing it with ellipses. */
export function truncateString(str: string, maxLength: number) {
    const ellipsis = '…';
    if (str.length > maxLength) {
        return `${str.slice(0, maxLength - ellipsis.length)}${ellipsis}`;
    }
    return str;
}

/**
 * Truncates a long string by reducing its contents in the middle and replacing it with ellipses.
 */
export const middleTruncateString = (str: string, maxLength: number): string => {
    if (str.length <= maxLength) {
        return str;
    }

    const ellipsis = '...';
    const lengthPerPart = (maxLength - ellipsis.length) / 2;
    return (
        str.slice(0, Math.floor(lengthPerPart)) +
        ellipsis +
        str.slice(Math.ceil(lengthPerPart) * -1)
    );
};

export const middleTruncateStringWidth = (
    str: string,
    maxPixelWidth: number,
    charPixelWidth: number,
): string => {
    const maxCharLength = maxPixelWidth / charPixelWidth;
    return middleTruncateString(str, maxCharLength);
};

export const addBusinessDaysToDate = (date: Date, businessDays: number): Date => {
    const _date = new Date(date.getTime());
    while (businessDays > 0) {
        _date.setDate(_date.getDate() + 1);
        switch (_date.getDay()) {
            case 0:
            case 6:
                break; // sunday & saturday
            default:
                businessDays--;
        }
    }
    return _date;
};

export const formatBitrateToKbps = (bitrate: number): string => {
    return `${Math.floor(bitrate / 1000)} kbps`;
};

export const formatSampleRateToKHz = (sampleRate: number): string => {
    return `${(sampleRate / 1000).toFixed(1)} kHz`;
};

export const metaSymbol = PlatformHelpers.isMac() ? '⌘' : 'Ctrl';
const ctrlSymbol = PlatformHelpers.isMac() ? '⌃' : 'Ctrl';
export const altSymbol = PlatformHelpers.isMac() ? '⌥' : PlatformHelpers.altKey();
export const shiftSymbol = PlatformHelpers.isMac() ? '⇧' : 'Shift';
export const enterSymbol = PlatformHelpers.isMac() ? '↩' : 'Enter';
const backspaceSymbol = PlatformHelpers.isMac() ? '⌫' : 'Backspace';
const deleteSymbol = PlatformHelpers.isMac() ? '⌦' : 'Del';
const leftArrow = '←';
const rightArrow = '→';
const upArrow = '↑';
const downArrow = '↓';
const plusSymbol = '+';

/**
 * Takes a shortcut string, splits it into individual keys, puts them in the right order based
 * on the operating system, and replaces specific parts with the correct symbol.
 */
function formatShortcutHelper(shortcut: string) {
    let hasMeta,
        hasAlt,
        hasShift,
        hasCtrl = false;
    const modifiers: string[] = [];
    const nonModifiers: string[] = [];

    shortcut
        .replace(' ', '+')
        .split('+')
        .map((part) => part.trim())
        .filter((part) => part.length > 0)
        .forEach((part) => {
            const lower = part.toLocaleLowerCase();
            switch (lower) {
                case 'cmd':
                case 'mod':
                case 'cmdorctrl':
                    hasMeta = true;
                    break;
                case 'option':
                case 'opt':
                case 'alt':
                    hasAlt = true;
                    break;
                case 'shift':
                    hasShift = true;
                    break;
                case 'ctrl':
                    hasCtrl = true;
                    break;
                case 'enter':
                case 'return':
                    nonModifiers.push(enterSymbol);
                    break;
                case 'backspace':
                    nonModifiers.push(backspaceSymbol);
                    break;
                case 'delete':
                case 'del':
                    nonModifiers.push(deleteSymbol);
                    break;
                case 'left':
                    nonModifiers.push(leftArrow);
                    break;
                case 'right':
                    nonModifiers.push(rightArrow);
                    break;
                case 'up':
                    nonModifiers.push(upArrow);
                    break;
                case 'down':
                    nonModifiers.push(downArrow);
                    break;
                case 'plus':
                    nonModifiers.push(plusSymbol);
                    break;
                default:
                    nonModifiers.push(
                        unUnderscored(lower, { capitalize: true, titlecase: true }),
                    );
                    break;
            }
        });

    // Make sure the modifier symbols appear in the same order as they do on the OS (which is
    // different than how they're defined in Electron).
    if (PlatformHelpers.isMac()) {
        if (hasCtrl) {
            modifiers.push(ctrlSymbol);
        }
        if (hasAlt) {
            modifiers.push(altSymbol);
        }
        if (hasShift) {
            modifiers.push(shiftSymbol);
        }
        if (hasMeta) {
            modifiers.push(metaSymbol);
        }
    } else {
        if (hasMeta) {
            modifiers.push(metaSymbol);
        }
        if (hasCtrl) {
            modifiers.push(ctrlSymbol);
        }
        if (hasAlt) {
            modifiers.push(altSymbol);
        }
        if (hasShift) {
            modifiers.push(shiftSymbol);
        }
    }
    return [...modifiers, ...nonModifiers];
}

export function formatShortcut(shortcuts: string | string[]): string {
    function format(shortcut: string) {
        const shortcutList = formatShortcutHelper(shortcut);
        return PlatformHelpers.isMac() ? shortcutList.join('') : shortcutList.join('+');
    }
    return typeof shortcuts === 'string' ? format(shortcuts) : shortcuts.map(format).join('/');
}

/**
 * Same as formatShortcut, but keeps it as a list.
 */
export function createShortcutList(shortcuts: string | string[]): string[] {
    return typeof shortcuts === 'string'
        ? formatShortcutHelper(shortcuts)
        : formatShortcutHelper(shortcuts.join('+'));
}

/**
 * Returns the next available unique name by appending incrementing numbers, ignoring case and
 * trimming whitespace.
 *
 * Given ["Mic"], the next available name for "Mic" would be "Mic (2)".
 * Given ["Mic", "Mic (2)", "MIC (3)"], the next available name for " Mic " would be "Mic (4)".
 *
 * @param name Base name to start with
 * @param existingNames List of existing names
 * @param withParentheses When true, returns the next name with parentheses (i.e. "Name (2)"").
 * When false, returns the next name without them (i.e. "Name 2").
 */
export function nextAvailableName(
    name: string,
    existingNames: string[],
    withParentheses: boolean = true,
): string {
    name = name.trim();
    existingNames = existingNames.map((existingName) =>
        existingName.trim().toLocaleLowerCase(),
    );
    let nextName = name;
    for (let counter = 2; ; counter++) {
        if (!existingNames.includes(nextName.toLocaleLowerCase())) {
            return nextName;
        }
        nextName = withParentheses ? `${name} (${counter})` : `${name} ${counter}`;
    }
}
