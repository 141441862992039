// Copyright 2022 Descript, Inc

/**
 * Clamps `num` to be within `min <= num <= max`
 * @param num - the number to clamp
 * @param min - the minimum bound
 * @param max - the maximum bound
 */
export function clamp(num: number, min: number, max: number): number {
    // Performs better than
    // Math.min(Math.max(num, min), max);
    return num < min ? min : max < num ? max : num;
}

/**
 * Clamps `num` to be within `min <= num <= max`,
 * but where either `min` or `max` can both be `undefined`
 * @param num - the number to clamp
 * @param min - the (optional) minimum bound
 * @param max - the (optional) maximum bound
 * @return `num` if `min` is `undefined` and `num < max`.
 *         `num` if `max` is `undefined` and `min < num`.
 *         `num` if both `min` and `max` are `undefined`.
 */
export function clampWithOptionalBounds(
    num: number,
    min: number | undefined,
    max: number | undefined,
): number {
    if (min !== undefined) {
        num = Math.max(min, num);
    }
    if (max !== undefined) {
        num = Math.min(max, num);
    }
    return num;
}
