// Copyright 2022 Descript, Inc

import { AppSettings, AppSettingsValue, IAppSettings } from './AppSettings';
import { clamp } from '@descript/descript-core';

export interface IUserSetting<T> {
    key: string;
    get: (settings?: IAppSettings) => T;
    set: (val: T, settings?: IAppSettings) => void;
    isSet: (settings?: IAppSettings) => boolean;
    getDefault: () => T;
    clear: (settings?: IAppSettings) => void;
}

export function isUserSetting(x: unknown): x is IUserSetting<AppSettingsValue> {
    const setting = x as IUserSetting<AppSettingsValue>;
    if (!setting || typeof setting !== 'object') {
        return false;
    }
    return Boolean(
        typeof setting.key === 'string' &&
            typeof setting.get === 'function' &&
            typeof setting.set === 'function' &&
            typeof setting.isSet === 'function' &&
            typeof setting.clear === 'function' &&
            typeof setting.getDefault === 'function',
    );
}

export interface INumericUserSetting extends IUserSetting<number> {
    applyBounds: (val: number) => number;
}

export interface IBooleanUserSetting extends IUserSetting<boolean> {
    toggle: (settings?: IAppSettings) => boolean;
}

export interface IAccountSetting<T> extends IUserSetting<T> {
    /**
     * Use this to prevent race conditions with login/logout
     *
     * e.g. to prevent mistakes like:
     *
     *     await getSomething(userId);
     *     // user logs out
     *     set(val); // Bug! This sets for the anonymous user
     */
    setForAccount: (userId: string, val: T, settings?: IAppSettings) => void;
    getForAccount: (userId: string, settings?: IAppSettings) => T | undefined;
}

export type IUserSettings<T extends object> = { [K in keyof T]: IUserSetting<T[K]> };

export function makeUserSetting<T extends AppSettingsValue | Record<string, unknown>>(
    key: string,
    defaultValue: T,
    getDefaultSettings?: () => IAppSettings,
): IUserSetting<T> {
    return {
        key,
        get: (settings = getDefaultSettings?.() || AppSettings.instance) => {
            const value = settings?.get(key) as T;
            return value === undefined ? defaultValue : value;
        },
        isSet: (settings = getDefaultSettings?.() || AppSettings.instance) => {
            return settings?.get(key) !== undefined;
        },
        set: (val, settings = getDefaultSettings?.() || AppSettings.instance) => {
            settings?.set(key, val as AppSettingsValue);
        },
        clear: (settings = getDefaultSettings?.() || AppSettings.instance) => {
            settings?.delete(key);
        },
        getDefault: () => {
            return defaultValue;
        },
    };
}

export function makeNumericUserSetting(
    key: string,
    defaultValue: number,
    minValue: number = -Infinity,
    maxValue: number = Infinity,
): INumericUserSetting {
    const applyBounds = (val: number = defaultValue) =>
        isNaN(val) ? defaultValue : clamp(val, minValue, maxValue);
    return {
        key,
        get: (settings = AppSettings.instance) => {
            return applyBounds(settings.get(key) as number);
        },
        isSet: (settings = AppSettings.instance) => {
            return settings?.get(key) !== undefined;
        },
        set: (val, settings = AppSettings.instance) => {
            settings.set(key, applyBounds(val));
        },
        clear: (settings = AppSettings.instance) => {
            settings.delete(key);
        },
        applyBounds,
        getDefault: () => {
            return defaultValue;
        },
    };
}

export function makeBooleanUserSetting(
    key: string,
    defaultValue: boolean,
): IBooleanUserSetting {
    const setting = makeUserSetting<boolean>(key, defaultValue);
    return {
        ...setting,
        get: (settings = AppSettings.instance) => {
            const value = settings.get(key);
            if (value === 'false') {
                return false;
            }
            return value === undefined ? defaultValue : Boolean(value);
        },
        toggle: (settings = AppSettings.instance) => {
            const toggledValue = !setting.get(settings);
            setting.set(toggledValue, settings);
            return toggledValue;
        },
    };
}
