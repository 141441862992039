// Copyright 2018 Descript, Inc

import { IAppSettings } from './AppSettings';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

if (process.env.NODE_ENV === 'test') {
    // @ts-expect-error  https://github.com/reactivestack/cookies/issues/113
    cookies.HAS_DOCUMENT_COOKIE = false;
}

function cookieDomain(): string {
    const { location } = document;
    if (!location) {
        return 'localhost';
    }
    const rootDomain = location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');
    if (rootDomain === 'localhost') {
        return rootDomain;
    }
    const full = '.' + rootDomain;
    return full;
}

function expirationDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 365);
    return date;
}

const WebAppCookieSettings: IAppSettings = {
    get: (key) => {
        return cookies.get(key);
    },
    set: (key, value) => {
        cookies.set(key, value, {
            domain: cookieDomain(),
            path: '/',
            expires: expirationDate(),
            secure: process.env.NODE_ENV === 'production',
        });
    },
    delete: (key) => {
        cookies.remove(key, {
            domain: cookieDomain(),
            path: '/',
            secure: process.env.NODE_ENV === 'production',
        });
    },
    getAll: () => {
        return cookies.getAll();
    },
};

export default WebAppCookieSettings;
