// Copyright 2024 Descript, Inc
import classNames from 'classnames';
import { useMemo } from 'react';
import * as React from 'react';

import { DARK_THEME_CLASS, LIGHT_THEME_OVERRIDE_CLASS, ThemeContext } from './ThemeProvider';
import { useTheme } from './useTheme';
import { DescriptThemeOption } from './types';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    theme: DescriptThemeOption;
}

export function ThemeScope({
    children,
    className,
    theme,
    ...attrs
}: React.PropsWithChildren<Props>) {
    const { isDarkMode, ...props } = useTheme();

    const darkMode = useMemo(() => {
        switch (theme) {
            case DescriptThemeOption.LIGHT:
                return false;
            case DescriptThemeOption.DARK:
                return true;
            case DescriptThemeOption.SYSTEM:
                return isDarkMode;
        }
    }, [isDarkMode, theme]);

    return (
        <ThemeContext.Provider
            value={{
                ...props,
                colors: darkMode ? props.darkColors : props.lightColors,
                isDarkMode: darkMode,
            }}
        >
            <div
                className={classNames(
                    darkMode ? DARK_THEME_CLASS : LIGHT_THEME_OVERRIDE_CLASS,
                    className,
                )}
                {...attrs}
            >
                {children}
            </div>
        </ThemeContext.Provider>
    );
}
