// Copyright 2024 Descript, Inc
/* eslint-disable no-restricted-syntax */

import { minimatch } from 'minimatch';
import * as DebugSettings from './debug/DebugSettings';
import { logging, json } from '@descript/std';
import { isBrowser } from './platform/PlatformHelpers';

// The way the types are exported results in errors packages that import this function
export type ConsoleLogInput = string | Error | undefined;
export type Attributes = json.JSONObject;
type EnabledEnv = 'staging' | 'release';

const buildType = process.env.REACT_APP_BUILD_TYPE ?? '';
const hasDebugSettingsEnabled =
    process.env.NODE_ENV === 'development' || buildType === 'staging';

function isBrowserMaybe() {
    try {
        return isBrowser();
    } catch {
        return false;
    }
}

const logFunc = Object.freeze({
    [logging.LogLevel.Debug]: console.debug,
    [logging.LogLevel.Info]: console.info,
    [logging.LogLevel.Warning]: console.warn,
    [logging.LogLevel.Error]: console.error,
    [logging.LogLevel.Critical]: console.error,
}) satisfies Record<logging.LogLevel, typeof console.log>;

/**
 * Create a logger that can be easily turned on/off during development.
 *
 * The `warn` and `error` methods are always enabled.
 *
 * There are two ways to enable/disable the loggers:
 *
 * 1. Set the `DESCRIPT_DEBUG` environment variable in your `.env` file. It is a comma-separated list of logger names.
 *    For example, to enable the `foo` and `bar` loggers, you would set `DESCRIPT_DEBUG=foo,bar`.
 *    You can also use glob patterns, like `DESCRIPT_DEBUG=MEDIA/*`.
 *
 * 2. Use the debug menu in the app to enable/disable the loggers. (This will only work for websites. If you're developing
 *    a desktop app, you'll need to use the `DESCRIPT_DEBUG` environment variable.)
 *
 * The `.env` file takes precedence over the in app debug menu. If you're using the `.env` the debug settings
 * will be reset to the value of the environment variable on startup. If you don't like that behavior, you can
 * set `DESCRIPT_DEBUG=` and only use the in app debug menu.
 *
 * > NOTE: `debug` logs are not included in the default logging levels in browsers.
 * > To enable them, you must set enabled `Verbose` logging in the console menu.
 *
 * @example No loggers
 * DESCRIPT_DEBUG=
 *
 * @example Enable all loggers
 * DESCRIPT_DEBUG=*
 *
 * @example Enable all loggers in the `MEDIA` namespace
 * DESCRIPT_DEBUG=MEDIA/*
 *
 * @example Enable the `foo` and `bar` loggers
 * DESCRIPT_DEBUG=foo,bar
 */
export function createScopedLogger({
    name,
    color = 'blue',
    alwaysEnabled = false,
    debugSettingName: debugSettingNameOverride,
    loggingAttributes,
    enabledEnvs = [],
}: {
    /**
     * The name of the logger. This is used for
     *
     * - The name of the console group
     * - The name you can filter the logs with
     * - The name of the debug setting that's created to enable/disable the logger at runtime
     */
    name: string;
    /**
     * An alternative name for the debug setting that's created to enable/disable the logger at runtime.
     */
    debugSettingName?: string;
    /** The color to print the first port of the log message in */
    color?: string;
    /**
     * Enable the logs for specific runtime environments
     *
     * @default ['staging', 'release']
     *
     * @example The default, logging enabled everywhere.
     * enabledEnvs = ['staging', 'release']
     *
     * @example Logging enabled only in staging
     * enabledEnvs = ['staging']
     *
     * @example Logging disabled everywhere. (except locally)
     * enabledEnvs = []
     */
    enabledEnvs?: Array<EnabledEnv>;
    /** Disregard the `DESCRIPT_DEBUG` environment variable and always enable the logs */
    alwaysEnabled?: boolean;
    loggingAttributes?: Attributes;
}) {
    const logger = logging.groupLogger(name, loggingAttributes);

    let hasReset = false;

    function format(...parts: unknown[]): unknown[] {
        let initial = `%c[${name}]`;
        let rest: unknown[] = [];
        for (let i = 0; i < parts.length; i++) {
            if (typeof parts[i] === 'string') {
                initial += ` ${parts[i]}`;
            } else {
                rest = parts.slice(i);
                break;
            }
        }
        return [initial, `color: ${color}`, ...rest];
    }

    function isEnabled() {
        if (alwaysEnabled) {
            return true;
        }

        if (enabledEnvs.includes(buildType as EnabledEnv)) {
            return true;
        }

        if (hasDebugSettingsEnabled) {
            const debugSettingName = debugSettingNameOverride || `Logging:${name}`;
            const filters = (process.env.DESCRIPT_DEBUG ?? '').split(',').filter(Boolean);
            const enabledViaFilter =
                filters.length === 0
                    ? false
                    : filters.includes(name) ||
                      filters.some((glob) => minimatch(name, glob === '*' ? '**/*' : glob));

            // During initialization, we need to reset the debug setting to the value of the environment variable
            if (!hasReset && filters.length > 0) {
                const settings = DebugSettings.getInstance();

                if (settings) {
                    settings.set(debugSettingName, enabledViaFilter, enabledViaFilter);
                    hasReset = true;
                    return enabledViaFilter;
                }
            }

            return DebugSettings.getValue<boolean>(debugSettingName, enabledViaFilter);
        }

        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function runIfEnabled<F extends (...args: any[]) => void>(fn: F) {
        if (hasDebugSettingsEnabled) {
            // During dev we check on every innovation to see if the logger is enabled so they can
            // be configured dynamically.
            return ((...args: unknown[]) => (isEnabled() ? fn(...args) : undefined)) as F;
        } else {
            // Otherwise only do the check once per app load.
            return (isEnabled() ? (...args: unknown[]) => fn(...args) : () => undefined) as F;
        }
    }

    // The logger was built for the backend and doesn't really have a nice browser presentation.
    // Once the repos are merged we can add this as an official formatter.
    if (isBrowserMaybe()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (logger as any).logger.logEntry = (
            level: logging.LogLevel,
            entry: logging.LogEntry,
        ) => {
            logFunc[level](
                ...format(entry.message, ...[entry.attrs, entry.error].filter(Boolean)),
            );
        };
    }

    return {
        /** Whether the log,debug,info,warn,event,error,critical,etc. methods are enabled */
        isEnabled,

        log: runIfEnabled((message: ConsoleLogInput, attrs?: Attributes) =>
            logger.info(message, attrs),
        ),
        debug: runIfEnabled((message: ConsoleLogInput, attrs?: Attributes) =>
            logger.debug(message, attrs),
        ),
        /**
         * The normal loggers accepts very strict types so the our logs are
         * formatted correctly on the backend and in our log viewers.
         *
         * This is a special case where we want to log some more complex JS objects
         * to the console. This is not a normal logger so we need to use console.debug
         * instead of logger.debug.
         *
         * Only use this if you really need to, prefer `logger.debug`.
         *
         * > NOTE: These logs will still show up in Sentry, but not be available if used in the
         * >       backend. A general rule of thumb is if you're writing code that will only ever
         * >       be used in the frontend, it's ok to use this method. If you're writing code
         * >       that will be used in the backend, use the normal loggers.
         */
        debugClient: runIfEnabled((...args: unknown[]) => console.debug(...format(...args))),

        // These are always enabled
        warn: (message: ConsoleLogInput, attrs?: Attributes) => logger.warn(message, attrs),
        error: (message: ConsoleLogInput, attrs?: Attributes) => logger.error(message, attrs),
        critical: (message: ConsoleLogInput, attrs?: Attributes) =>
            logger.critical(message, attrs),

        group: (message?: string) =>
            console.group(`%c[${name} GROUP] ${message || ''}`, `color: ${color}`),
        groupEnd: () => console.groupEnd(),
    };
}

export type ScopedLogger = ReturnType<typeof createScopedLogger>;
