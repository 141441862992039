// Copyright 2023 Descript, Inc

import { Scope, Integration } from '@sentry/types';
import { invariant, ErrorCategory } from '@descript/errors';
import { TrackConsoleErrorIntegration } from './Sentry/Integrations/TrackConsoleErrorIntegration';
import { IgnoredEventsIntegration } from './Sentry/Integrations/IgnoredEventsIntegration';
import { UnhandledCategoryIntegration } from './Sentry/Integrations/UnhandledCategoryIntegration';

export type TrackFnProperties = Record<string, unknown>;
export type TrackFn = (event: string, properties?: TrackFnProperties) => void;
type SentryLike = {
    configureScope(callback: (scope: Scope) => void): void;
    withScope(callback: (scope: Scope) => void): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    captureException(exception: any): string;
    addIntegration(integration: Integration): void;
};

export const config: {
    trackFn: TrackFn | undefined;
    sentry: SentryLike | undefined;
} = {
    trackFn: undefined,
    sentry: undefined,
};

export function setConfig(_trackFn: TrackFn, _sentry: SentryLike): void {
    invariant(_trackFn, 'trackFn is required', ErrorCategory.AppArchitecture);
    invariant(_sentry, 'sentry is required', ErrorCategory.AppArchitecture);

    // Make sure we only install the integration once per sentry instance
    if (config.sentry !== _sentry) {
        _sentry.addIntegration(new UnhandledCategoryIntegration());
        _sentry.addIntegration(new IgnoredEventsIntegration());
        _sentry.addIntegration(new TrackConsoleErrorIntegration(config));
    }

    config.trackFn = _trackFn;
    config.sentry = _sentry;
}
