// Copyright 2022 Descript, Inc

import { DescriptError, ErrorCategory } from '@descript/errors';

/**
 * The Gotoh algorithm[1] is an extension of Needleman-Wunsch[2] that allows us to define an affine gap penalty.
 * That means, by changing the weights, we can bias the sequence alignment to create fewer, larger gaps rather than
 * more, smaller gaps. This is important because, e.g., we want to be able to accommodate for adding or removing
 * entire sentences/paragraphs as cleanly as possible.
 *
 * - `matchScore`: should be positive when the items match and negative when they don't
 * - `gapStart`: the score of opening a new gap. If `gapStart = 0`, Gotoh will become Needleman-Wunsch.
 * - `gapExtend`: the score of adding an item to a gap (including the first item of a gap).
 *
 * [1] http://rna.informatik.uni-freiburg.de/Teaching/index.jsp?toolName=Gotoh
 * [2] https://en.wikipedia.org/wiki/Needleman%E2%80%93Wunsch_algorithm
 */

export type GotohParameters<T> = {
    matchScore: (x: T, y: T) => number;
    gapStart: number;
    gapExtend: number;
};

// Smaller scores => worse alignment
type GotohMatrices = {
    /** Score of alignment between substrings a[:i] and b[:j] */
    M: number[][];
    /** Score for a gap in a for substrings a[:i] and b[:j] */
    X: number[][];
    /** Score for a gap in b for substrings a[:i] and b[:j] */
    Y: number[][];
};

/**
 * An array of coordinates. Coord [x, y] is interpreted as:
 * - if x === -1: yth item of the second sequence is aligned to a gap
 * - if y === -1: xth item of the first sequence is aligned to a gap
 * - else: xth item of the first sequence is aligned to yth item of the second sequence
 */
type SequenceAlignment = [number, number][];

function makeMatrix(rows: number, columns: number): number[][] {
    const arr = new Array(rows).fill(undefined);
    for (let i = 0; i < rows; i++) {
        arr[i] = new Array(columns).fill(0);
    }
    return arr;
}

/**
 * Compute the three matrices for the Gotoh algorithm given two sequences `a` and `b`
 * and cost parameters
 */
export function computeGotohMatrices<T>(
    a: readonly T[],
    b: readonly T[],
    { gapExtend, gapStart, matchScore }: GotohParameters<T>,
): GotohMatrices {
    const aLen = a.length;
    const bLen = b.length;
    const gapStartAndExtend = gapStart + gapExtend;

    // Setup the matrices that we're going to populate
    const M = makeMatrix(aLen + 1, bLen + 1);
    const X = makeMatrix(aLen + 1, bLen + 1);
    const Y = makeMatrix(aLen + 1, bLen + 1);

    for (let i = 1; i < aLen + 1; i++) {
        M[i]![0] = gapStart + i * gapExtend;
    }
    for (let j = 1; j < bLen + 1; j++) {
        M[0]![j] = gapStart + j * gapExtend;
    }

    X[0]!.fill(-Infinity);
    Y[0]!.fill(-Infinity);
    for (let i = 0; i < aLen + 1; i++) {
        X[i]![0] = -Infinity;
        Y[i]![0] = -Infinity;
    }

    for (let i = 1; i < aLen + 1; i++) {
        // optimization: fetch from arrays once if going to need the results for every j
        const Mi = M[i]!;
        const Xi = X[i]!;
        const Yi = Y[i]!;
        const Mi1 = M[i - 1]!;
        const Yi1 = Y[i - 1]!;
        const ai1 = a[i - 1]!;
        for (let j = 1; j < bLen + 1; j++) {
            // See recurrences as defined here: http://rna.informatik.uni-freiburg.de/Teaching/index.jsp?toolName=Gotoh

            // gap in A
            Xi[j] = Math.max(Mi[j - 1]! + gapStartAndExtend, Xi[j - 1]! + gapExtend);

            // gap in B
            Yi[j] = Math.max(Mi1[j]! + gapStartAndExtend, Yi1[j]! + gapExtend);

            // match between A and B
            Mi[j] = Math.max(Mi1[j - 1]! + matchScore(ai1, b[j - 1]!), Xi[j]!, Yi[j]!);
        }
    }

    return { M, X, Y };
}

const enum GotohMatrix {
    M = 'm',
    X = 'x',
    Y = 'y',
}

/**
 * Generates the sequence alignment given the three computed Gotoh matrices
 */
export function gotohAlignment<T>(
    a: readonly T[],
    b: readonly T[],
    { gapExtend, gapStart, matchScore }: GotohParameters<T>,
    { M, X, Y }: GotohMatrices,
): SequenceAlignment {
    let i = M.length - 1;
    let j = M[0]!.length - 1;
    let currentMat = GotohMatrix.M;

    const seqAlignment: SequenceAlignment = [];

    const gapStartAndExtend = gapStart + gapExtend;

    while (i > 0 || j > 0) {
        // base case: in first row
        if (i === 0) {
            seqAlignment.push([-1, j - 1]);
            j -= 1;
            continue;
        }
        // base case: in first column
        if (j === 0) {
            seqAlignment.push([i - 1, -1]);
            i -= 1;
            continue;
        }

        // find the recurrence that generated the current value
        switch (currentMat) {
            case GotohMatrix.M: {
                // Do we want M, X, or Y to take precedence here?
                // They will both return an optimal solution, but the selected path will be
                // different if there are multiple optimal solutions.

                // For now, prefer M to X to Y

                const val = M[i]![j]!;

                const fromM = M[i - 1]![j - 1]! + matchScore(a[i - 1]!, b[j - 1]!);
                if (fromM === val) {
                    seqAlignment.push([i - 1, j - 1]);
                    currentMat = GotohMatrix.M;
                    i -= 1;
                    j -= 1;
                    continue;
                }

                const fromX = X[i]![j];
                if (fromX === val) {
                    currentMat = GotohMatrix.X;
                    continue;
                }

                const fromY = Y[i]![j];
                if (fromY === val) {
                    currentMat = GotohMatrix.Y;
                    continue;
                }

                throw new DescriptError('invalid matrices', ErrorCategory.Alignment);
            }
            case GotohMatrix.X: {
                const val = X[i]![j];

                const mVal = M[i]![j - 1]! + gapStartAndExtend;
                if (mVal === val) {
                    seqAlignment.push([-1, j - 1]);
                    currentMat = GotohMatrix.M;
                    j -= 1;
                    continue;
                }

                const xVal = X[i]![j - 1]! + gapExtend;
                if (xVal === val) {
                    seqAlignment.push([-1, j - 1]);
                    currentMat = GotohMatrix.X;
                    j -= 1;
                    continue;
                }

                throw new DescriptError('invalid matrices', ErrorCategory.Alignment);
            }
            case GotohMatrix.Y: {
                const val = Y[i]![j];

                const mVal = M[i - 1]![j]! + gapStartAndExtend;
                if (mVal === val) {
                    seqAlignment.push([i - 1, -1]);
                    currentMat = GotohMatrix.M;
                    i -= 1;
                    continue;
                }

                const yVal = Y[i - 1]![j]! + gapExtend;
                if (yVal === val) {
                    seqAlignment.push([i - 1, -1]);
                    currentMat = GotohMatrix.Y;
                    i -= 1;
                    continue;
                }

                throw new DescriptError('invalid matrices', ErrorCategory.Alignment);
            }
            default: {
                throw new DescriptError(
                    `Invalid currentMat: ${currentMat}`,
                    ErrorCategory.Alignment,
                );
            }
        }
    }

    return seqAlignment.reverse();
}

/**
 * Generates a string representation of the sequence alignment, following the style here:
 * http://rna.informatik.uni-freiburg.de/Teaching/index.jsp?toolName=Gotoh
 *
 * _ => gap
 * * => exact match
 * | => aligned, but not exact match
 *
 * e.g.,
 *
 *     C_AT
 *     * *|
 *     CHAD
 */
export function sequenceAlignmentString(
    a: readonly string[],
    b: readonly string[],
    seqAlignment: Readonly<SequenceAlignment>,
): string {
    return [
        seqAlignment.map(([i]) => (i === -1 ? '_' : a[i])).join(''),
        seqAlignment
            .map(([i, j]) => (i === -1 || j === -1 ? ' ' : a[i] === b[j] ? '*' : '|'))
            .join(''),
        seqAlignment.map(([_, j]) => (j === -1 ? '_' : b[j])).join(''),
    ].join('\n');
}
