export const hexToRgba = (hex: string, alpha: number = 1) => {
    if (hex.startsWith('rgb')) {
        return hex;
    }

    const [r, g, b] = hex
        .match(/#?(\w\w?)(\w\w?)(\w\w?)$/)
        .slice(1, 4)
        .map((h) => parseInt(h, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};
