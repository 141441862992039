// Copyright 2024 Descript, Inc

import type { Event, Integration } from '@sentry/types';
import { getTeamData, Team } from '@descript/descript-core';
import { ErrorCategory } from '@descript/errors';

import { getErrorMessageFromEvent } from './getErrorFromEvent';
import type { TrackFn } from '../../eventTracker';

/**
 * Extract data from Sentry errors and call an injected tracking function.
 */
export class TrackConsoleErrorIntegration implements Integration {
    name = 'descript.trackConsoleError';

    constructor(private readonly config: { trackFn?: TrackFn }) {
        // no-op
    }

    setupOnce(): void {
        // no-op; will become optional w/ Sentry 8
    }

    private readonly MAX_ERRORS_PER_TYPE = 15;
    private readonly errorCounts: Record<string, number> = {};
    private readonly trackedLevels = new Set(['error', 'fatal']);

    async processEvent(event: Event): Promise<Event | null> {
        const tags = event.tags ?? {};

        if (tags['event.process'] === 'renderer') {
            /**
             * Special case for sentry/electron. Errors in renderer are sent to main process
             * and main sends them to sentry. We don't want to track these errors twice.
             * We have more information for the console_error on the renderer process.
             * sentry/electron adds a tag 'event.process' to the event to indicate the process
             * once the event has been sent from renderer to main. So if that tag exists,
             * we know we have already sent the console_error from the renderer process.
             *
             * Note: if we were to set `getRendererName()` we'd have to change this logic
             * https://github.com/getsentry/sentry-electron/blob/6f6b4563043564d4f62102ea49f1253046c7b4fc/src/main/ipc.ts#L67
             */
            return event;
        }

        const type = tags['error-type'];

        let errorCount = undefined;
        if (typeof type === 'string') {
            errorCount = (this.errorCounts[type] || 0) + 1;
            this.errorCounts[type] = errorCount;
        }

        if (
            this.config.trackFn &&
            (errorCount === undefined || errorCount <= this.MAX_ERRORS_PER_TYPE) &&
            this.trackedLevels.has(event.level ?? 'error')
        ) {
            const error = await getErrorMessageFromEvent(event);

            this.config.trackFn('console_error', {
                sentry_id: event.event_id,
                project_id: tags['project-id'],
                track_id: tags['track-id'],
                drive_id: tags['drive-id'],
                type,
                name: error?.name,
                message: error?.message,
                event_origin: tags['event-origin'],
                response_message: event.contexts?.['Request Error']?.response_message,
                error_category: tags['error-category'] ?? ErrorCategory.UnhandledException,
                eng_team: tags['eng-team'] ?? getTeamData(Team.ClientPlatform).name,
                screen_recorder_version:
                    event.contexts?.['Screen Recorder Version']?.screen_recorder_version,
            });
        }

        return event;
    }
}
