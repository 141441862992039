// Copyright 2019 Descript, Inc

import { fastDeepEqual } from '@descript/fast-deep-equal';
import { JSONObject } from '@descript/descript-core';
import { getAnalytics, initAnalytics } from './initAnalytics';
import { AnalyticsProperties } from './properties';
import { getAnalyticsLogger } from './analyticsLogger';
import { getTrackListeners } from './trackListeners';
import { DescriptError, ErrorCategory } from '@descript/errors';

export type AnalyticsEventParametersDictionary = {
    [name: string]: string | number | boolean | undefined;
};
export type AnalyticsEvent = {
    name: string;
    parameters?: AnalyticsEventParametersDictionary;
};

function stripUndefinedValuesShallow(obj: Record<string, unknown>): JSONObject {
    const newObj: Record<string, unknown> = {};
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    }
    return newObj as JSONObject;
}

function checkAnonymousId() {
    const logger = getAnalyticsLogger();
    if (logger) {
        const analytics = getAnalytics();
        const user = analytics?.user?.();
        if (!user) {
            getAnalyticsLogger()?.warn('no analytics user');
            return;
        }
        const anonymousId = user.anonymousId?.();
        if (!anonymousId) {
            getAnalyticsLogger()?.warn('no anonymous id');
            return;
        }
        if (anonymousId !== AnalyticsProperties.installId) {
            getAnalyticsLogger()?.error('anonymous id does not match install id', {
                anonymousId: anonymousId,
                installId: AnalyticsProperties.installId,
            });
        }
    }
}

let sessionId = Date.now();
let lastTime = Date.now();
function getSessionId(): number {
    const now = Date.now();
    if (now - lastTime > 30 * 60 * 1000) {
        // new session after 30 minutes of inactivity
        sessionId = now;
    }
    lastTime = now;
    return sessionId;
}

export function getIntegrations(): JSONObject {
    return {
        Amplitude: {
            session_id: getSessionId(),
        },
    };
}

// we're setting the anonymous id on ready in SegmentAnalytics.ts
// and we don't want to fire any events until then
let analyticsInitialized = false;
let analyticsInitializedWithRealUser = false;

export function areAnalyticsInitialized(): boolean {
    return analyticsInitialized;
}

export function areAnalyticsInitializedWithRealUser(): boolean {
    return analyticsInitializedWithRealUser;
}

const analyticsInitQueue: (() => void)[] = [];

function flushAnalyticsInitQueue() {
    getAnalyticsLogger()?.debug(`done waiting for load, flushing queued analytics calls`);
    let fn: (() => void) | undefined;
    while ((fn = analyticsInitQueue.shift())) {
        fn();
    }
}

async function onAnalyticsInitialized(fn: () => void): Promise<void> {
    getAnalyticsLogger()?.debug(`waiting for load`);
    analyticsInitQueue.push(fn);
    await initAnalytics(
        AnalyticsProperties.installId,
        AnalyticsProperties.getPageProperties?.(),
    );
    analyticsInitialized = true;
    flushAnalyticsInitQueue();
}

const analyticsInitUserQueue: (() => void)[] = [];

function flushAnalyticsInitUserQueue() {
    getAnalyticsLogger()?.debug(`done waiting for user, flushing queued analytics calls`);
    let fn: (() => void) | undefined;
    while ((fn = analyticsInitUserQueue.shift())) {
        fn();
    }
}

export async function onAnalyticsUserInitialized(fn: () => void): Promise<void> {
    if (analyticsInitializedWithRealUser) {
        fn();
        return;
    }

    getAnalyticsLogger()?.debug(`waiting for user`);

    analyticsInitUserQueue.push(fn);
}

let scheduledIdentify: { userId: string | undefined; traits: JSONObject } | undefined;

/**
 * Schedules an `identify` call for after analytics have been initialized.
 * Will merge identify calls for the same user id if others have been scheduled already.
 */
async function scheduleTraitsForInitialIdentify(
    userId: string | undefined,
    traits: JSONObject,
) {
    if (
        scheduledIdentify &&
        (scheduledIdentify.userId === userId ||
            scheduledIdentify.userId === undefined ||
            userId === undefined)
    ) {
        getAnalyticsLogger()?.debug(
            `merging scheduled identify call for ${userId ?? scheduledIdentify.userId}`,
        );
        scheduledIdentify = {
            userId: userId ?? scheduledIdentify.userId,
            traits: {
                ...scheduledIdentify.traits,
                ...traits,
            },
        };
    } else {
        scheduledIdentify = { userId, traits };
        await onAnalyticsInitialized(() => {
            if (!scheduledIdentify) {
                getAnalyticsLogger()?.warn('No traits stored for identify', { userId: userId });
                return;
            }
            execIdentify(scheduledIdentify.userId, scheduledIdentify.traits);
            scheduledIdentify = undefined;
        });
    }
}

let mergedIdentifyPayload: {
    userId: string | undefined;
    anonId: string | undefined;
    traits: JSONObject;
    integrations: JSONObject;
    context: JSONObject;
} = {
    userId: undefined,
    anonId: undefined,
    traits: {},
    integrations: {},
    context: {},
};

let didIdentify = false;
function execIdentify(userId: string | undefined, traits: JSONObject): void {
    const analytics = getAnalytics();
    // only call identify if we haven't identified yet
    let shouldIdentify = !didIdentify;

    // or if the traits have changed
    shouldIdentify ||= Object.entries(traits).some(
        ([key, value]) => !fastDeepEqual(mergedIdentifyPayload.traits[key], value),
    );

    // or if integrations have changed
    const integrations = getIntegrations();
    shouldIdentify ||= !fastDeepEqual(integrations, mergedIdentifyPayload.integrations);

    // or if context has changed
    const context = AnalyticsProperties.genericContext;
    shouldIdentify ||= !fastDeepEqual(context, mergedIdentifyPayload.context);

    // or if the user id has changed
    shouldIdentify ||= userId !== mergedIdentifyPayload.userId;

    // or if the anon id has changed
    const anonId = AnalyticsProperties.installId;
    shouldIdentify ||= mergedIdentifyPayload.anonId !== anonId;

    if (shouldIdentify) {
        didIdentify = true;
        analytics?.identify(userId, traits, {
            context: AnalyticsProperties.genericContext,
            integrations,
        });
        if (!analyticsInitializedWithRealUser) {
            analyticsInitializedWithRealUser = true;
        }
        // analytics.js (Segment) will clear out the anonymous id if the new user id is different
        // but we want to keep the install id as the anonymous id
        // https://github.com/segmentio/analytics.js-core/blob/master/lib/user.ts#L67
        analytics?.user?.().anonymousId?.(AnalyticsProperties.installId);

        getAnalyticsLogger()?.debug(`identify`, {
            userId: userId,
            anonId: anonId,
            traits: JSON.stringify(traits),
        });

        mergedIdentifyPayload = {
            userId,
            anonId,
            traits: {
                ...mergedIdentifyPayload.traits,
                ...traits,
            },
            integrations,
            context,
        };
        flushAnalyticsInitUserQueue();
    } else {
        getAnalyticsLogger()?.debug(`skipping identify; nothing has changed`);
    }
}

/**
 * Ensures that `identify` is called before other analytics calls.
 * If analytics is initialized, this will synchronously call `execIdentify`.
 * Otherwise, it will schedule the call.
 *
 * So: this should be called before the upstream caller (e.g., track/page) is scheduled.
 */
function ensureIdentified() {
    if (!didIdentify) {
        getAnalyticsLogger()?.debug(`inserting identify call before track/page`);
        identify(undefined);
    }
}

export function identify(
    userId: string | undefined,
    properties: JSONObject | Record<string, unknown> = {},
): void {
    const traits = { ...properties, ...AnalyticsProperties.genericProperties };
    if (analyticsInitialized) {
        execIdentify(userId, traits as JSONObject);
    } else {
        void scheduleTraitsForInitialIdentify(userId, traits as JSONObject);
    }
}

function execPage(name: string, properties: JSONObject | undefined, options: JSONObject): void {
    checkAnonymousId();
    const analytics = getAnalytics();
    analytics?.page(name, properties, options);
    getAnalyticsLogger()?.debugClient(`page ${name}`, properties);
}

export function page(name: string, properties?: JSONObject): void {
    ensureIdentified();
    const options = { integrations: getIntegrations() };
    if (analyticsInitialized) {
        execPage(name, properties, options);
    } else {
        void onAnalyticsInitialized(() => {
            execPage(name, properties, options);
        });
    }
}

function execTrack(
    event: string,
    cleanProperties: JSONObject | undefined,
    optionsWithIntegrations: JSONObject,
): void {
    checkAnonymousId();
    const analytics = getAnalytics();
    analytics?.track(event, cleanProperties, optionsWithIntegrations);
    getAnalyticsLogger()?.debugClient(`${event}`, cleanProperties);
}

let inUntrackableCount = 0;
const untrackableCallsQueue: Parameters<typeof _track>[] = [];

/**
 * Call to indicate that we cannot track analytics events. Calls to `track` will be queued.
 * Must be paired with a call to `outUntrackable`.
 */
export function inUntrackable(): void {
    inUntrackableCount += 1;
}

/**
 * Call to indicate that we are out of a section of code that cannot track analytics events.
 */
export function outUntrackable(): void {
    inUntrackableCount -= 1;
    if (inUntrackableCount === 0) {
        let args: Parameters<typeof _track> | undefined;
        while ((args = untrackableCallsQueue.shift())) {
            _track(...args);
        }
    } else if (inUntrackableCount <= 0) {
        throw new DescriptError(
            'outReducer called more often than inReducer',
            ErrorCategory.AppArchitecture,
        );
    }
}

/**
 * Track an event to Segment (and thereby to all enabled destinations, e.g., Amplitude).
 * Should not be called directly, but rather injected through EventTracker.config.
 *
 * @param event: The event name
 * @param properties: A dictionary of properties to track with the event
 */
export function trackEventWeb(...args: Parameters<typeof _track>): void {
    if (inUntrackableCount > 0) {
        untrackableCallsQueue.push(args);
        return;
    }
    _track(...args);
}

function _track(event: string, properties: Record<string, unknown> = {}): void {
    ensureIdentified();
    const integrations = getIntegrations();
    const optionsWithIntegrations = { integrations };

    // Add standard properties: https://coda.io/d/Event-Scrubbing-Project_d8EpWkuL5dr/2-Event-Logging-Conventions_suaVQ#_luaxT
    if (AnalyticsProperties.getAdditionalProperties) {
        properties = {
            ...properties,
            ...AnalyticsProperties.getAdditionalProperties(properties),
        };
    }

    // Amplitude/etc. complain if we submit events with undefined properties.
    // We want the freedom to easily add keys without worrying about whether
    // everything is defined. So: strip undefined values before sending to
    // Segment.
    const cleanProperties = stripUndefinedValuesShallow(properties);

    if (analyticsInitialized) {
        execTrack(event, cleanProperties, optionsWithIntegrations);
    } else {
        void onAnalyticsInitialized(() => {
            execTrack(event, cleanProperties, optionsWithIntegrations);
        });
    }

    // Ignore console warns and errors.
    for (const onTrack of getTrackListeners()) {
        onTrack(event, cleanProperties);
    }
}

export function eventProbabilityProperties(probability: number): {
    event_sample_probability: number;
} {
    return {
        event_sample_probability: probability,
    };
}
