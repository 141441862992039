// Copyright 2020 Descript, Inc

export enum ExportScope {
    Composition = 'composition',
    Selection = 'selection',
    Card = 'card',
    Markers = 'markers',
    LineBreaks = 'line_breaks',
    SelectedCompositions = 'selected_compositions',
    AllCompositions = 'all_compositions',
}

export const ExportScopes = Object.values(ExportScope);

export const DefaultExportScope: ExportScope = ExportScope.Composition;

export function isBatchExport(scope: ExportScope) {
    return (
        scope === ExportScope.Markers ||
        scope === ExportScope.SelectedCompositions ||
        scope === ExportScope.LineBreaks ||
        scope === ExportScope.AllCompositions
    );
}
