// Copyright © 2023 Descript, Inc. All rights reserved.

export const wordbarHeight = 24;
export const scriptTrackMinHeight = 24;
export const pinTrackDefaultHeight = 24;
export const scriptSizeMin = scriptTrackMinHeight + wordbarHeight;
export const verticalGapBtwnPins = 2;
export const pinSizeMin = 8 + verticalGapBtwnPins;
export const collapsedPinLaneDefaultHeight = 48;
export const cardHorizontalGap = 2;
export const minTitleHeight = 16;
export const timestripHeight = 24;
export const cardRailHeight = 22;
export const cardRailCardBoundaryHeight = 16;
export const toolbarHeight = 48;
export const scrollbarAreaHeight = 16;
export const fixedHeight =
    toolbarHeight +
    timestripHeight +
    cardRailHeight +
    1 +
    verticalGapBtwnPins +
    scrollbarAreaHeight;
export const defaultHeight = scriptSizeMin + pinTrackDefaultHeight + fixedHeight;
