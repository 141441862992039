// Copyright 2018 Descript, Inc

import { Attachment, Scope, User, SeverityLevel, Contexts } from '@sentry/types';
import * as ClientErrors from './Errors';
import {
    SpanTag,
    trackEvent,
    EventTracker,
    AnalyticsProperties,
    isErrorIgnored as isErrorIgnoredDefault,
    isErrorIgnoredButTracked,
} from '@descript/analytics';
import { ERROR_CATEGORY_TEAMS, ErrorCategory, Errors, getTeamData } from '@descript/errors';
import { roll } from './Probability';
import { AssetSyncError } from '../AssetSync/errors';
import { DocumentMismatchError } from '@descript/descript-model';
import * as NUserSettings from '../App/UserSettings';
import {
    screenRecorderMacVersion,
    screenRecorderWinVersion,
} from '../App/Constants/AppConstants';
import { Opaque } from 'type-fest';

export type ErrorHandler = (
    error: Error,
    type: string,
    severity: SeverityLevel | undefined,
    extra: Record<string, unknown>,
    sentryId: string | undefined,
) => void;
export type SentryLike = {
    configureScope(callback: (scope: Scope) => void): void;
    withScope(callback: (scope: Scope) => void): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    captureException(exception: any): string;
};

export function setErrorTrackerUser(user: User | undefined): void {
    if (EventTracker.config.sentry) {
        EventTracker.config.sentry.configureScope((scope: Scope) => {
            if (user) {
                const sentryUser = scope.getUser();
                if (!sentryUser || user.id !== sentryUser.id) {
                    // Ensures we clear scope tags, extras, contexts when
                    // user changes
                    scope.clear();
                }
                scope.setUser(user);
            } else {
                // TODO: is this the right thing?
                scope.clear();
            }

            // After clearing the scope, set the install id
            const installId = NUserSettings.Application.installId.get();
            scope.setTag('app-install-id', installId);
        });
    }
}

export type GlobalErrorMetadata = {
    tags: Record<string, string | undefined>;
    contexts: Contexts;
};

/**
 * Warning: in @sentry/electron, changes merged into the scope are sent to Main and get merged into a
 * scope that is shared across all Sentry errors (i.e., those logged from Renderers or Main)
 *
 * @params metadata.tags Tags passed here will be merged into scope. Any previously set values
 *     for those tags will be overwritten. Tags are indexed in Sentry error search
 * @params metadata.contexts These are non-indexed metadata added to sentry errors. Pass in
 *     `null` to unset a particular context. Either way, any previously set value for
 *     a particular context is overwritten as a whole (not merged).
 */
export function setGlobalErrorMetadata(metadata: Partial<GlobalErrorMetadata>): void {
    if (EventTracker.config.sentry && (metadata.tags || metadata.contexts)) {
        EventTracker.config.sentry.configureScope((scope: Scope) => {
            if (metadata.tags) {
                scope.setTags(metadata.tags);
            }
            if (metadata.contexts) {
                for (const [key, vals] of Object.entries(metadata.contexts)) {
                    // eslint-disable-next-line no-null/no-null
                    scope.setContext(key, vals ?? null);
                }
            }
        });
    }
}

let onError: ErrorHandler | undefined;

export function setOnError(handler: ErrorHandler | undefined): void {
    onError = handler;
}

function isErrNoException(ex: Error): ex is NodeJS.ErrnoException {
    return ex instanceof Error && 'errno' in ex && 'code' in ex;
}

export type SentryErrorExtras = {
    project_id?: string;
    projectId?: string;
    drive_id?: string;
    driveId?: string;
    published_project_id?: string;
    publishedProjectId?: string;
    published_project_url_slug?: string;
    publishedProjectUrlSlug?: string;
    asset_id?: string;
    assetId?: string;
    artifact_id?: string;
    artifactId?: string;
    recording_id?: string;
    recordingId?: string;
    category?: ErrorCategory;
    [key: string]: unknown;
};

export function trackWarningWithCategory(
    error: Error,
    category: ErrorCategory,
    type: string,
    extra: SentryErrorExtras = {},
    allowNetworkError: boolean = true,
) {
    return console.warn(error, { type, allowNetworkError, category });
}

function isWindows(): boolean {
    return process.platform === 'win32';
}

export type SentryId = Opaque<string, 'sentryId'>;

const trackedErrorsAndSentryIds = new WeakMap<Error, SentryId | undefined>();
const trackedIgnoredErrors = new Set<string>();

/**
 * Define a fingerprint to group errors together.
 * You can set the fingerprint directly on `trackError` to divide catch all errors into groups.
 * This function can be used to group many errors into a single fingerprint.
 */
function getCustomFingerprint(error: unknown): string[] | undefined {
    if (
        error instanceof Error &&
        (error.message.toLowerCase().includes('No space left on device') ||
            error.message.toLowerCase().includes('ENOSPC'))
    ) {
        return ['enospc'];
    }

    return undefined;
}

/**
 * Define a severity to group errors together.
 * You can set the severity directly on `trackError` individually.
 * This function can be used affect the severity of many errors.
 */
function getCustomSeverity(error: unknown): SeverityLevel | undefined {
    return undefined;
}

function isErrorIgnored({
    error,
    allowNetworkError,
    type,
    extra,
}: {
    error: Error;
    allowNetworkError: boolean | undefined;
    type: string;
    extra?: SentryErrorExtras;
}): boolean {
    if (isErrorIgnoredDefault(error)) {
        return true;
    }

    if (Errors.isNetworkError(error)) {
        // Skip logging network errors in most cases
        if (!allowNetworkError) {
            return true;
        }
        // Only send 1% of network error events to reduce cost
        if (roll(0.99)) {
            console.error(`[${type}] not reporting network error`, error, extra);
            return true;
        }
    }

    if (Errors.isForcedLogoutError(error)) {
        // eslint-disable-next-line no-restricted-syntax
        console.info(`[${type}] not reporting forced logout error`);
        return true;
    }

    if (ClientErrors.stopRecorderErrorTracking(error)) {
        return true;
    }

    if (Errors.isResizeObserverError(error)) {
        return true;
    }

    if (error instanceof DocumentMismatchError) {
        // These are expected and should not be tracked
        return true;
    }

    return false;
}

/**
 * Possibly tracks an error, e.g., to sentry.
 *
 * If we've already called trackError on this error, this returns the sentry id
 * from that first call (if it was tracked to sentry).
 *
 * @returns sentry id if this error was tracked to sentry
 */
export function trackError(
    error: Error,
    type: string,
    {
        extra = {},
        allowNetworkError,
        category,
        level,
        fingerprint,
        attachment,
    }: {
        extra?: SentryErrorExtras;
        allowNetworkError?: boolean;
        category: ErrorCategory;
        level?: SeverityLevel;
        fingerprint?: string[];
        attachment?: Attachment;
    },
): SentryId | undefined {
    if (trackedErrorsAndSentryIds.has(error)) {
        return trackedErrorsAndSentryIds.get(error);
    }
    // even if we don't end up tracking this to sentry, we
    // still want to indicate that we've called this function with this error
    trackedErrorsAndSentryIds.set(error, undefined);

    let severity: SeverityLevel | undefined = level;

    // Skip logging network errors in most cases
    if (Errors.isNetworkError(error) && severity === undefined) {
        severity = 'warning';
    }

    // Special handling for IO errors
    if (isErrNoException(error)) {
        const { code, errno, message, path, syscall } = error;
        Object.assign(extra, { code, errno, message, path, syscall });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorExtra = (error as Record<string, any>).extra;
    if (errorExtra && typeof errorExtra === 'object') {
        Object.assign(extra, errorExtra);
    }

    /* eslint-disable dot-notation */
    if (AssetSyncError.isAssetSyncError(error)) {
        extra['projectId'] = extra['projectId'] || error.tags[SpanTag.appProjectId];
        extra['assetId'] = extra['assetId'] || error.tags[SpanTag.appAssetId];
        extra['artifactId'] = extra['artifactId'] || error.tags[SpanTag.appArtifactId];
    }

    const additionalProps = AnalyticsProperties.getAdditionalProperties?.({}) as Record<
        string,
        string
    >;

    const projectId = extra['project_id'] ?? extra['projectId'] ?? additionalProps?.project_id;
    const driveId = extra['drive_id'] ?? extra['driveId'] ?? additionalProps?.drive_id;
    const publishedProjectId = extra['published_project_id'] ?? extra['publishedProjectId'];
    const publishedProjectUrlSlug =
        extra['published_project_url_slug'] ?? extra['publishedProjectUrlSlug'];
    const assetId = extra['asset_id'] ?? extra['assetId'];
    const artifactId = extra['artifact_id'] ?? extra['artifactId'];
    const recordingId = extra['recording_id'] ?? extra['recordingId'];
    const engTeam = getTeamData(ERROR_CATEGORY_TEAMS[category]).name;

    const trackId = (extra.composition_id ||
        extra.compositionId ||
        extra.track_id ||
        extra.trackId ||
        extra.scene_id ||
        extra.sceneId ||
        additionalProps?.composition_id) as string | undefined;

    const isStoryboard = (extra.is_storyboard ?? additionalProps?.is_storyboard) as
        | boolean
        | undefined;
    const screenRecorderVersion = isWindows()
        ? screenRecorderWinVersion
        : screenRecorderMacVersion;

    if (isErrorIgnoredButTracked(error)) {
        // Only track these errors once per session
        if (!trackedIgnoredErrors.has(error.message)) {
            trackedIgnoredErrors.add(type);
            trackEvent('error_ignored', {
                ...extra,
                track_id: trackId,
                type,
                name: error.name,
                message: error.message,
                response_message: Errors.isRequestError(error) ? error.json.message : undefined,
                error_category: category,
                eng_team: engTeam,
                screen_recorder_version: screenRecorderVersion,
            });
        }
        return;
    } else if (isErrorIgnored({ error, allowNetworkError, type, extra })) {
        return;
    }

    /* eslint-enable dot-notation */
    let sentryId: string | undefined = undefined;
    if (process.env.SENTRY_DSN && EventTracker.config.sentry !== undefined) {
        EventTracker.config.sentry.withScope((scope) => {
            scope.setTag('error-type', type);
            if (Errors.isRequestError(error)) {
                scope.setContext('Request Error', {
                    ...error.getSentryContext(),
                    response_message: error.json.message,
                });
            }
            scope.setTag('error-category', category);
            scope.setTag('eng-team', engTeam);
            if (driveId) {
                scope.setTag('drive-id', driveId);
            }
            if (projectId) {
                scope.setTag('project-id', projectId);
            }
            if (isStoryboard !== undefined) {
                scope.setTag('storyboard', `${isStoryboard}`);
            }
            if (trackId) {
                scope.setTag('track-id', trackId);
            }
            if (publishedProjectId) {
                scope.setTag('published-project-id', publishedProjectId);
            }
            if (publishedProjectUrlSlug) {
                scope.setTag('published-project-url-slug', publishedProjectUrlSlug);
            }
            if (assetId) {
                scope.setTag('asset-id', assetId);
            }
            if (artifactId) {
                scope.setTag('artifact-id', artifactId);
            }
            if (recordingId) {
                scope.setTag('recording-id', recordingId);
            }
            severity ||= getCustomSeverity(error);
            scope.setLevel(severity || 'error');
            if (extra) {
                Object.keys(extra).forEach((key) => scope.setExtra(key, extra[key]));
            }
            if (AssetSyncError.isAssetSyncError(error)) {
                scope.setContext('Asset Sync Error', error.tags);
            }

            scope.setContext('Screen Recorder Version', {
                screen_recorder_version: screenRecorderVersion,
            });

            fingerprint ||= getCustomFingerprint(error);

            if (fingerprint) {
                scope.setFingerprint(fingerprint);
            }
            if (attachment) {
                scope.addAttachment(attachment);
            }

            sentryId = EventTracker.config.sentry?.captureException(error);
        });

        console.warn(`[${type}] (sentry=${sentryId})`, error, extra);
    } else {
        console.error(`[${type}]`, error, extra);
    }
    onError?.(error, type, severity, extra, sentryId);
    trackedErrorsAndSentryIds.set(error, sentryId);
    return sentryId;
}
