// Copyright 2024 Descript, Inc

type FormatBytesOptions = {
    decimalPlaces?: number;
    unitSeparator?: string;
    locale?: string;
};

const units = [
    { unit: 'TB', min: 1e12 },
    { unit: 'GB', min: 1e9 },
    { unit: 'MB', min: 1e6 },
    { unit: 'KB', min: 1e3 },
];

const formatterCache = new Map<string, Intl.NumberFormat>();
const getFormatter = (locale: string, decimalPlaces: number): Intl.NumberFormat => {
    const key = `${locale}-${decimalPlaces}`;
    let formatter = formatterCache.get(key);
    if (!formatter) {
        formatter = new Intl.NumberFormat(locale, {
            style: 'decimal',
            maximumFractionDigits: decimalPlaces,
        });
        formatterCache.set(key, formatter);
    }
    return formatter;
};

/**
 * Format a size in bytes into a human readable string
 */
export function formatBytes(numBytes: number, options?: FormatBytesOptions) {
    const unitSeparator = options?.unitSeparator ?? ' ';
    const decimalPlaces = options?.decimalPlaces ?? 2;
    const locale = options?.locale ?? 'en-US';

    const formatter = getFormatter(locale, decimalPlaces);
    const unit = units.find((u) => u.min <= Math.abs(numBytes));

    const value = formatter.format(unit ? numBytes / unit.min : numBytes);
    return `${value}${unitSeparator}${unit ? unit.unit : 'B'}`;
}
