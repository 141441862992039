// Copyright 2024 Descript, Inc

import { ErrorCategory } from '@descript/errors';

/**
 * Used to throw when reacting to issues with any specific file
 */
export class ErrorWithPath extends Error {
    category = ErrorCategory.VideoMediaEngine;

    constructor(error: Error, pathname: string) {
        // combine message & path in a formatted string
        super(`${error.message} (pathname=${pathname})`);
    }

    private static splitError(errorString: string): {
        message: string | undefined;
        pathname: string | undefined;
    } {
        // split message & path from the formatted string
        const match = errorString.match(/^(.*) \(pathname=(.*)\)$/);
        return match
            ? { message: match[1], pathname: match[2] }
            : { message: undefined, pathname: undefined };
    }

    static isErrorWithPath(error: Error): error is ErrorWithPath {
        const pathname = ErrorWithPath.getPathname(error as ErrorWithPath);
        return pathname !== undefined;
    }
    static getMessageWithoutPath(error: ErrorWithPath): string | undefined {
        const { message } = ErrorWithPath.splitError(error.message);
        return message;
    }
    static getPathname(error: ErrorWithPath): string | undefined {
        const { pathname } = ErrorWithPath.splitError(error.message);
        return pathname;
    }
}
