// Copyright © 2023 Descript, Inc. All rights reserved.
export const wordbarHeight = 30;
export const scrollbarHeight = 20;
export const pinBufferHeightTop = 25;
export const pinBufferHeightBottom = 15;
export const scriptTrackMinHeight = 50;
export const scriptSizeDefault = 160;

export const scriptSizeMin = scriptTrackMinHeight + wordbarHeight + scrollbarHeight;
export const pinsExtraSize = pinBufferHeightTop + pinBufferHeightBottom;
export const extraSize = scrollbarHeight + pinsExtraSize;
export const pinSizeMin = 8;
export const tauWidthToShowGainControl = 60;
// throttle wait time manually tested for a balance between responsiveness and performance
export const dragOverThrottleWaitTime = 35;
