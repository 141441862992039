// Copyright 2022 Descript, Inc

import { getAnalyticsLogger } from './analyticsLogger';

const analyticsLoadedPromise = new Promise<void>((resolve, reject) => {
    let globalScope: (Window & { analytics: SegmentAnalytics.AnalyticsJS }) | undefined;
    try {
        globalScope =
            typeof window !== 'undefined'
                ? (window as Window & { analytics: SegmentAnalytics.AnalyticsJS })
                : typeof self !== 'undefined'
                  ? (self as Window & { analytics: SegmentAnalytics.AnalyticsJS })
                  : undefined;
    } catch {
        getAnalyticsLogger()?.debug('window check failed; skipping analytics init');
        resolve();
        return;
    }

    if (typeof globalScope === 'undefined') {
        getAnalyticsLogger()?.debug('window unavailable; skipping analytics init');
        resolve();
        return;
    }

    getAnalyticsLogger()?.debug('starting loaded check');

    if (globalScope.analytics && 'initialize' in globalScope.analytics) {
        getAnalyticsLogger()?.debug('loaded immediately');
        resolve();
        return;
    }

    let maxTime = 20_000; // 20 seconds... just assume it's loaded after that point (we don't want to lose events!)
    const step = 250;
    const handle = setInterval(() => {
        if ((globalScope.analytics && 'initialize' in globalScope.analytics) || maxTime <= 0) {
            // object exists and is loaded
            clearInterval(handle as Parameters<typeof clearInterval>[0]);
            getAnalyticsLogger()?.debug('loaded');
            resolve();
        }
        maxTime -= step;
    }, step);
});

export function getAnalytics(): SegmentAnalytics.AnalyticsJS | undefined {
    if (typeof analytics !== 'object' || typeof analytics.user !== 'function') {
        return undefined;
    }

    return analytics;
}

let initPromise: Promise<void> | undefined;

/**
 * Finish initializing the Segment SDK. Don't call until user settings have been initialized
 */
export async function initAnalytics(
    anonymousId: string,
    pageProps: { page: string; params: Record<string, unknown> } | undefined,
) {
    // segment sdk requires that we call `page` at least once per page load
    // it normally persists an anonymous id in localStorage. Our localStorage is not
    // shared between windows (and is not persisted) so we end up with many more
    // MAUs than expected. Instead, use the installId as the anonymous id

    if (initPromise) {
        return await initPromise;
    }

    initPromise = (async () => {
        await analyticsLoadedPromise;

        const analytics = getAnalytics();

        if (!analytics) {
            getAnalyticsLogger()?.warn('not properly loaded');
            return;
        }

        getAnalyticsLogger()?.debug(`loaded, setting anonymous id to ${anonymousId}`);

        analytics.user().anonymousId(anonymousId);
        analytics.page(pageProps?.page, pageProps?.params);
    })();

    return await initPromise;
}
