// Copyright 2020 Descript, Inc

import { OwnershipRole } from './Project';
import { DriveMembershipType, DriveMembershipTypeFilter } from './Drive';
import { ComparisonResult, sortAlphabetical } from '@descript/descript-core';

export type InviteType = 'drive' | 'project';

type InviteJsonBase = {
    id: string;
    invitee_email: string;
    invite_object_id: string;
    created_at: string;
    accepted_at?: string;
    accepted_by?: string;
    invitee_exists: boolean;
    public_properties?: {
        name: string;
    };
};

type DriveInviteProperties = {
    invite_object_type: 'drive';
    properties: {
        membership_type: DriveMembershipType;
    };
};
type DriveInviteJson = InviteJsonBase & DriveInviteProperties;

type ProjectInviteProperties = {
    invite_object_type: 'project';
    properties: {
        role: OwnershipRole;
    };
};
type ProjectInviteJson = InviteJsonBase & ProjectInviteProperties;

type TeamInviteJson = InviteJsonBase & {
    invite_object_type: 'team';
};

export type InviteJson = DriveInviteJson | ProjectInviteJson | TeamInviteJson;

type CreateDriveInviteJsonBase = Pick<InviteJsonBase, 'invitee_email' | 'invite_object_id'> & {
    comment?: string;
};

type CreateDriveInviteJson = CreateDriveInviteJsonBase & DriveInviteProperties;
type CreateProjectInviteJson = CreateDriveInviteJsonBase & ProjectInviteProperties;
export type CreateInviteJson = CreateDriveInviteJson | CreateProjectInviteJson;

type InviteBase = {
    id: string;
    inviteeEmail: string;
    inviteObjectId: string;
    createdAt: Date;
    acceptedAt: Date | undefined;
    acceptedBy: string | undefined;
    inviteeExists: boolean;
    name?: string;
};

export type DriveInvite = InviteBase & {
    type: 'drive' | 'team';
    membershipType: DriveMembershipType;
};

export type ProjectInvite = InviteBase & {
    type: 'project';
    projectRole: OwnershipRole;
};

export type Invite = DriveInvite | ProjectInvite;

export function inviteFromJson({
    id,
    invitee_email: inviteeEmail,
    invite_object_id: inviteObjectId,
    created_at: createdAt,
    accepted_at: acceptedAt,
    accepted_by: acceptedBy,
    invitee_exists: inviteeExists,
    public_properties: publicProperties,
    ...rest
}: InviteJson): Invite {
    const baseInvite: InviteBase = {
        id,
        inviteeEmail,
        inviteObjectId,
        createdAt: new Date(createdAt),
        acceptedAt: acceptedAt ? new Date(acceptedAt) : undefined,
        acceptedBy,
        inviteeExists,
        name: publicProperties?.name,
    };

    switch (rest.invite_object_type) {
        case 'drive':
            return {
                ...baseInvite,
                type: 'drive',
                membershipType: rest.properties.membership_type,
            };

        case 'project':
            return {
                ...baseInvite,
                type: 'project',
                projectRole: rest.properties.role,
            };

        case 'team':
        default:
            return {
                ...baseInvite,
                type: 'drive',
                membershipType: 'editor',
            };
    }
}

export function getSortedFilteredInvitations(
    invitations: DriveInvite[] | undefined,
    membershipTypeFilter?: DriveMembershipTypeFilter,
    memberSearchValue?: string,
): DriveInvite[] {
    const searchTermMatches = (invite: DriveInvite) => {
        if (!memberSearchValue) {
            return true;
        }
        return invite.inviteeEmail.toLowerCase().includes(memberSearchValue.toLowerCase());
    };
    const membershipTypeMatches = (invite: DriveInvite) => {
        if (!membershipTypeFilter) {
            return true;
        } else if (membershipTypeFilter === DriveMembershipTypeFilter.admins) {
            // no admin invites to show currently
            return false;
        } else if (membershipTypeFilter === DriveMembershipTypeFilter.editors) {
            return invite.membershipType === 'editor';
        } else if (membershipTypeFilter === DriveMembershipTypeFilter.free) {
            return invite.membershipType === 'free';
        } else {
            return true;
        }
    };
    return [...(invitations || [])]
        .filter((invite) => {
            return membershipTypeMatches(invite) && searchTermMatches(invite);
        })
        .sort((inviteA, inviteB) => {
            if (inviteA.membershipType !== inviteB.membershipType) {
                return inviteA.membershipType === 'editor' // preferred type when sorting
                    ? ComparisonResult.OrderedAscending
                    : ComparisonResult.OrderedDescending;
            } else {
                return sortAlphabetical(inviteA.inviteeEmail, inviteB.inviteeEmail);
            }
        });
}
