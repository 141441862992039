// Copyright 2020 Descript, Inc

export interface TypedArray extends ArrayLike<number> {
    subarray(begin?: number, end?: number): this;
    set(array: ArrayLike<number>, offset?: number): void;
    copyWithin(target: number, start: number, end?: number): this;
}

export interface TypedArrayConstructor<T extends TypedArray> {
    new (copyFromOrLength: TypedArray | number): T;
    readonly BYTES_PER_ELEMENT: number;
}

export const TYPED_ARRAYS_ARE_LITTLE_ENDIAN =
    new Uint8Array(new Uint16Array([1]).buffer)[0] === 1;
