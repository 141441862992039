// Copyright 2024 Descript, Inc

export enum TemplateSectionType {
    All,
    Drive,
    Private,
    Gallery,
}

export function getTemplateSectionTypeFromDisplayName(name: string) {
    return TemplateSectionType[name as keyof typeof TemplateSectionType];
}
export function getTemplateSectionTypeDisplayName(type: TemplateSectionType) {
    return TemplateSectionType[type].toString();
}

export const sections = [
    TemplateSectionType.Drive,
    TemplateSectionType.Private,
    TemplateSectionType.Gallery,
];
