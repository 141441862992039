// Copyright © 2024 Descript, Inc. All rights reserved.

const isArray = Array.isArray;
const hasProp = Object.prototype.hasOwnProperty;

/**
 * Determines where all the values in `a` are reference-equal to the corresponding values in `b`,
 * and that `a` and `b` have the same keys.
 *
 * Expected to be used with arrays or objects as input, but the type signature is more
 * permissive to allow for use with APIs like `useSelector`
 *
 * Adapted from fast-deep-equal
 */
export function fastShallowEqual<T>(a: T | undefined, b: T | undefined) {
    if (a === b) {
        return true;
    }

    if (!a || !b || typeof a !== 'object' || typeof b !== 'object') {
        return a !== a && b !== b; // NaN check
    }

    if (isArray(a)) {
        if (isArray(b)) {
            if (a.length !== b.length) {
                return false;
            }
            for (let i = a.length; i-- !== 0; ) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    } else if (isArray(b)) {
        return false;
    }

    // a and b have same ownprops
    let key = '';
    for (key in a) {
        if (hasProp.call(a, key)) {
            if (!hasProp.call(b, key)) {
                return false;
            }
        }
    }

    // values of `a` are same as values of `b`
    let count = 0;
    for (key in a) {
        if (hasProp.call(a, key)) {
            if ((a as Record<string, unknown>)[key] !== (b as Record<string, unknown>)[key]) {
                return false;
            }
            count++;
        }
    }

    // `a` has same number of properties as `b`
    for (key in b) {
        if (hasProp.call(b, key)) {
            count--;
        }
    }
    return count === 0;
}
