// Copyright 2018 Descript, Inc

import { Entries } from 'type-fest';

/**
 * Determines whether an object is empty (e.g. JSON serialization becomes '{}')
 * @param obj - the object to test
 */
// eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
export function isObjectEmpty(obj: Record<string, unknown> | Object): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

//

/**
 * Recursive function to remove empty properties from an object
 * https://stackoverflow.com/a/38340730/1006238
 * @param obj - the object to prune
 * @param recursive - true if you want to recurse through child properties
 */
export function removeEmpty<T extends Record<string, unknown>>(obj: T, recursive = true): T {
    return (
        Object.keys(obj)
            // eslint-disable-next-line no-null/no-null
            .filter((k) => obj[k] !== null && obj[k] !== undefined) // Remove undef. and null.
            .reduce((newObj, k) => {
                const value = obj[k];

                return recursive && typeof value === 'object' && !(value instanceof Date)
                    ? Object.assign(newObj, { [k]: removeEmpty(value as T) }) // Recurse.
                    : Object.assign(newObj, { [k]: value }); // Copy value.
            }, {}) as T
    );
}

export type KeysOfUnion<T> = T extends T ? keyof T : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DistributivePick<T, K extends keyof T> = T extends any ? Pick<T, K> : never;

/**
 * Make a copy of an object without certain keys
 *
 * @param obj The object to copy
 * @param keys The keys to omit
 */
export function omit<
    T extends Record<string, unknown>,
    K extends KeysOfUnion<T>,
    R = { [key in Exclude<keyof T, K>]: T[key] },
>(obj: T, ...keysArr: K[]): R {
    return Object.entries(obj).reduce(
        (newObj, [k, v]) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            !keysArr.includes(k as K) ? (((newObj as any)[k] = v), newObj) : newObj,
        {} as R,
    );
}

/**
 * Make a copy of an object with a subset of its current properties.
 *
 * @param obj The object to copy
 * @param keys The keys to include
 */
export function pick<
    T extends Record<string, unknown>,
    K extends keyof T,
    R = { [key in K]: T[key] },
>(obj: T, ...keysArr: K[]): R {
    return Object.entries(obj).reduce(
        (newObj, [k, v]) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            keysArr.includes(k as K) ? (((newObj as any)[k] = v), newObj) : newObj,
        {} as R,
    );
}

export function fromEntries<K extends string | number | symbol, V>(
    items: Array<[key: K, val: V]>,
): Record<K, V> {
    const result: Partial<Record<K, V>> = {};
    for (const [k, v] of items) {
        result[k] = v;
    }
    return result as Record<K, V>;
}

export const keys: <O extends object>(obj: O) => Array<keyof O> = Object.keys;
export const entries = <T extends object>(obj: T) => Object.entries(obj) as Entries<typeof obj>;
