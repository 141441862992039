// Copyright © 2023 Descript, Inc. All rights reserved.

import { SpanOptions, trace, Tracer } from '@opentelemetry/api';
import { AsyncContext } from './asyncContext';

export function getOtelTracer(): Tracer {
    return trace.getTracer('beachcube', '0.0.1');
}

/**
 * Creates a new span with the given name and options.
 * Also returns an updated AsyncContext with the new span set as the active span.
 *
 * @param ctx If `ctx.span` is provided, ensures that the new span will be a child of that span.
 * If `ctx.traceMetadata` is provided, adds those attributes to the new span and all descendant spans.
 */
export function startSpan(
    name: string,
    spanOptions: SpanOptions | undefined,
    ctx: AsyncContext,
): AsyncContext {
    const tracer = getOtelTracer();

    if (ctx.traceMetadata) {
        spanOptions = {
            ...spanOptions,
            attributes: {
                ...spanOptions?.attributes,
                ...ctx.traceMetadata,
            },
        };
    }

    // If ctx has an invalid span or trace id (e.g., a NoopSpan),
    // or if spanOptions.root === true, this will create a new root span.
    // Otherwise, it will create a child span of whatever span is set in the ctx.
    const span = tracer.startSpan(name, spanOptions, ctx);

    return ctx.setSpan(span);
}
