// Copyright 2023 Descript, Inc

export const enum ComparisonResult {
    OrderedAscending = -1,
    OrderedSame = 0,
    OrderedDescending = 1,
}
/**
 * Sorts strings alphabetically, numerically for strings with numbers at the beginning
 * @param {string} a
 * @param {string} b
 * @returns {ComparisonResult}
 */
export const sortAlphabetical = (a: string, b: string): number => {
    const aLowerCase = a.toLowerCase();
    const bLowerCase = b.toLowerCase();

    if (aLowerCase === bLowerCase) {
        return ComparisonResult.OrderedSame;
    }

    const aNumberMatch = aLowerCase.match(/^\d*/);
    const bNumberMatch = bLowerCase.match(/^\d*/);

    // This will return undefined if match[0] is empty string;
    const aNumberString = (aNumberMatch && aNumberMatch[0]) || undefined;
    const bNumberString = (bNumberMatch && bNumberMatch[0]) || undefined;

    // If only one string starts with a number, it comes first
    if (aNumberString && !bNumberString) {
        return ComparisonResult.OrderedAscending;
    }
    if (!aNumberString && bNumberString) {
        return ComparisonResult.OrderedDescending;
    }

    // If both strings start with a number, compare the numbers
    if (aNumberString && bNumberString) {
        const aNumber = parseInt(aNumberString, 10);
        const bNumber = parseInt(bNumberString, 10);
        if (aNumber !== bNumber) {
            return numericSort(aNumber, bNumber);
        } else if (aNumberString !== bNumberString) {
            // If numbers are the same, compare the length of the number strings
            // This is to handle numbers with leading zeros
            if (aNumberString.length < bNumberString.length) {
                return ComparisonResult.OrderedAscending;
            }
            return ComparisonResult.OrderedDescending;
        }

        // If the numbers are the same, compare the rest of the strings
        return sortAlphabetical(
            aLowerCase.slice(aNumberString.length),
            bLowerCase.slice(bNumberString.length),
        );
    }

    if (aLowerCase < bLowerCase) {
        return ComparisonResult.OrderedAscending;
    }
    return ComparisonResult.OrderedDescending;
};

export const numericSort = (a: number, b: number): number => {
    if (a === b) {
        return ComparisonResult.OrderedSame;
    }
    return a < b ? ComparisonResult.OrderedAscending : ComparisonResult.OrderedDescending;
};

export function booleanSort(a: boolean, b: boolean) {
    return b
        ? ComparisonResult.OrderedAscending
        : a
          ? ComparisonResult.OrderedDescending
          : ComparisonResult.OrderedSame;
}
