// Copyright 2023 Descript, Inc

import semverMajor from 'semver/functions/major';
import semverMinor from 'semver/functions/minor';

export function getAppVersion(): string {
    return process.env.REACT_APP_VERSION || 'unknown';
}

export function getMajorNumber(version: string): number {
    return semverMajor(version);
}

export function getMinorNumber(version: string): number {
    return semverMinor(version);
}

export function newChangelogAvailable(lastVersionChangelogViewed: string | undefined): boolean {
    // We have a changelog for each major version
    return (
        lastVersionChangelogViewed === undefined ||
        getMajorNumber(lastVersionChangelogViewed) < getMajorNumber(getAppVersion())
    );
}
