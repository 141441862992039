// Copyright 2024 Descript, Inc

import type { Event, EventHint } from '@sentry/types';

export async function getActualErrorFromHint(hint: EventHint): Promise<Error | undefined> {
    const { originalException } = hint;

    if (originalException) {
        if (originalException instanceof Error) {
            return originalException;
        } else if (originalException instanceof Promise) {
            return await originalException.catch((e) => e);
        }
    }

    return undefined;
}

export async function getErrorMessageFromEvent(event: Event) {
    const exception = event.exception?.values?.find((e) => e.type);

    if (exception) {
        return {
            name: exception.type || 'Unknown Error',
            message: exception.value || "Couldn't get error message",
        };
    }

    return undefined;
}
